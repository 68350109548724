import rootSession from "../../api/session";
import { sweetAlert } from "../../utils/sweetAlert";
import axiosApi from "../../api/axios";
import domain from "../../api/constent";
import { Link, useNavigate } from "react-router-dom";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGOUT = "LOGOUT";

export const loginUser = (credentials, navigate) => async (dispatch) => {
  dispatch({ type: LOGIN_REQUEST });

  try {
    await rootSession.getCSRF();

    const response = await axiosApi.post(domain.Login_URL, credentials);
    const accessToken = response?.data?.accessToken;

    if (!accessToken) {
      sweetAlert("Error!", "Token not found.", 2);
      return dispatch({ type: LOGIN_FAILURE, payload: "Token not found" });
    }

    localStorage.setItem("token", accessToken);
    localStorage.setItem("redirection", response?.data?.type);

    const loginDetails = await axiosApi.post(domain.LoggedIn, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const userData = {
      id: loginDetails?.data?.data?.id,
      email: loginDetails?.data?.data?.email,
      type: loginDetails?.type,
      profileImage: loginDetails?.data?.data?.profile_img,
      name: loginDetails?.data?.data?.name,
      firstName: loginDetails?.data?.data?.first_name,
      lastName: loginDetails?.data?.data?.last_name,
      status: loginDetails?.data?.data?.status,
      type: 1,
      token: `Bearer ${accessToken}`,
    };

    sessionStorage.setItem("user", JSON.stringify(userData));
    dispatch({ type: LOGIN_SUCCESS, payload: userData });

    // ✅ Redirect based on user type
    if (localStorage.getItem("redirection") == 1) {
      navigate("/");
    } else {
      navigate("/login");
      sweetAlert("Failed!", "Login failed. Please check your credentials.", 2);
    }

    sweetAlert("Success!", "You have logged in successfully.", 1);
  } catch (err) {
    dispatch({
      type: LOGIN_FAILURE,
      payload: err.response?.data || err.message,
    });

    sweetAlert("Failed!", "Login failed. Please check your credentials.", 2);
  }
};

export const logoutUser = () => (dispatch) => {
  localStorage.removeItem("token");
  localStorage.removeItem("userType");
  dispatch({ type: LOGOUT });
};
