import React, {
  Suspense,
  useState,
  useEffect,
  useReducer,
  useMemo,
} from "react";

import CircleProgess from "../../../components/progressBar";

import LottieControl from "../../../utils/LottieControl";
import Session from "../../../api/session";
import PropTypes from "prop-types";
import Sidebar from "../../../components/candidates/Sidebar";
import { Link, useNavigate } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import SortBuy from "../../../components/dropdown/SortBuy";
import domain from "../../../api/constent";
import { sweetAlert } from "../../../utils/sweetAlert";
import ArowBackIcon from "@rsuite/icons/ArowBack";

import STYLES from "../../../assets/css/styles.css";

import LoaderMount from "../../../components/preloader";
import SearchIcon from "@rsuite/icons/Search";
import AddOutlineIcon from "@rsuite/icons/AddOutline";
import PlusIcon from "@rsuite/icons/Plus";
import { Edit, Trash, Eye } from "@rsuite/icons";

import ReviewSubmit from "../../../components/reviewSubmit/ReviewSubmit";
import apiService from "../../../api/apiService";
import {
  IconButton,
  ButtonToolbar,
  Grid as RSGrid,
  Panel as RSPanel,
  Row as RSRow,
  CheckPicker as RSCheckPicker,
  SelectPicker as RSSelectPicker,
  Col as RSCol,
  Dropdown as RSDropdown,
  InputPicker as RSInputPicker,
  Loader as RSLoader,
  Pagination as RSPaginator,
  Placeholder as RSPlaceholder,
  AutoComplete as RSAutoComplete,
  VStack,
  CheckTreePicker as RSCheckTreePicker,
  TreePicker as RSTreePicker,
  List as RSList,
  TagGroup as RSTagGroup,
  Tag as RSTag,
  Button as RSButton,
  Divider as RSDivider,
  Placeholder,
  Form as RSForm,
} from "rsuite";
import { alert, confirm, prompt } from "@rsuite/interactions";
import ManageJobCandidateList from "./ManageCandidate/ManagejobCandidateList";
import ManageJobPostEdit from "./Edit/ManageJobPostEdit";
// import ManageJobPostEdit from "./ManageJobPostEdit";

// ManageJobPost.propTypes = {};

function ManageJobPost() {
  // const { data } = props;

  const [jobManagePostingList, setManageJobPostingList] = useState([]);

  const [shortlisted, setShortlisted] = useState(2);
  const [perPagefil, setPerPageFil] = useState(10);
  const [perSortList, setPerSortList] = useState("asc");

  const [searchValue, setSearchValue] = useState("");
  const [selectedJobStatus, setSelectedJobStatus] = useState("1");
  const [selectedCategory, setSelectedCategory] = useState("1");

  const [loading, setLoading] = useState(false);

  // pagination
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);

  //total Jobs
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    fetchInfo(currentPage);
  }, [
    currentPage,
    searchValue,
    selectedJobStatus,
    selectedCategory,
    perPagefil,
    perSortList,
  ]);
  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchInfo(page);
  };
  const fetchInfo = async (pageIndex) => {
    setLoading(true);
    const sessionData = Session.getDataEmployerSessionStore();
    console.log(sessionData);

    const downPoint = {
      id: sessionData && sessionData.id ? sessionData.id : 0,
      encrypted:
        sessionData && sessionData.encryptId ? sessionData.encryptId : "0",
      page: pageIndex,
      per_page: perPagefil, //itemsPerPage ??
      order_direction: perSortList, //sortValue,
      //   candidate_info: sessionData && sessionData.id ? sessionData.id : 0,
      keyword: searchValue,
      //   depart_id: listFind1,
      //   role_id: listFind2,
      //   place_search: WherePlaceSearchFilter,
      //   employment_type: JSON.stringify(employmentTypeFilter),
      //   salary_type: salaryTypeFilter,
      //   salary_range_min: parseInt(salaryRangeMinFilter, 10) ?? 0,
      //   salary_range_max: parseInt(salaryRangeMaxFilter, 10) ?? 0,
      //   listing_time: listingTimeList,
    };

    const response = await apiService.getEManageJobList(downPoint);

    // console.log(response);

    // const { data } = response;
    setCurrentPage(response.data.current_page);
    setLastPage(response.data.last_page);
    setItemsPerPage(response.data.per_page);
    setTotalPages(response.data.total);
    setManageJobPostingList(response.data.data);

    setLoading(false);

    if (!response) return;
  };
  const [currentComponent, setCurrentComponent] = useState("list");
  const [filteredJobs, setFilteredJobs] = useState([]);
  const handleClick = (idx) => {
    // Filter jobs and update state
    const jobs = jobManagePostingList.filter((job) => job.id === idx.id);
    setFilteredJobs(jobs);
    setCurrentComponent("candidates"); // Switch to the candidate list
  };
  const navigate = useNavigate();

  const handleDelete = async (idIndex) => {
    try {
      const response = await apiService.getEManageJobDelete({ id: idIndex });

      if (response?.case === 1) {
        sweetAlert("Success!", "The job has been deleted successfully.", 1);

        fetchInfo();
        // Callback to update the parent component if necessary
        // onJobDeleted?.(idx.id);
      } else {
        // Error Alert
        sweetAlert("Error!", response?.msg || "An error occurred.", 2);
      }
    } catch (error) {
      // Log the error and show the user a fallback message
      console.error("Error deleting job:", error);
      sweetAlert("Error!", "Failed to delete the job. Please try again.", 2);
    }
  };
  return (
    <section className="candidates-section">
      <div className="tf-container">
        <div className="row">
          <div className="col-lg-4 ctn2">
            <div className="p-4">
              <h4>Manage Jobs & Responses</h4>
            </div>
            <Sidebar
              onUpdateValue={setSearchValue} // Function to update searchValue
              onUpdateJobStateValue={setSelectedJobStatus}
              onUpdateJobCategoryValue={setSelectedCategory}
            />
          </div>

          <Tabs className="col-lg-8 tf-tab ctn2">
            <div className="wd-meta-select-job">
              <div className="wd-findjob-filer">
                <div className="group-select-display">
                  <TabList className="inner menu-tab">
                    <Tab className="btn-display">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="17"
                        height="16"
                        viewBox="0 0 17 16"
                        fill="none"
                      >
                        <path
                          d="M0.5 12.001L0.5 16.0005C0.880952 16.001 1.09693 16.001 1.83333 16.001L15.1667 16.001C15.9031 16.001 16.5 16.0005 16.5 16.0005L16.5 12.001C16.5 12.001 15.9031 12.001 15.1667 12.001L1.83333 12.001C1.09693 12.001 0.880952 12.001 0.5 12.001Z"
                          fill="#A0A0A0"
                        />
                        <path
                          d="M0.5 6.00098L0.5 10.0005C0.880952 10.001 1.09693 10.001 1.83333 10.001L15.1667 10.001C15.9031 10.001 16.5 10.0005 16.5 10.0005L16.5 6.00098C16.5 6.00098 15.9031 6.00098 15.1667 6.00098L1.83333 6.00098C1.09693 6.00098 0.880952 6.00098 0.5 6.00098Z"
                          fill="#A0A0A0"
                        />
                        <path
                          d="M0.5 0.000976562L0.5 4.0005C0.880952 4.00098 1.09693 4.00098 1.83333 4.00098L15.1667 4.00098C15.9031 4.00098 16.5 4.0005 16.5 4.0005L16.5 0.000975863C16.5 0.000975863 15.9031 0.000975889 15.1667 0.000975921L1.83333 0.000976504C1.09693 0.000976536 0.880952 0.000976546 0.5 0.000976562Z"
                          fill="#A0A0A0"
                        />
                      </svg>
                    </Tab>
                    <Tab className="btn-display">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="17"
                        height="16"
                        viewBox="0 0 17 16"
                        fill="none"
                      >
                        <path
                          d="M4.5 0H0.500478C0.5 0.380952 0.5 0.596931 0.5 1.33333V14.6667C0.5 15.4031 0.500478 16 0.500478 16H4.5C4.5 16 4.5 15.4031 4.5 14.6667V1.33333C4.5 0.596931 4.5 0.380952 4.5 0Z"
                          fill="white"
                        />
                        <path
                          d="M10.5 0H6.50048C6.5 0.380952 6.5 0.596931 6.5 1.33333V14.6667C6.5 15.4031 6.50048 16 6.50048 16H10.5C10.5 16 10.5 15.4031 10.5 14.6667V1.33333C10.5 0.596931 10.5 0.380952 10.5 0Z"
                          fill="white"
                        />
                        <path
                          d="M16.5 0H12.5005C12.5 0.380952 12.5 0.596931 12.5 1.33333V14.6667C12.5 15.4031 12.5005 16 12.5005 16H16.5C16.5 16 16.5 15.4031 16.5 14.6667V1.33333C16.5 0.596931 16.5 0.380952 16.5 0Z"
                          fill="white"
                        />
                      </svg>
                    </Tab>
                  </TabList>
                  <p className="nofi-job">
                    <span>{totalPages}</span> Total Job Posting
                  </p>
                </div>
                <SortBuy
                  mountSetPerPage={setPerPageFil}
                  mountSetSortList={setPerSortList}
                  prop={[
                    { value: "12", label: "12 Per Page" },
                    { value: "1", label: "1 Per Page" },
                    { value: "10", label: "10 Per Page" },
                    { value: "8", label: "8 Per Page" },
                  ]}
                  props={[
                    { value: "", label: "Sort by (Default)" },
                    { value: "asc", label: "Asc" },
                    { value: "desc", label: "Desc" },
                  ]}
                />
              </div>
            </div>
            <div className="content-tab no-scroll">
              {loading ? (
                <div
                  className="text-center"
                  style={{
                    display: "grid",
                    placeItems: "center",
                    height: "100vh",
                  }}
                >
                  <LoaderMount.CuLoader />
                </div>
              ) : (
                <>
                  {jobManagePostingList.length != 0 ? (
                    <>
                      <TabPanel className="inner">
                        {jobManagePostingList.map((idx) => (
                          <div
                            key={idx.id}
                            className="features-job candidate-layout wd-thum-career"
                            // onClick={() => {
                            // navigate();

                            // window.location.href =
                            //   "employer#/manage-job-candidate-list";
                            // }}
                          >
                            <div className="job-archive-header">
                              <div className="career-header-left">
                                {/* <img
                          src={`${process.env.REACT_APP_ROOT_URL}${idx.img} `}
                          alt="jobtex"
                          className="thumb"
                        /> */}
                                <div className="career-left-inner">
                                  {/* <h4>
                            <Link>{idx.cate}</Link>
                          </h4> */}
                                  <h3>
                                    <Link>{idx.title}</Link>&nbsp;
                                    {/* {idx.status !== 0 && (
                                      <span className="icon-bolt"></span>
                                    )} */}
                                    <br />
                                    <small>{idx.role}</small>
                                    <h6>
                                      Total Applied Candidate :{" "}
                                      {idx.total_applied}
                                    </h6>
                                    <h6>Shortlisted: {setShortlisted ?? 0}</h6>
                                    <small>
                                      {idx.status != 0 && idx.status == 1 ? (
                                        <small>
                                          Status:{" "}
                                          <b style={{ color: "green" }}>
                                            Active Job
                                          </b>
                                        </small>
                                      ) : (
                                        <small>
                                          Status:{" "}
                                          <b style={{ color: "red" }}>
                                            Expaired Job
                                          </b>
                                        </small>
                                      )}
                                    </small>
                                  </h3>
                                  {/* 
                          <h3>
                            <Link to="/Candidatesingle_v1">{idx.name}</Link>
                            &nbsp;
                            <span className="icon-bolt"></span>
                          </h3> */}
                                  {/* <ul className="career-info">
                            <li>
                              {" "}
                              <b>{idx.total_applied}</b>
                            </li>
                            <li>
                              <span className="icon-map-pin"></span>
                              {idx.map}
                            </li> 
                          </ul> */}
                                </div>
                              </div>

                              <div className="career-header-right">
                                {idx.id} {idx.encryptId}
                                <ButtonToolbar>
                                  {/* <IconButton
                            style={{ color: "green" }}
                            icon={<AddOutlineIcon />}
                          >
                            Repost
                          </IconButton> */}
                                  <IconButton
                                    onClick={() => {
                                      // sessionStorage.removeItem(
                                      //   "formValue"
                                      // );
                                      navigate("/employer/manage-job-post-edit", {
                                        state: {
                                          jobPostingDetails: idx,
                                          privateID: idx.id,
                                        },
                                      });

                                      // <ManageJobPostEdit prop={"d"} />;
                                      // const formValue = {
                                      //   jobTitle: idx.title || "",
                                      //   jobType: idx.employment_type,
                                      //   keySkills: idx.key_skill,
                                      //   departmentCategory:
                                      //     idx.departmentCategory || "",
                                      //   role: idx.role || "",
                                      //   workMode: idx.work_mode || "", // Map from work_mode
                                      //   jobLocation: idx.job_location || "", // Map from job_location
                                      //   baseLocationRemote:
                                      //     idx.baseLocationRemote || "",
                                      //   hybridPolicy: idx.hybridPolicy || "",
                                      //   minYear: idx.min_exp || "", // Map from min_exp
                                      //   maxYear: idx.max_exp || "", // Map from max_exp
                                      //   salaryType: idx.sal_pay || "", // Map from sal_pay
                                      //   salaryProType: idx.salaryProType || "",
                                      //   minSalary: idx.sal_min || 0, // Map from sal_min
                                      //   maxSalary: idx.sal_max || 0, // Map from sal_max
                                      //   checkPercentage: !!idx.check_percentage, // Convert to boolean
                                      //   percentage:
                                      //     parseFloat(idx.percentage) || 0,
                                      //   variableSalaryType: idx.sal_symb || "₹", // Map from sal_symb
                                      //   variableMinSalary:
                                      //     parseFloat(
                                      //       idx.temp_min_salary_value
                                      //     ) || 0,
                                      //   variableMaxSalary:
                                      //     parseFloat(
                                      //       idx.temp_max_salary_value
                                      //     ) || 0,
                                      //   companyIndustries:
                                      //     idx.company_industry || "", // Map from company_industry
                                      //   hideSalary: !!idx.sal_hide, // Convert to boolean
                                      //   educations: Array.isArray(
                                      //     idx.education_list
                                      //   )
                                      //     ? idx.education_list
                                      //     : [],
                                      //   candidateIndustries: Array.isArray(
                                      //     idx.candidate_industries
                                      //   )
                                      //     ? idx.candidate_industries
                                      //     : [],
                                      //   isDiversityHiringChecked:
                                      //     !!idx.is_diversity_hiring_checked, // Convert to boolean
                                      //   jobDescription:
                                      //     idx.summarnote_job_description || "", // Map from summarnote_job_description
                                      //   vacancy: !!idx.vacancy, // Convert to boolean
                                      //   additionalVacancy:
                                      //     idx.additional_vacancy || "",
                                      //   requestCandidateVideoProfile:
                                      //     !!idx.request_candidate_video_profile, // Convert to boolean
                                      //   requestIncludeWalkInDetails:
                                      //     !!idx.request_include_walk_in_details, // Convert to boolean
                                      //   requestIncludeWalkInText:
                                      //     idx.request_include_walk_text || "",
                                      //   addQuestionsList: Array.isArray(
                                      //     idx.question_select
                                      //   )
                                      //     ? idx.question_select
                                      //     : [],
                                      //   companyName: idx.comp_name || "",
                                      //   companyWebsite: idx.comp_website || "",
                                      //   aboutCompany:
                                      //     idx.summarnote_comp_about || "", // Map from summarnote_comp_about
                                      //   isSendMailChecked:
                                      //     !!idx.isSendMailChecked, // Convert to boolean if needed
                                      //   selectedUserEmails: Array.isArray(
                                      //     idx.selectedUserEmails
                                      //   )
                                      //     ? idx.selectedUserEmails
                                      //     : [],
                                      //   notificationValue: Array.isArray(
                                      //     idx.notificationValue
                                      //   )
                                      //     ? idx.notificationValue
                                      //     : [],
                                      //   companyEmail: idx.comp_email || "",
                                      //   companyVideoContent:
                                      //     idx.companyVideoContent || "",
                                      //   companyImageUpload: Array.isArray(
                                      //     idx.companyImageUpload
                                      //   )
                                      //     ? idx.companyImageUpload
                                      //     : [],
                                      //   companySize: Array.isArray(
                                      //     idx.companySize
                                      //   )
                                      //     ? idx.companySize
                                      //     : [],
                                      //   headquarters: idx.headquarters || "",
                                      //   foundedYear: idx.foundedYear || "",
                                      //   socialLinkFB: idx.socialLinkFB || "",
                                      //   socialLinkIn: idx.socialLinkIn || "",
                                      //   socialLinkTw: idx.socialLinkTw || "",
                                      //   socialLinkIns: idx.socialLinkIns || "",
                                      //   socialLinkYou: idx.socialLinkYou || "",
                                      // };

                                      // console.log(formValue);
                                      // sessionStorage.setItem(
                                      //   "formValue",
                                      //   JSON.stringify(formValue)
                                      // );
                                      // // ManageJobPostEdit
                                      // // ManageJobPostEdit(idx);
                                      // // handleDelete(idx.id);
                                      // console.log("SDDD");
                                    }}
                                    style={{ color: "blue" }}
                                    icon={<Edit />}
                                  >
                                    Edit
                                  </IconButton>
                                  <IconButton
                                    // onClick={async () => {
                                    //   const options = {
                                    //     okButtonText: "Yes",
                                    //     cancelButtonText: "Cancel",
                                    //   };
                                    //   const result = await confirm(
                                    //     "Are you sure?",
                                    //     options
                                    //   );

                                    //   if (result) {
                                    //     console.log("You clicked Yes");
                                    //   }
                                    // }}
                                    onClick={() => {
                                      handleDelete(idx.id);
                                    }}
                                    style={{
                                      color: "red",
                                      "align-items": "center",
                                    }}
                                    icon={<Trash />}
                                  >
                                    Delete
                                  </IconButton>
                                </ButtonToolbar>
                                <br />
                                {/* <span className="icon-heart"></span> */}
                                <div
                                  className="p-2"
                                  onClick={() => {
                                    navigate("/employer/manage-job-candidate-list", {
                                      state: {
                                        idx,
                                      },
                                    });
                                  }}
                                >
                                  <Link className="tf-btn mb-3">Details</Link>
                                </div>
                                <p>posted by Me | {idx.post_uploaded_date2} </p>
                              </div>
                            </div>
                          </div>
                        ))}
                      </TabPanel>
                      <TabPanel className="inner">
                        <div className="group-col-2">
                          {jobManagePostingList.map((idx) => (
                            <div
                              key={idx.id}
                              className="features-job wd-thum-career style-2 cl2 mb1"
                            >
                              <p>posted by Me | {idx.post_uploaded_date2} </p>
                              <div className="job-archive-header">
                                <div className="career-header-left">
                                  {/* <img
                            src={`${process.env.REACT_APP_ROOT_URL}${idx.img} `}
                            alt="jobtex"
                            className="thumb"
                          /> */}
                                  <div className="career-left-inner">
                                    {/* <h4>
                              <Link to="#">{idx.unit}</Link>
                            </h4> */}
                                    <h3>
                                      <Link>{idx.title}</Link>&nbsp;
                                      {idx.status !== 0 && (
                                        <span className="icon-bolt"></span>
                                      )}
                                      <br />
                                      <small>{idx.role}</small>
                                      <h6>
                                        Total Applied Candidate :{" "}
                                        {idx.total_applied}
                                      </h6>
                                    </h3>
                                    {/* <ul className="career-info">
                              <li>{idx.cate}</li>
                              <li>
                                <span className="icon-map-pin"></span>
                                {idx.map}
                              </li>
                              <li>
                                <span className="icon-dolar1"></span>
                                {idx.price}$/hour
                              </li>
                            </ul> */}
                                  </div>
                                </div>
                                {/* <div className="career-header-right">
                          <span className="icon-heart"></span>
                        </div> */}
                              </div>
                              {/* job-archive-footer */}
                              <div className="row ">
                                <div className="pb-3">
                                  <ButtonToolbar>
                                    <IconButton
                                      style={{ color: "green" }}
                                      icon={<AddOutlineIcon />}
                                    >
                                      Repost
                                    </IconButton>
                                    <IconButton
                                      style={{ color: "blue" }}
                                      icon={<Edit />}
                                    >
                                      Edit
                                    </IconButton>
                                    <IconButton
                                      style={{ color: "red" }}
                                      icon={<Trash />}
                                    >
                                      Delete
                                    </IconButton>
                                  </ButtonToolbar>
                                </div>
                                <br />
                                <Link to="/" className="tf-btn mb-3">
                                  Details
                                </Link>
                              </div>
                            </div>
                          ))}
                        </div>
                      </TabPanel>
                    </>
                  ) : (
                    <LottieControl />
                  )}
                </>
              )}
            </div>
          </Tabs>

          <ul className="pagination-job p-top-st1">
            <RSPaginator
              prev
              next
              first
              last
              ellipsis
              size="lg"
              boundaryLinks
              pages={lastPage - 1}
              activePage={currentPage}
              onSelect={(e) => {
                handlePageChange(e);
              }}
            />
          </ul>
          <RSDivider />
        </div>
      </div>
    </section>
  );
}

export default ManageJobPost;
