import rootSession from "../../api/session";
import { sweetAlert } from "../../utils/sweetAlert";
import axiosApi from "../../api/axios";
import domain from "../../api/constent";
import { Link, useNavigate } from "react-router-dom";

export const LOGIN_REQUEST_EMP = "LOGIN_REQUEST_EMP";
export const LOGIN_SUCCESS_EMP = "LOGIN_SUCCESS_EMP";
export const LOGIN_FAILURE_EMP = "LOGIN_FAILURE_EMP";
export const LOGOUT_EMP = "LOGOUT_EMP";

export const loginEmployer = (credentials, navigate) => async (dispatch) => {
  dispatch({ type: LOGIN_REQUEST_EMP });

  try {
    await rootSession.getCSRF();

    const response = await axiosApi.post(domain.EmployerLogin_URL, credentials);
    const accessToken = response?.data?.accessToken;

    if (!accessToken) {
      sweetAlert("Error!", "Token not found.", 2);
      return dispatch({ type: LOGIN_FAILURE_EMP, payload: "Token not found" });
    }

    localStorage.setItem("token", accessToken);
    localStorage.setItem("redirection", response?.data?.type);
    localStorage.setItem("node", response?.data?.node);

    const loginDetails = await axiosApi.post(domain.LoggedIn, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const EmployerData = {
      id: loginDetails?.data?.data?.id,
      name: loginDetails?.data?.data?.name || "N/A",
      encryptId: loginDetails?.data?.data?.encrypt_id || "N/A",
      firstName: loginDetails?.data?.data?.first_name || "N/A",
      lastName: loginDetails?.data?.data?.last_name || "N/A",
      phoneNo: loginDetails?.data?.data?.phone_no || "N/A",
      email: loginDetails?.data?.data?.email || "N/A",
      countryCode: loginDetails?.data?.data?.country_code || "N/A",
      homeLocation: loginDetails?.data?.data?.home_location || "N/A",
      businessLocation: loginDetails?.data?.data?.business_location || "N/A",
      profileImage: loginDetails?.data?.data?.profile_image || "",
      businessLogo: loginDetails?.data?.data?.business_logo || "",
      status: loginDetails?.data?.data?.status || 0,
      type: 2,
      token: `Bearer ${accessToken}`,
    };
    sessionStorage.setItem("employer", JSON.stringify(EmployerData));

    dispatch({ type: LOGIN_SUCCESS_EMP, payload: EmployerData });
    navigate("/employer/dashboard");

    sweetAlert("Success!", "You have logged in successfully.", 1);
  } catch (err) {
    dispatch({
      type: LOGIN_FAILURE_EMP,
      payload: err.response?.data || err.message,
    });

    sweetAlert("Failed!", "Login failed. Please check your credentials.", 2);
  }
};

export const logoutUser = () => (dispatch) => {
  localStorage.removeItem("token");
  localStorage.removeItem("type");
  dispatch({ type: LOGOUT_EMP });
};
