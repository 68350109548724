import React, { useState, useEffect, useRef, useMemo } from "react";
import {
  Checkbox,
  FormGroup,
  FormControlLabel,
  TextField,
  Button,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  Select,
  MenuItem,
  InputLabel,
  Typography,
  Autocomplete,
  Chip,
  // Grid,
} from "@mui/material";

import { useEmployers } from "../../Employer/hooks/profile/useEmployer";
// import { Close as CloseIcon, Add as AddIcon } from "@mui/icons-material";
import Session from "../../api/session";
import axios from "../../api/axios";
import domain from "../../api/constent";
import { ShimmerCategoryItems } from "shimmer-effects-react";
import apiService from "../../api/apiService";
import CloseIcon from "@rsuite/icons/Close";
import {
  InputStyles,
  TitleHeading,
} from "../components/Forms/rsuite/InputStyles";

// import '../assets/styles/styles.css';
import KeySkills from "./PostVacancy/skills/SkillJobPost";
import SearchSelect from "./PostVacancy/search-select/SearchSelect";
import WorkModeDropdown from "./PostVacancy/work-mode/WorkModeDropdown";
import SalaryForm from "./PostVacancy/salary-form/SalaryForm";
import IndustrySelection from "./PostVacancy/Candidate-Industry/IndustrySelection";
import { sweetAlert } from "../../utils/sweetAlert";
import Get from "../../Employer/components/PostVacancy/Test/Get";
import JODITEditor from "./Forms/SummarNote/JoditEditor";
import DiversityHiring from "./PostVacancy/Diversity-Hiring/DiversityHiring";
import CollaborationSettings from "./PostVacancy/Collaboration-Settings/CollaborationSettings";
import CompanyInformation from "./PostVacancy/Company-Info/CompanyInformation";
import CompNestedSelect from "../components/Forms/Inputs/CompNestedSelect";
import SearchableGroupedSelect from "../components/Forms/Inputs/SearchableGroupedSelect";
import CompCheckBox from "./Forms/Inputs/CompCheckBox";
import CompNiceModal from "../components/Forms/Inputs/CompNiceModel";
import NiceModal from "@ebay/nice-modal-react";
import {
  CheckPicker,
  TreePicker,
  InputPicker as RSInputPicker,
  InlineEdit as RSInlineEdit,
  VStack,
  Schema,
  Panel,
  FlexboxGrid,
  Form,
  Uploader,
  CheckPicker as RSCheckPicker,
  Drawer as RSDrawer,
  ButtonToolbar as RSButtonToolbar,
  Radio as RSRadio,
  Button as RSButton,
  Input as RSInput,
  CheckboxGroup as RSCheckboxGroup,
  Checkbox as RSCheckbox,
  Toggle as RSToggle,
  RadioGroup as RSRadioGroup,
  SelectPicker as RSSelectPicker,
  CheckTreePicker,
  Toggle,
  IconButton,
  Stack,
  Whisper,
  Tooltip,
  Placeholder,
  Divider,
  Loader,
  Grid,
  Input,
  Row,
  Text,
} from "rsuite";
import { JSONTree } from "react-json-tree";
import AddQuestionsForm from "./PostVacancy/Add-Questions/AddQuestionsForm";
import jobAppliedModel from "../../Employer/model/JobPostingApply";
import CompAutoSearch from "./Forms/rsuite/CompAutoSearch";
import CompSelectSearch from "../../Employer/components/Forms/rsuite/CompSelectSearch";
import CompRSTextField from "../../Employer/components/Forms/rsuite/CompRSTextField";
import PostJobPreview from "../../Employer/views/post-vacancy/PostJobPreview.jsx";
import LoaderMount from "../../components/preloader";

import { Link, useNavigate } from "react-router-dom";
import { mockTreeData } from "../../api/mock/mock";
import CustomTagPicker from "./Forms/rsuite/JobPost/CustomTagPicker.jsx";
import ImageUploadPreviewRSuite from "../../Employer/components/PostVacancy/Test/ImageUploadPreviewRSuite";

import { useEmploymentTypes } from "../hooks/getList/useEmploymentTypes.jsx";
import { renderMenusLoadingSpinner } from "../components/Testing/LoadingSpinner";
// import LoadingSpinner from "../components/Testing/LoadingSpinner";
import ErrorMessage from "../components/Testing/LoadingSpinner";

import SpinnerIcon from "@rsuite/icons/legacy/Spinner";
import { useKeySkillsList } from "../hooks/getList/useKeySkillsList.jsx";
import { useDepartmentList } from "../hooks/getList/useDepartmentList.jsx";
import { useWorkingModeList } from "../hooks/getList/useWorkingModeList.jsx";
import { useLocationList } from "../hooks/getList/useLocationList.jsx";
import { useWorkingExpList } from "../hooks/getList/useWorkingExpList.jsx";
import { useSalaryTypeList } from "../hooks/getList/useSalaryTypeList.jsx";
import { useSalaryProcessTypeList } from "../hooks/getList/useSalaryProcessTypeList.jsx";
import { useSalaryRangeList } from "../hooks/getList/useSalaryRangeList.jsx";
import { useCompanyIndustriesList } from "../hooks/getList/useCompanyIndustriesList.jsx";
import { useEducationList } from "../hooks/getList/useEducationList.jsx";
import { useCandidateIndustriesList } from "../hooks/getList/useCandidateIndustriesList.jsx";
import { useNotificationFreqList } from "../hooks/getList/useNotificationFreqList.jsx";
import { useCompanySizeList } from "../hooks/getList/useCompanySizeList.jsx";
import { useCompanySubUserEmailList } from "../hooks/getList/useCompanySubUserEmailList.jsx";
import { useRoleList } from "../hooks/getList/useRoleList.jsx";
const TextFieldS = React.forwardRef((props, ref) => {
  const { name, label, accepter, required, error, ...rest } = props;
  return (
    <Form.Group ref={ref}>
      <Form.ControlLabel>
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: "bold",
            fontSize: "0.875rem",
          }}
        >
          {label}{" "}
          {required === true ? <span style={{ color: "red" }}>*</span> : null}
        </Typography>
      </Form.ControlLabel>
      <Form.Control name={name} accepter={accepter} {...rest} />
      {error && (
        <div style={{ color: "red", marginTop: "4px", fontSize: "12px" }}>
          {error}
        </div>
      )}
    </Form.Group>
  );
});
const model = Schema.Model({
  jobTitle: Schema.Types.StringType().isRequired("Job title is required"),
  jobType: Schema.Types.ArrayType()
    .minLength(1, "Select at least one Employee Type")
    .isRequired("Please select at least one Employee Type"),
  keySkills: Schema.Types.ArrayType()
    .minLength(2, "Select at least two skills")
    .isRequired("Please select at least two skills"),
  departmentCategory: Schema.Types.StringType().isRequired(
    "Please select Department Category"
  ),
  role: Schema.Types.StringType().isRequired("Please select Role"),
  workMode: Schema.Types.NumberType().isRequired(
    "Please select at least one Work Mode"
  ),
  minYear: Schema.Types.StringType().isRequired("Please select a Min Year"),
  maxYear: Schema.Types.StringType().isRequired("Please select a Max Year"),
  salaryType: Schema.Types.StringType().isRequired(
    "Please select a Salary Type"
  ),
  salaryProType: Schema.Types.StringType().isRequired(
    "Please select a Salary Process Type"
  ),
  minSalary: Schema.Types.StringType().isRequired("Please select a Min Salary"),
  maxSalary: Schema.Types.StringType().isRequired("Please select a Max Salary"),
  checkPercentage: Schema.Types.BooleanType(),
  companyIndustries: Schema.Types.StringType().isRequired(
    "Please select a Company Industries"
  ),
  educations: Schema.Types.ArrayType().isRequired(
    "Please select a Education & Qualification"
  ),
  candidateIndustries: Schema.Types.ArrayType()
    .minLength(2, "Select at least two Industries")
    .isRequired("Please select at least two Industries"),
  isDiversityHiringChecked: Schema.Types.BooleanType(),
  jobDescription: Schema.Types.StringType().isRequired(
    "Please Enter Job Description"
  ),
  requestCandidateVideoProfile: Schema.Types.BooleanType(),
  requestIncludeWalkInDetails: Schema.Types.BooleanType(),
  requestIncludeWalkInText: Schema.Types.StringType().addRule(
    (value, data) =>
      data.requestIncludeWalkInDetails === true
        ? value && value.trim().length > 0
        : true,
    "Please enter Walk In Text."
  ),
  companyName: Schema.Types.StringType().isRequired(
    "Please Enter Company Name"
  ),
  aboutCompany: Schema.Types.StringType().isRequired(
    "Please Enter About Company Details"
  ),
  companySize: Schema.Types.StringType().isRequired(
    "Please Enter Company Size"
  ),
  isSendMailChecked: Schema.Types.BooleanType(),
  selectedUserEmails: Schema.Types.ArrayType(),
  notificationFrequencyLists: Schema.Types.StringType(),
  companyEmail: Schema.Types.StringType().isRequired(
    "Please Enter Company Email"
  ),
  companyEmployeeSize: Schema.Types.StringType(),
  socialLinkFB: Schema.Types.StringType(),
  socialLinkIn: Schema.Types.StringType(),
  socialLinkTw: Schema.Types.StringType(),
  socialLinkIns: Schema.Types.StringType(),
  socialLinkYou: Schema.Types.StringType(),
});

const RSTextField = React.forwardRef((props, ref) => {
  const { name, label, required, accepter, error, ...rest } = props;
  return (
    <Form.Group ref={ref} controlId={name}>
      <Form.ControlLabel>
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: "bold",
            fontSize: "0.875rem",
          }}
        >
          {label}{" "}
          {required === true ? <span style={{ color: "red" }}>*</span> : null}
        </Typography>
      </Form.ControlLabel>
      <Form.Control name={name} accepter={accepter} {...rest} />
      {error && (
        <div style={{ color: "red", marginTop: "4px", fontSize: "12px" }}>
          {error}
        </div>
      )}
    </Form.Group>
  );
});
const RSInputField = React.forwardRef((props, ref) => {
  const { name, label, required, accepter, error, showMask, ...rest } = props;

  return (
    <Form.Group ref={ref}>
      <Form.ControlLabel>
        {label} {required && <span style={{ color: "red" }}>*</span>}
      </Form.ControlLabel>
      <Form.Control name={name} accepter={accepter || Input} {...rest} />
      {error && (
        <div style={{ color: "red", marginTop: "4px", fontSize: "12px" }}>
          {error}
        </div>
      )}
    </Form.Group>
  );
});

const JSONView = ({ formValue, formError }) => (
  <div style={{ marginBottom: 10 }}>
    <Panel className="json-tree-wrapper" header={<p>formValue</p>}>
      <JSONTree data={formValue} />
    </Panel>

    <Panel className="json-tree-wrapper" header={<p>formError</p>}>
      <JSONTree data={formError} />
    </Panel>
  </div>
);

const PostJobFormVacancy = (props) => {
  const navigate = useNavigate();
  // Job Description
  const placeholderJobDescription = `<div>
                  <h6>Role & responsibilities</h6>
                    <small>Outline the day-to-day responsibilities for this role.</small>
                    <br/><br/>
                    <h6>Preferred candidate profile</h6>
                    <small>Specify required role experience and skills, previous job experience, or relevant certifications.</small>
                    <br/><br/>
                    <h6>Perks and Benefits:</h6>
                    <small>Mention available facilities and benefits the company is offering with the job.</small>
                </div>`;
  const noteRefer = useRef(null);

  // about Company Details
  const placeholderCompInfo = `<div><b>Company Information :<b/>* <br />
    <small >Are you a User Experience Designer with a track record of delivering intuitive digital experiences that drive results? Are you a strategic storyteller and systems thinker who can concept and craft smart, world-class campaigns across a variety of mediums?
    Deloitte's Green Dot Agency is looking to add a Lead User Experience Designer to our experience design team. We want a passionate creative who's inspired by new trends and emerging technologies, and is able to integrate them into memorable user experiences. A problem solver who is entrepreneurial, collaborative, hungry, and humble; can deliver beautifully designed, leading-edge experiences under tight deadlines; and who has demonstrated proven expertise.</small> </div> `;
  const noteReferAboutCompany = useRef(null);

  const storedFormValue = JSON.parse(
    sessionStorage.getItem("formValue") || "{}"
  ); //previous stores

  const formRef = useRef();
  const [formError, setFormError] = useState({});
  const [formValue, setFormValue] = useState({
    jobTitle: storedFormValue.jobTitle || "",
    jobType: storedFormValue.jobType || [],
    keySkills: storedFormValue.keySkills || [],
    departmentCategory: storedFormValue.departmentCategory || "",
    role: storedFormValue.role || "",
    workMode: storedFormValue.workMode || "", //new
    jobLocation: storedFormValue.jobLocation || "", // storedFormValue.jobLocationList || [],//new
    baseLocationRemote: storedFormValue.baseLocationRemote || "",
    hybridPolicy: storedFormValue.hybridPolicy || "",
    minYear: storedFormValue.minYear || "",
    maxYear: storedFormValue.maxYear || "",
    salaryType: storedFormValue.salaryType || "",
    salaryProType: storedFormValue.salaryProType || "",
    minSalary: storedFormValue.minSalary || "",
    maxSalary: storedFormValue.maxSalary || "",
    checkPercentage: storedFormValue.checkPercentage || "", //false
    percentage: storedFormValue.percentage || 0,
    variableSalaryType: storedFormValue.variableSalaryType || "₹",
    variableMinSalary: storedFormValue.variableMinSalary || 0,
    variableMaxSalary: storedFormValue.variableMaxSalary || 0,
    companyIndustries: storedFormValue.companyIndustries || "",
    hideSalary: storedFormValue.hideSalary || false,
    educations: storedFormValue.educations || [],
    candidateIndustries: storedFormValue.candidateIndustries || [],
    isDiversityHiringChecked: storedFormValue.isDiversityHiringChecked || false,
    jobDescription: storedFormValue.jobDescription || "",
    vacancy: storedFormValue.vacancy || false,
    additionalVacancy: storedFormValue.additionalVacancy || "",
    requestCandidateVideoProfile:
      storedFormValue.requestCandidateVideoProfile || true,
    requestIncludeWalkInDetails:
      storedFormValue.requestIncludeWalkInDetails || true,
    requestIncludeWalkInText: storedFormValue.requestIncludeWalkInText || "",
    addQuestionsList: storedFormValue.addQuestionsList || [],
    companyName: storedFormValue.companyName || storedFormValue.comp_name || "",
    companyWebsite:
      storedFormValue.companyWebsite || storedFormValue.comp_url || "",
    aboutCompany:
      storedFormValue.aboutCompany || storedFormValue.comp_about || "",
    isSendMailChecked: storedFormValue.isSendMailChecked || false,
    selectedUserEmails: storedFormValue.selectedUserEmails || [],
    notificationValue: storedFormValue.notificationValue || [],
    companyEmail: storedFormValue.companyEmail || storedFormValue.email || "",
    companyVideoContent:
      storedFormValue.companyVideoContent || storedFormValue.video_url || "", //new
    companyImageUpload: storedFormValue.companyImageUpload || [],
    companySize: storedFormValue.companySize || [],
    socialLinkFB:
      storedFormValue.socialLinkFB || storedFormValue.social_link_fb || "",
    socialLinkIn:
      storedFormValue.socialLinkIn || storedFormValue.social_link_in || "",
    socialLinkTw:
      storedFormValue.socialLinkTw || storedFormValue.social_link_tw || "",
    socialLinkIns:
      storedFormValue.socialLinkIns || storedFormValue.social_link_ins || "",
    socialLinkYou:
      storedFormValue.socialLinkYou || storedFormValue.social_link_you || "",
  });
  // headquarters: storedFormValue.headquarters || "",
  // foundedYear: storedFormValue.foundedYear || "",

  const handleHideSalaryChange = () => {
    setFormValue((prevState) => ({
      ...prevState,
      hideSalary: !prevState.hideSalary,
    }));
  };
  const handleVacancyChanges = (val) => {
    setFormValue((prev) => ({
      ...prev,
      vacancy: val,
      additionalVacancy: val === "false" ? "" : prev.additionalVacancy, // Clear additionalVacancy if "No"
    }));
  };
  const [questionariesList, setQuestionariesList] = useState(
    formValue.addQuestionsList
  );

  const [questionaries, setQuestionaries] = useState([]);
  const [loading, setLoading] = useState(true);
  //   const [error, setError] = useState(null);

  // Default Details Company
  const [employerProfileDetail, setEmployerProfileDetails] = useState(null);
  const {
    data: employerProfileData,
    error,
    isLoading: empLoading,
  } = useEmployers();

  //new
  const { employmentTypes, isLoading } = useEmploymentTypes();
  const { keySkillsList } = useKeySkillsList();
  const { departmentCategoryList } = useDepartmentList();
  const { roleList } = useRoleList(formValue.departmentCategory);
  const { workModeList } = useWorkingModeList();
  const { jobLocationList, onItemsRendered, renderMenu, loadMore } =
    useLocationList();
  const { workExpList } = useWorkingExpList();
  const { salaryTypeList } = useSalaryTypeList();
  const { salaryProcessTypeList } = useSalaryProcessTypeList();
  const { salaryRangeList } = useSalaryRangeList();
  const { companyIndustriesList } = useCompanyIndustriesList();
  const { educationOptionsList } = useEducationList();
  const { candidateIndustriesList } = useCandidateIndustriesList();
  const { notificationFrequencyLists } = useNotificationFreqList();
  const { companySizeList } = useCompanySizeList();
  const { companySubUserEmails } = useCompanySubUserEmailList();

  // Suggestion Question List
  const [getSuggestionList, setSuggestionList] = useState([]);

  const handleDepartmentChange = (value) => {
    setFormValue((prev) => ({
      ...prev,
      departmentCategory: value,
      role: "",
    }));
  };

  const handleRoleChange = (value) => {
    setFormValue((prev) => ({
      ...prev,
      role: value,
    }));
  };
  if (employerProfileData) {
    console.log(employerProfileData);

    // setEmployerProfileDetails(employerProfileData.data);
    sessionStorage.setItem(
      "formValue",
      JSON.stringify(employerProfileData.data)
    );
  }
  useEffect(() => {
    // const getQuestionMountList = async () => {
    //   const value = await apiService.getQuestionsList();
    //   console.log(value);
    //   setQuestionaries(value);
    // };
    // getQuestionMountList();
    // getQuestionSugggstion();
    // fetchData();
    // console.log("extract Value", storedFormValue.addQuestionsList);
    // console.log(roleList);
    // setEmploymentList(employmentTypes);
    // GetRoleListMount(formValue?.departmentCategory);
  }, [departmentCategoryList]); //departmentCategoryList questionariesList

  //   const handleChange = (values) => {
  //     const selectedOptions = employeeTypes.filter((option) =>
  //       values.includes(option.value)
  //     );
  //     setFormValue({ ...formValue, jobType: selectedOptions });
  //     console.log(selectedOptions);
  //   };

  // const getQuestionSugggstion = async () => {
  //   const getlist = await apiService.getQuestionSugggstionList();
  //   console.log(getlist);
  //   setSuggestionList(getlist);
  // };

  const salaryTypeChange = (typeSelect) => {
    setFormValue((prevState) => ({
      ...prevState,
      salaryType: typeSelect,
    }));
    handlePercentageChanges(
      formValue.percentage,
      formValue.salaryType,
      formValue.minSalary,
      formValue.maxSalary
    );
  };
  const minSalaryChange = (selectedValue) => {
    setFormValue((prevState) => ({
      ...prevState,
      minSalary: selectedValue,
    }));

    handlePercentageChanges(
      formValue.percentage,
      formValue.salaryType,
      selectedValue,
      formValue.maxSalary
    );
  };

  const maxSalaryChange = (selectedValue) => {
    setFormValue((prevState) => ({
      ...prevState,
      maxSalary: selectedValue,
    }));

    handlePercentageChanges(
      formValue.percentage,
      formValue.salaryType,
      formValue.minSalary,
      selectedValue
    );
  };
  const salaryProcessTypeChange = (selectedValue) => {
    setFormValue((prevState) => ({
      ...prevState,
      salaryProType: selectedValue,
    }));
  };

  const handlePercentageChanges = (
    percentageValue,
    salaryType = formValue.salaryType,
    minSalary = formValue.minSalary,
    maxSalary = formValue.maxSalary
  ) => {
    if (!percentageValue || isNaN(percentageValue) || percentageValue < 0) {
      console.log("Invalid percentage value:", percentageValue);
      return;
    }

    // console.log("Percentage Value:", percentageValue);
    // console.log("Min Salary:", minSalary);
    // console.log("Max Salary:", maxSalary);

    const selectedMinOptions =
      salaryRangeList.find(
        (option) => String(minSalary) === String(option.value)
      )?.label || "0";
    const selectedMaxOptions =
      salaryRangeList.find(
        (option) => String(maxSalary) === String(option.value)
      )?.label || "0";

    const selectedTypeOptions =
      salaryTypeList.find(
        (option) => String(salaryType) === String(option.value)
      )?.label || "₹";

    const numericSelectedMin =
      parseFloat(selectedMinOptions.replace(",", "")) || 0;
    const numericSelectedMax =
      parseFloat(selectedMaxOptions.replace(",", "")) || 0;

    const vMin = ((numericSelectedMin * percentageValue) / 100).toFixed(2);
    const vMax = ((numericSelectedMax * percentageValue) / 100).toFixed(2);

    setFormValue((prevState) => ({
      ...prevState,
      percentage: percentageValue,
      variableSalaryType: selectedTypeOptions,
      variableMinSalary: vMin,
      variableMaxSalary: vMax,
    }));

    // console.log("Calculated Labels:", selectedTypeOptions);
    // console.log("Calculated vMin:", vMin);
    // console.log("Calculated vMax:", vMax);
  };

  const jobTypeRef = useRef();

  const handleSubmit = () => {
    if (!formRef.current.check()) {
      console.error("Form Error");
      sweetAlert(
        "Error! Please Check values and try again",
        formRef.current.check(),
        2
      );
      return;
    }
    const formData = formValue;
    sweetAlert("Success ", "Preview Page Next", 1);

    // console.log(formData);

    navigate("/post-review", {
      state: {
        formData,
        questionariesList,
        employmentTypes,
        keySkillsList,
        departmentCategoryList,
        roleList,
        workModeList,
        jobLocationList,
        workExpList,
        salaryTypeList,
        salaryProcessTypeList,
        salaryRangeList,
        educationOptionsList,
        candidateIndustriesList,
        companyIndustriesList,
        companySizeList,
        notificationFrequencyLists,
        companySubUserEmails,
      },
    });

    // console.log(formValue, "Form Value");
  };

  const handleImageUpload = (e) => {
    let files = e.target.files;
    const fileArray = Array.from(files);
    const newImages = fileArray.map((file, index) => ({
      id: file.name,
      src: URL.createObjectURL(file),
      file,
    }));
    setFormValue((prevState) => ({
      ...prevState,
      companyImageUpload: [
        ...(prevState.companyImageUpload || []),
        ...newImages,
      ],
    }));
  };
  const removeImage = (id) => {
    setFormValue((prevState) => ({
      ...prevState,
      companyImageUpload: prevState.companyImageUpload.filter(
        (image) => image.id !== id
      ),
    }));
  };
  return isLoading == false ? (
    <>
      <div
        className="text-center"
        style={{
          display: "grid",
          placeItems: "center",
          height: "100vh",
        }}
      >
        <LoaderMount.CuLoader />
      </div>
    </>
  ) : (
    <div>
      <Row
        component="form"
        xs={12}
        lg={12}
        sm={12}
        md={12}
        sx={{
          margin: "auto",
        }}
      >
        <Text size="xxl" weight="semibold">
          {" "}
          Post a Job Vacancy
        </Text>
        <Form
          ref={formRef}
          onChange={setFormValue}
          onCheck={setFormError}
          formValue={formValue}
          model={model}
        >
          <div className="row">
            <div className="col-md-4">
              <TextFieldS
                name="jobTitle"
                label="job Title"
                placeholder="Enter Your Job Title"
                required={true}
                style={InputStyles().input}
              />
            </div>
            <div className="col-md-4">
              <RSTextField
                name="jobType"
                label="Employment Type"
                required={true}
                accepter={({ value, onChange }) => (
                  <CustomTagPicker
                    value={value}
                    apiList={employmentTypes}
                    onHandlerChange={onChange}
                    placeholder="Select Employee Type"
                  />
                )}
              />
            </div>
            <div className="col-md-4">
              <RSTextField
                name="keySkills"
                label="Key Skills"
                required={true}
                accepter={({ value, onChange }) => (
                  <CompAutoSearch
                    value={value}
                    onChange={onChange}
                    list={keySkillsList}
                  />
                )}
              />
            </div>
            <div className="col-md-4">
              <RSTextField
                name="departmentCategory"
                required={true}
                label="Department Category"
                onChange={handleDepartmentChange}
                accepter={({ value, onChange }) => (
                  <CompSelectSearch
                    value={value}
                    onChange={onChange}
                    list={departmentCategoryList}
                  />
                )}
              />
            </div>

            <div className="col-md-4">
              <RSTextField
                name="role"
                required={true}
                label="Role"
                accepter={({ value, onChange }) => (
                  <CompSelectSearch
                    value={value}
                    onChange={handleRoleChange}
                    list={roleList}
                  />
                )}
              />
            </div>
            <div className="col-md-4">
              <RSTextField
                name="workMode"
                required={true}
                label="Work Mode"
                accepter={({ value, onChange }) => (
                  <CompSelectSearch
                    value={value}
                    onChange={onChange}
                    list={workModeList}
                  />
                )}
              />
            </div>
            <div className="col-md-4">
              {formValue.workMode === 1 || formValue.workMode === 2 ? (
                <RSTextField
                  name="jobLocation"
                  required={true}
                  label="Job Location"
                  accepter={({ value, onChange }) => (
                    <RSSelectPicker
                      value={value}
                      onChange={onChange}
                      data={jobLocationList}
                      style={{ width: 224 }}
                      virtualized
                      renderMenu={renderMenu}
                      listProps={{
                        onItemsRendered,
                      }}
                      multi
                    />
                  )}
                />
              ) : (
                <></>
              )}
              {formValue.workMode === 2 ? (
                <RSTextField
                  name="hybridPolicy"
                  required={true}
                  label="Define your hybrid policy"
                  accepter={({ value, onChange }) => (
                    <RSInlineEdit
                      placeholder="Click to Define your hybrid policy"
                      style={{ width: 300 }}
                    >
                      <RSInput as="textarea" rows={5} />
                    </RSInlineEdit>
                  )}
                />
              ) : formValue.workMode === 3 ? (
                <RSTextField
                  name="baseLocationRemote"
                  required={true}
                  label="Base location for remote job"
                  accepter={({ value, onChange }) => (
                    <RSSelectPicker
                      value={value}
                      onChange={onChange}
                      data={jobLocationList}
                      style={{ width: 224 }}
                      virtualized
                      renderMenu={renderMenu}
                      listProps={{
                        onItemsRendered,
                      }}
                    />
                  )}
                />
              ) : null}
            </div>
            <Divider />

            <Typography variant="subtitle1" sx={InputStyles().title}>
              Work Experience(Years) <span style={{ color: "red" }}>*</span>
            </Typography>
            <Typography variant="body2">
              Select Minimum or Maximum Experience{" "}
            </Typography>
            <div className="col-md-4" style={InputStyles().content}>
              <RSTextField
                name="minYear"
                required={true}
                label="Min Years"
                accepter={({ value, onChange }) => (
                  <CompSelectSearch
                    value={value}
                    onChange={onChange}
                    list={workExpList}
                  />
                )}
              />
            </div>

            <div className="col-md-4" style={InputStyles().content}>
              <RSTextField
                name="maxYear"
                required={true}
                label="Max Years"
                accepter={({ value, onChange }) => (
                  <CompSelectSearch
                    value={value}
                    onChange={onChange}
                    list={workExpList}
                  />
                )}
              />
            </div>
            <div className="col-md-4" style={InputStyles().content}>
              <RSTextField
                name="isDiversityHiringChecked"
                required={false}
                label="Diversity Hiring"
                accepter={({ value, onChange }) => (
                  <>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Checkbox
                        checked={value}
                        onChange={(_, isChecked) => {
                          onChange(isChecked);
                        }}
                      />
                      {/* Text */}
                      <span style={{ marginLeft: "8px" }}>
                        Hire women candidates for this role and promote
                        diversity in the workplace.
                      </span>
                    </div>
                    <div>
                      <Typography variant="caption" color="textSecondary">
                        Diversity hiring feature currently requires no
                        additional credits.
                      </Typography>
                    </div>
                  </>
                )}
              />
            </div>

            <Divider />
            <Typography
              variant="subtitle1"
              sx={InputStyles().title}
              gutterBottom
            >
              Annual Salary Range <span style={{ color: "red" }}>*</span>
            </Typography>
            <Typography variant="body2">
              Enter the salary offered for this job
            </Typography>
            <div className="col-md-4" style={InputStyles().content}>
              <RSTextField
                name="salaryType"
                required={true}
                label="Salary Type"
                onChange={salaryTypeChange}
                accepter={({ value, onChange }) => (
                  <RSInputPicker
                    style={InputStyles().input}
                    data={salaryTypeList}
                    value={value}
                    onChange={onChange}
                  />

                  // <CompSelectSearch value={value} onChange={onChange} list={salaryTypeList} />
                )}
              />
            </div>
            {/* <Typography variant="body1" sx={{ mx: 1 }}>
              -
            </Typography> */}
            <div className="col-md-4" style={InputStyles().content}>
              <RSTextField
                name="salaryProType"
                required={true}
                label="Salary Process Type"
                onChange={salaryProcessTypeChange}
                accepter={({ value, onChange }) => (
                  <CompSelectSearch
                    value={value}
                    onChange={onChange}
                    list={salaryProcessTypeList}
                  />
                )}
              />
            </div>
            <div className="col-md-4" style={InputStyles().content}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formValue.hideSalary}
                    onChange={handleHideSalaryChange}
                  />
                }
                label={
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "0.875rem", color: "text.primary" }}
                  >
                    Hide salary details from candidates
                  </Typography>
                }
              />
              {formValue.hideSalary && (
                <Row>
                  <Typography variant="body2" color="textSecondary">
                    Displaying salary invites higher number of responses.
                    However, if you want to hide the salary, please enter the
                    correct salary to reach out to a relevant pool of
                    candidates.
                  </Typography>
                </Row>
              )}
            </div>

            <div className="col-md-4" style={InputStyles().content}>
              <RSTextField
                name="minSalary"
                required={true}
                label="Min Salary"
                onChange={minSalaryChange}
                accepter={({ value, onChange }) => (
                  <CompSelectSearch
                    value={value}
                    onChange={onChange}
                    list={salaryRangeList}
                  />
                )}
              />
            </div>
            {/* <Typography variant="body1" sx={{ mx: 1 }}>
              to
            </Typography> */}
            <div className="col-md-4" style={InputStyles().content}>
              <RSTextField
                name="maxSalary"
                required={true}
                label="Max Salary"
                onChange={maxSalaryChange}
                accepter={({ value, onChange }) => (
                  <CompSelectSearch
                    value={value}
                    onChange={onChange}
                    list={salaryRangeList}
                  />
                )}
              />
            </div>
            <br />
            {/* </Row> */}
            <div className="col-md-4" style={InputStyles().content}>
              {!formValue.checkPercentage ? (
                // <Row>
                <p>
                  Entire salary is fixed. It does not include any variable
                  component.{" "}
                  <span
                    style={{ color: "blue", cursor: "pointer" }}
                    onClick={() =>
                      setFormValue((prevState) => ({
                        ...prevState,
                        checkPercentage: true,
                      }))
                    }
                  >
                    Change
                  </span>
                </p>
              ) : (
                <>
                  <Typography variant="h6">
                    Percentage of Salary that is Variable{" "}
                    <span
                      style={{ color: "blue", cursor: "pointer" }}
                      onClick={() =>
                        setFormValue((prevState) => ({
                          ...prevState,
                          checkPercentage: false,
                          percentage: "",
                          variableMinSalary: "",
                          variableMinSalary: "",
                          variableMaxSalary: "",
                        }))
                      }
                    >
                      Back
                    </span>
                  </Typography>
                  <RSTextField
                    onChange={(e) =>
                      handlePercentageChanges(
                        e,
                        formValue.salaryType,
                        formValue.minSalary,
                        formValue.maxSalary
                      )
                    }
                    min="0"
                    name="percentage"
                    label="Percentage Fixed"
                    type="number"
                    required={true}
                  />
                  <Typography variant="body2" color="textSecondary">
                    Entire salary is fixed:{" "}
                    <strong>
                      {" "}
                      {formValue.variableSalaryType}{" "}
                      {formValue.variableMinSalary || 0}-
                      {formValue.variableMaxSalary || 0}
                    </strong>
                  </Typography>
                </>
              )}
            </div>

            <div className="col-md-4" style={InputStyles().content}>
              <RSTextField
                name="companyIndustries"
                required={true}
                label="Company Industry"
                accepter={({ value, onChange }) => (
                  <CompSelectSearch
                    value={value}
                    onChange={onChange}
                    list={companyIndustriesList}
                  />
                )}
              />
            </div>
            <div className="col-md-4" style={InputStyles().content}>
              {/* <VStack> */}
              <RSTextField
                name="educations"
                required={true}
                label="Educational Qualification"
                accepter={({ value, onChange }) => (
                  <CheckTreePicker
                    value={value}
                    onChange={(val) => {
                      // Explicitly handle value change
                      onChange(val);
                    }}
                    searchable
                    data={educationOptionsList}
                    defaultExpandAll
                    cascade
                    placeholder="Select Education Qualification"
                    style={InputStyles().input}
                  />
                )}
              />
            </div>
            {/* </VStack> */}

            <div className="col-md-4" style={InputStyles().content}>
              <RSTextField
                name="candidateIndustries"
                required={true}
                label="Candidate Industries"
                accepter={({ value, onChange }) => (
                  <CheckTreePicker
                    value={value}
                    onChange={onChange}
                    data={candidateIndustriesList}
                    defaultExpandAll
                    cascade
                    placeholder="Select Candidate Industries"
                    style={{ width: 280 }}
                  />
                  //     <TreePicker
                  //     value={value}
                  //     onChange={(val) => {
                  //         // Explicitly handle value change
                  //         onChange(val);
                  //     }}
                  //     data={educationOptionsList}
                  //     defaultExpandAll
                  //     style={{ width: 246 }}
                  //     searchable
                  //     blockNode
                  //     checkable // Enables checkboxes for multiple selection
                  //     cascade
                  // />
                )}
              />
            </div>

            <Divider />
            <Row
              sx={{
                border: "1px solid #ccc",
                borderRadius: "5px",
                padding: "10px",
              }}
            >
              <Typography
                variant="subtitle1"
                sx={InputStyles().title}
                gutterBottom
              >
                Job Detail Description
              </Typography>
              <Typography variant="body2">
                Enter your Job Description
              </Typography>
              <div className="col-md-12" style={InputStyles().content}>
                <RSTextField
                  name="jobDescription"
                  required={true}
                  label="Job Description"
                  accepter={({ value, onChange }) => (
                    <JODITEditor
                      label=""
                      editor={noteRefer}
                      content={value || placeholderJobDescription}
                      placeholder={placeholderJobDescription}
                      onBlurs={(content) => {
                        onChange(content);
                        // console.log(content);
                      }}
                    />
                  )}
                  // value={jobDescription}
                  // onChange={setJobDescription}
                />
              </div>

              <Form.Group style={InputStyles().content}>
                <Form.ControlLabel>
                  Do you have more than one vacancy for this job?
                </Form.ControlLabel>
                <RSRadioGroup
                  name="vacancy"
                  inline
                  style={InputStyles().input}
                  value={formValue.vacancy}
                  onChange={handleVacancyChanges}
                >
                  <RSRadio value="true">Yes</RSRadio>
                  <RSRadio value="false">No</RSRadio>
                </RSRadioGroup>
              </Form.Group>

              {formValue.vacancy === "true" && (
                <RSTextField
                  style={InputStyles().input}
                  name="additionalVacancy"
                  label="Enter the number of vacancies"
                  required={formValue.vacancy === "true"}
                  value={formValue.additionalVacancy}
                  onChange={(value) =>
                    setFormValue({ ...formValue, additionalVacancy: value })
                  }
                />
              )}
              <RSTextField
                style={InputStyles().content}
                name="requestCandidateVideoProfile"
                required={false}
                accepter={({ value, onChange }) => (
                  <>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Checkbox
                        style={InputStyles().content}
                        checked={value}
                        onChange={(_, isChecked) => {
                          onChange(isChecked);
                        }}
                      />
                      {/* Text */}
                      <span style={{ marginLeft: "8px" }}>
                        Request candidates for video profile{" "}
                      </span>
                    </div>
                  </>
                )}
              />
              <RSTextField
                name="requestIncludeWalkInDetails"
                required={false}
                accepter={({ value, onChange }) => (
                  <>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Checkbox
                        checked={value}
                        onChange={(_, isChecked) => {
                          onChange(isChecked);
                        }}
                      />
                      {/* Text */}
                      <span style={{ marginLeft: "8px" }}>
                        Include walk-in details{" "}
                      </span>
                    </div>
                  </>
                )}
              />
              {formValue.requestIncludeWalkInDetails == true && (
                <RSTextField
                  rows={4}
                  style={InputStyles().input}
                  name="requestIncludeWalkInText"
                  label="Enter Walk-in details"
                  required={false}
                />
              )}

              {/* <br /> */}
            </Row>
            <Divider />

            <AddQuestionsForm
              // [questionariesList]
              getListApiQuestion={questionaries}
              onUpdateValue={setQuestionariesList}
              getQuestionList={questionariesList}
              // getQuestionSugggstionList={getSuggestionList}
            />
            <Divider />

            {/* <div
              sx={{
                border: "1px solid #ccc",
                borderRadius: "5px",
                padding: "10px",
              }}
            > */}
            <Typography
              variant="subtitle1"
              sx={InputStyles().title}
              gutterBottom
            >
              Collaboration with other users
            </Typography>
            <Typography variant="body2">
              Responses are only visible to you. Option to share access with
              sub-users via link.
            </Typography>

            <div className="col-md-12" style={InputStyles().content}>
              <RSTextField
                name="isSendMailChecked"
                required={false}
                // label="Diversity Hiring"
                accepter={({ value, onChange }) => (
                  <>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Checkbox
                        checked={value}
                        onChange={(_, isChecked) => {
                          onChange(isChecked);
                        }}
                      />
                      {/* Text */}
                      <span style={{ marginLeft: "8px" }}>
                        Notify me about AI-recommended applicants through email
                      </span>
                    </div>
                  </>
                )}
              />

              {formValue.isSendMailChecked && (
                <>
                  {" "}
                  <small>
                    On which email ids do you want to receive notifications of
                    matching applies?
                  </small>
                  <br />
                  <RSCheckPicker
                    data={companySubUserEmails}
                    value={formValue.selectedUserEmails}
                    onChange={(value) =>
                      setFormValue({
                        ...formValue,
                        selectedUserEmails: value,
                      })
                    }
                    placeholder="Select Emails"
                    style={{ width: 224 }}
                  />
                  {formError.selectedUserEmails && (
                    <div className="error">{formError.selectedUserEmails}</div>
                  )}
                </>
              )}
            </div>

            <div className="col-md-4" style={InputStyles().content}>
              <RSTextField
                name="companyName"
                label="Company Name"
                required={true}
                style={InputStyles().input}
              />
            </div>
            <div className="col-md-4" style={InputStyles().content}>
              <RSTextField
                name="companyWebsite"
                label="Company Website"
                required={false}
                style={InputStyles().input}
              />
            </div>
            <div className="col-md-4" style={InputStyles().content}>
              <RSTextField
                name="companyEmail"
                label="Company Email"
                required={true}
                style={InputStyles().input}
              />
            </div>
            <div className="col-md-4" style={InputStyles().content}>
              <RSTextField
                style={InputStyles().input}
                name="companyVideoContent"
                label="Company Upload Video"
                required={false}
              />
            </div>
            <div className="col-md-4" style={InputStyles().content}>
              <RSTextField
                name="companySize"
                label="Company Size"
                required={true}
                accepter={({ value, onChange }) => (
                  <CompSelectSearch
                    value={value}
                    onChange={onChange}
                    list={companySizeList}
                  />
                )}
              />
            </div>
            <div className="col-md-4" style={InputStyles().content}>
              <Form.Group>
                <Form.ControlLabel>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontWeight: "bold",
                      fontSize: "0.875rem",
                    }}
                  >
                    How often should mails be sent ?
                  </Typography>
                </Form.ControlLabel>
                <RSSelectPicker
                  name="notificationFrequency"
                  data={notificationFrequencyLists}
                  value={formValue.notificationValue}
                  onChange={(value) =>
                    setFormValue({ ...formValue, notificationValue: value })
                  }
                  placeholder="Choose frequency..."
                  style={InputStyles().input}
                />
                {formError.notificationValue && (
                  <div className="error">{formError.notificationValue}</div>
                )}
              </Form.Group>
            </div>
            <div className="col-md-12" style={InputStyles().content}>
              <Form.ControlLabel>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "0.875rem",
                  }}
                >
                  Company Image Upload{" "}
                </Typography>
              </Form.ControlLabel>
              <br />
              <input
                type="file"
                accept="image/*"
                className="pb-2 pt-1"
                name="companyImageUpload"
                multiple
                onChange={handleImageUpload}
              ></input>
              <div
                style={{
                  display: "flex",
                  paddingTop: "2px",
                  gap: "10px",
                  overflowX: "auto",
                  padding: "10px",
                  border: "1px solid #ddd",
                  borderRadius: "5px",
                }}
              >
                {formValue.companyImageUpload.length === 0 && (
                  <p>No images uploaded yet.</p>
                )}
                {formValue.companyImageUpload.map((image) => (
                  <div
                    key={image.id}
                    style={{
                      position: "relative",
                      width: "120px",
                      height: "120px",
                      flex: "0 0 auto",
                      border: "1px solid #ddd",
                      borderRadius: "5px",
                      overflow: "hidden",
                    }}
                  >
                    <img
                      src={image.src}
                      alt="Uploaded"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                    <Whisper
                      placement="top"
                      trigger="hover"
                      speaker={<Tooltip>Remove Image</Tooltip>}
                    >
                      <IconButton
                        icon={<CloseIcon />}
                        size="xs"
                        circle
                        appearance="subtle"
                        style={{
                          position: "absolute",
                          top: "5px",
                          right: "5px",
                          backgroundColor: "rgba(255, 255, 255, 0.8)",
                        }}
                        onClick={() => removeImage(image.id)}
                      />
                    </Whisper>
                  </div>
                ))}
              </div>
            </div>
            <Divider />

            {/* <ImageUploadPreviewRSuite /> */}
            <Typography
              variant="subtitle1"
              sx={InputStyles().title}
              gutterBottom
            >
              About Detail Description
            </Typography>
            <Typography variant="body2">
              Enter your about Description
            </Typography>
            <div className="col-md-12" style={InputStyles().content}>
              <RSTextField
                name="aboutCompany"
                required={true}
                label="About Company"
                accepter={({ value, onChange }) => (
                  <JODITEditor
                    label=""
                    editor={noteReferAboutCompany}
                    content={value || placeholderCompInfo}
                    placeholder={placeholderCompInfo}
                    onBlurs={(content) => {
                      onChange(content);
                    }}
                  />
                )}
                // value={aboutCompany}
                // onChange={(value) => setAboutCompany(value)}
              />{" "}
            </div>

            <div className="col-md-4" style={InputStyles().content}>
              <RSTextField
                name="socialLinkFB"
                label="Facebook link"
                style={InputStyles().input}
                required={false}
              />
            </div>
            <div className="col-md-4" style={InputStyles().content}>
              <RSTextField
                name="socialLinkIn"
                style={InputStyles().input}
                label="Linkedin link"
                required={false}
              />
            </div>
            <div className="col-md-4" style={InputStyles().content}>
              <RSTextField
                style={InputStyles().input}
                name="socialLinkTw"
                label="
                Twitter link"
                required={false}
              />
            </div>
            <div className="col-md-4" style={InputStyles().content}>
              <RSTextField
                style={InputStyles().input}
                name="socialLinkIns"
                label="Instagram link"
                required={false}
              />
            </div>
            <div className="col-md-4" style={InputStyles().content}>
              <RSTextField
                style={InputStyles().input}
                name="socialLinkYou"
                label="Youtube link"
                required={false}
              />
            </div>
            {/* </Form.Group> */}

            {/* </div> */}
          </div>
        </Form>

        {/* <FlexboxGrid.Item colspan={12}>
            <JSONView formValue={formValue} formError={formError} />
          </FlexboxGrid.Item> */}
        {/* </Grid> */}

        {/* <Row sx={{ display: "flex", justifyContent: "space-between", mt: 4 }}> */}
        {/* <Button variant="contained" color="secondary" onClick={handleSubmit} >
                    Testing Data
                </Button> */}
        <Divider />

        <Button
          className="mt-4 text-right"
          variant="contained"
          color="primary"
          onClick={() => {
            handleSubmit();
            // console.log();
          }}
        >
          Save and Continue
        </Button>
        <Divider />

        {/* </Row> */}
      </Row>
    </div>
  );
};

export default PostJobFormVacancy;
