import * as Yup from "yup";
const employmentTypes = ["Full-time", "Part-time", "Contract", "Internship"];
const validationSchema = Yup.object().shape({
  jobTitle: Yup.string().required("Job title is required"),
  jobType: Yup.array()
    .min(1, "Select at least one Employee Type")
    .required("Please select at least one Employee Type"),
  // keySkills: Yup.array()
  //   .min(2, "Select at least two skills")
  //   .required("Please select at least two skills"),
  // departmentCategory: Yup.string().required(
  //   "Please select Department Category"
  // ),
  // role: Yup.string().required("Please select Role"),
  // workMode: Yup.number().required("Please select at least one Work Mode"),
  // minYear: Yup.string().required("Please select a Min Year"),
  // maxYear: Yup.string().required("Please select a Max Year"),
  // salaryType: Yup.string().required("Please select a Salary Type"),
  // salaryProType: Yup.string().required("Please select a Salary Process Type"),
  // minSalary: Yup.string().required("Please select a Min Salary"),
  // maxSalary: Yup.string().required("Please select a Max Salary"),
  // checkPercentage: Yup.boolean(),
  // companyIndustries: Yup.string().required("Please select a Company Industry"),
  // educations: Yup.array().required(
  //   "Please select an Education & Qualification"
  // ),
  // candidateIndustries: Yup.array()
  //   .min(2, "Select at least two Industries")
  //   .required("Please select at least two Industries"),
  // isDiversityHiringChecked: Yup.boolean(),
  // jobDescription: Yup.string().required("Please Enter Job Description"),
  // requestCandidateVideoProfile: Yup.boolean(),
  // requestIncludeWalkInDetails: Yup.boolean(),
  // requestIncludeWalkInText: Yup.string().when("requestIncludeWalkInDetails", {
  //   is: true,
  //   then: Yup.string().trim().required("Please enter Walk In Text."),
  //   otherwise: Yup.string().nullable(),
  // }),
  // companyName: Yup.string().required("Please Enter Company Name"),
  // aboutCompany: Yup.string().required("Please Enter About Company Details"),
  // companySize: Yup.string().required("Please Enter Company Size"),
  // isSendMailChecked: Yup.boolean(),
  // selectedUserEmails: Yup.array(),
  // notificationFrequencyLists: Yup.string(),
  // companyEmail: Yup.string()
  //   .email("Please enter a valid email")
  //   .required("Please Enter Company Email"),
  // companyEmployeeSize: Yup.string(),
  // socialLinkFB: Yup.string().url("Please enter a valid URL"),
  // socialLinkIn: Yup.string().url("Please enter a valid URL"),
  // socialLinkTw: Yup.string().url("Please enter a valid URL"),
  // socialLinkIns: Yup.string().url("Please enter a valid URL"),
  // socialLinkYou: Yup.string().url("Please enter a valid URL"),
});

export default validationSchema;
