import { createStore, applyMiddleware, combineReducers } from "redux";
import { thunk } from "redux-thunk";
import authReducer from "./reducers/authReducer";
import authReducerEmployer from "./reducers/Employer/authReducerEmployer";

const rootReducer = combineReducers({
  authUser: authReducer,
  authEmployer: authReducerEmployer,
});

const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;
