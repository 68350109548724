import React from "react";

export function InputStyles() {
  return {
    input: {
      width: "300px",
      borderRadius: "8px",
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    },
    title: {
      fontWeight: "bold",
      marginTop: "-16px",
      padding: "0px",
    },
    content: {
      marginTop: "0px",
      paddingTop: "17px",
    },
  };
}

export function TitleHeading() {
  return {
    fontWeight: "bold",
    marginTop: "-16px",
  };
}
