import { React, useState, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from "../ScrollToTop";
import LoaderMount from "../components/preloader";

import { Modal, Drawer, Button, HStack } from "rsuite";
import NiceModal from "@ebay/nice-modal-react";
import { baseTheme } from "../Employer/assets/global/Theme-variable"; //" './assets/global/Theme-variable';
import { ThemeProvider } from "@mui/material/styles";

// layout
import Footer from "./subCandidate/Footer"; //footer
import AppRoutePath from "./subCandidate/AppRoutePath"; //router
import Header from "./subCandidate/Header"; //header
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
const AppCandidate = () => {
  const theme = baseTheme;

  const [loading, setLoading] = useState(true);

  const queryClient = new QueryClient();

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [false]); //falsefuture={{ v7_startTransition: true }}

  return (
    <>
      {!loading ? (
        <BrowserRouter future={{ v7_startTransition: true }}>
          <ThemeProvider theme={theme}>
            <ScrollToTop />
            <NiceModal.Provider>
              <QueryClientProvider client={queryClient}>
                <AppRoutePath />
              </QueryClientProvider>
            </NiceModal.Provider>
          </ThemeProvider>
        </BrowserRouter>
      ) : (
        <LoaderMount.Preloader />
      )}
    </>
  );
};

export default AppCandidate;
