import React, { useState, useEffect, createContext } from "react";
import axiosDom from "../../api/axios";
import { Link, useNavigate } from "react-router-dom";
import img from "../../assets/images/review/google.png";
import img2 from "../../assets/images/review/tweet.png";
import domain from "../../api/constent";
import axiosApi from "../../api/axios";
import { Checkbox } from "rsuite";
import { sweetAlert } from "../../utils/sweetAlert";
import rootSession from "../../api/session";
import axiosOrigin from "axios";
import EmployerRouter from "../../routers/routers";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import SignInSchema from "../../schemas/Auth/LoginSchema";

import { useFormik } from "formik";
import mount from "../../schemas/Auth/LoginSchema";
import EmployerDashboard from "../../Employer/EmployerDashboard";
// export const getCSRFToken = () => axiosApi.get("/sanctum/csrf-cookie");
// export const ListContext = createContext(null);

import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../redux/actions/authActions";
import { loginEmployer } from "../../redux/actions/authActionEmployer";

function SignIn() {
  const [showPass, setShowPass] = useState(false);
  const [showPass2, setShowPass2] = useState(false);

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { loading: loadingStateUMP, error: loadingErrorUMP } = useSelector(
    (state) => state.authUser
  );
  const { loading: loadingStateEMP, error: loadingErrorEMP } = useSelector(
    (state) => state.authEmployer
  );

  useEffect(() => {
    rootSession.getEmployerChecking();
  }, []);

  const formvariable1 = {
    role: 1,
    email: "",
    password: "",
    remeberMe: false,
  };

  const formvariable2 = {
    roleE: 2,
    emailE: "",
    passwordE: "",
    remeberMeE: false,
  };

  const {
    handleChange: handleChangeCandidate,
    handleSubmit: handleSubmitCandidate,
    handleBlur: handleBlurCandidate,
    setFieldValue: setFieldValueCandidate,
    values: valuesCandidate,
    errors: errorsCandidate,
    touched: touchedCandidate,
  } = useFormik({
    initialValues: formvariable1,
    validationSchema: mount.SignInSchema,
    onSubmit: async (values, action) => {
      sessionStorage.clear();
      localStorage.clear();
      await rootSession.getCSRF();

      dispatch(
        loginUser(
          {
            email: values.email,
            password: values.password,
            remember_me: values.remeberMe != true ? 1 : 0,
            type: values.role,
          },
          navigate
        )
      );

      action.resetForm();
      // console.log(values);
      // setLoading(true);

      // try {
      //   await rootSession.getCSRF();
      //   const response = await axiosApi.post(domain.Login_URL, {
      //     email: values.email,
      //     password: values.password,
      //     remember_me: values.remeberMe != true ? 1 : 0,
      //     type: values.role,
      //   });
      //   console.log(response.data);
      //   const accessToken = response.data.accessToken;

      //   localStorage.setItem("token", response.data.accessToken);
      //   localStorage.setItem("redirection", response.data.type);

      //   if (accessToken) {
      //     await rootSession.getCSRF();

      //     const loginDetails = await axiosApi.post(domain.LoggedIn, {
      //       headers: {
      //         Authorization: `Bearer ${localStorage.getItem("token")}`,
      //       },
      //     });
      //     const userData = {
      //       id: loginDetails.data.data.id,
      //       email: loginDetails.data.data.email,
      //       type: loginDetails.type,
      //       profileImage: loginDetails.data.data.profile_img,
      //       name: loginDetails.data.data.name,
      //       firstName: loginDetails.data.data.first_name,
      //       lastName: loginDetails.data.data.last_name,
      //       status: loginDetails.data.data.status,
      //       type: 1, // localStorage.getItem("redirection"),
      //       token: `Bearer ${localStorage.getItem("token")}`,
      //     };
      //     console.log(loginDetails);
      //     console.log(localStorage.getItem("redirection"));

      //     sweetAlert("Success!", "You have logged in successfully.", 1); // Succes
      //     sessionStorage.setItem("user", JSON.stringify(userData));
      //     if (localStorage.getItem("redirection") == 1) {
      //       navigate("/");

      //       action.resetForm();
      //     } else {
      //       navigate("/login");
      //       sweetAlert(
      //         "Failed!",
      //         "Login failed. Please check your credentials.",
      //         2
      //       ); // Succes
      //     }
      //   } else {
      //     sweetAlert("Error!", "Token not found.", 2); // Error
      //   }
      // } catch (err) {
      //   // Check for validation errors and handle
      //   if (err.response && err.response.status === 422) {
      //     sweetAlert(
      //       "Error!",
      //       err.response.data.errors.email
      //         ? err.response.data.errors.email[0]
      //         : "Invalid credentials.",
      //       2
      //     ); // Error
      //   } else {
      //     sweetAlert("Error!", "Invalid credentials provided.", 2); // Error
      //   }
      // } finally {
      //   setLoading(false);
      // }
    },
  });

  const {
    handleChange: handleChangeEmployer,
    handleSubmit: handleSubmitEmployer,
    handleBlur: handleBlurEmployer,
    setFieldValue: setFieldValueEmployer,
    values: valuesEmployer,
    errors: errorsEmployer,
    touched: touchedEmployer,
  } = useFormik({
    initialValues: formvariable2,
    validationSchema: mount.SignInSchemaEmployer,
    onSubmit: async (values, action) => {
      console.log(values);
      sessionStorage.clear();
      localStorage.clear();
      await rootSession.getCSRF();

      dispatch(
        loginEmployer(
          {
            email: values.emailE,
            password: values.passwordE,
            remember_me: values.remeberMeE != true ? 1 : 0,
            type: values.roleE,
          },
          navigate
        )
      );

      // action.resetForm();
      // sessionStorage.clear();
      // localStorage.clear();

      // setLoading(true);

      // try {
      //   await rootSession.getCSRF();

      //   const response = await axiosApi.post(domain.EmployerLogin_URL, {
      //     email: values.emailE,
      //     password: values.passwordE,
      //     remember_me: values.remeberMeE != true ? 1 : 0,
      //     type: values.roleE,
      //   });
      //   console.log(response.data);
      //   const accessToken = response.data.accessToken;
      //   localStorage.setItem("token", response.data.accessToken);
      //   localStorage.setItem("redirection", response.data.type);
      //   localStorage.setItem("node", response.data.node);
      //   if (accessToken) {
      //     await rootSession.getCSRF();

      //     const loginDetails = await axiosApi.post(domain.LoggedIn, {
      //       headers: {
      //         Authorization: `Bearer ${localStorage.getItem("token")}`,
      //       },
      //     });
      //     const userDataChecked = {
      //       id: loginDetails.data.data.id,
      //       name: loginDetails.data.data.name || "N/A",
      //       encryptId: loginDetails.data.data.encrypt_id || "N/A",
      //       firstName: loginDetails.data.data.first_name || "N/A",
      //       lastName: loginDetails.data.data.last_name || "N/A",
      //       phoneNo: loginDetails.data.data.phone_no || "N/A",
      //       email: loginDetails.data.data.email || "N/A",
      //       countryCode: loginDetails.data.data.country_code || "N/A",
      //       homeLocation: loginDetails.data.data.home_location || "N/A",
      //       businessLocation: loginDetails.data.data.business_location || "N/A",
      //       profileImage: loginDetails.data.data.profile_image || "",
      //       businessLogo: loginDetails.data.data.business_logo || "",
      //       status: loginDetails.data.data.status || 0,
      //       type: 2, // Assuming the user type is static for now
      //       token: `Bearer ${localStorage.getItem("token")}` || null,
      //     };

      //     sessionStorage.setItem("employer", JSON.stringify(userDataChecked));

      //     if (localStorage.getItem("redirection") == 2) {
      //       navigate("/employer/dashboard");
      //       sweetAlert("Success!", "You have logged in successfully.", 1);

      //       action.resetForm();
      //       // EmployerDashboard(response.data);
      //     } else {
      //       sweetAlert(
      //         "Failed!",
      //         "Login failed. Please check your credentials.",
      //         2
      //       );
      //       navigate("/login");
      //     }
      //   } else {
      //     sweetAlert("Error!", "Token not found.", 2);
      //   }
      // } catch (err) {
      //   if (err.response && err.response.status === 422) {
      //     sweetAlert(
      //       "Error!",
      //       err.response.data.errors.email
      //         ? err.response.data.errors.email[0]
      //         : "Invalid credentials.",
      //       2
      //     );
      //   } else {
      //     sweetAlert("Error!", "Invalid credentials provided.", 2); // Error
      //   }
      // } finally {
      //   setLoading(false);
      // }
    },
  });

  return (
    <section className="account-section">
      <div className="tf-container">
        <div className="row">
          <Tabs
            className="wd-form-login tf-tab"
            selectedIndex={valuesCandidate.role === 1 ? 0 : 1}
            onSelect={(index) =>
              setFieldValueCandidate("role", index === 0 ? 1 : 2)
            }

            // selectedIndex={activeTab}
            // onSelect={handleTabChange}
          >
            <h4>Log In</h4>
            <TabList className="menu-tab">
              <Tab className="ct-tab">Candidate</Tab>
              <Tab className="ct-tab">Employer</Tab>
            </TabList>
            <div className="content-tab">
              <TabPanel className="inner animation-tab">
                <div className="wd-form-login">
                  {/* <ListContext.Provider value={{ name: "Jayant" }}> */}
                  <form onSubmit={handleSubmitCandidate}>
                    <div className="ip">
                      <label>
                        Email<span>*</span>
                      </label>
                      <input
                        type="text"
                        name="email"
                        value={valuesCandidate.email}
                        onChange={handleChangeCandidate}
                        onBlur={handleBlurCandidate}
                        placeholder="Email or Username"
                      />
                      {errorsCandidate.email && touchedCandidate.email ? (
                        <span className="error">{errorsCandidate.email}</span>
                      ) : null}
                    </div>

                    <div className="ip">
                      <label>
                        Password<span>*</span>
                      </label>
                      <div className="inputs-group auth-pass-inputgroup">
                        <input
                          type={showPass ? "text" : "password"}
                          className="input-form password-input"
                          autocomplete="current-password"
                          name="password"
                          value={valuesCandidate.password}
                          onChange={handleChangeCandidate}
                          onBlur={handleBlurCandidate}
                          placeholder="Password"
                        />
                        <Link
                          className={`password-addon ${
                            showPass ? "icon-eye" : "icon-eye-off"
                          }`}
                          onClick={() => setShowPass(!showPass)}
                        />
                      </div>
                      {errorsCandidate.password && touchedCandidate.password ? (
                        <span className="error">
                          {errorsCandidate.password}
                        </span>
                      ) : null}
                    </div>
                    <div className="group-ant-choice">
                      <div className="sub-ip">
                        <input
                          type="checkbox"
                          name="remeberMe"
                          checked={valuesCandidate.remeberMe}
                          onChange={handleChangeCandidate}
                          onBlur={handleBlurCandidate}
                          value={1}
                        />
                        Remember me
                        {errorsCandidate.remeberMe &&
                        touchedCandidate.remeberMe ? (
                          <span className="error">
                            {errorsCandidate.remeberMe}
                          </span>
                        ) : null}
                        {/* <input
                          type="checkbox"
                          checked={formData.remeberMe}
                          onChange={handleCheckboxChange}
                        />
                        Remember me */}
                      </div>
                      <Link to="#" className="forgot">
                        Forgot password?
                      </Link>
                    </div>
                    <button type="submit" disabled={loading}>
                      {loading ? "Logging in..." : "Login"}
                    </button>

                    <div className="sign-up p-3">
                      Not registered yet?{" "}
                      <Link to="/create-account">Sign Up</Link>
                    </div>
                    <p className="line-ip">
                      <span>or sign up with</span>
                    </p>

                    {/* Social media login options */}
                    {/* <Link to="#" className="btn-social">
                      Continue with Facebook
                    </Link> */}
                    <Link to="#" className="btn-social">
                      <img src={img} alt="Google" /> Continue with Google
                    </Link>
                    {/* <Link to="#" className="btn-social">
                      <img src={img2} alt="Twitter" /> Continue with Twitter
                    </Link> */}
                  </form>
                  {/* </ListContext.Provider> */}
                </div>
              </TabPanel>
              <TabPanel className="inner animation-tab">
                <form onSubmit={handleSubmitEmployer}>
                  <div className="ip">
                    <label>
                      Email<span>*</span>
                    </label>
                    <input
                      type="text"
                      name="emailE"
                      value={valuesEmployer.emailE}
                      onChange={handleChangeEmployer}
                      onBlur={handleBlurEmployer}
                      placeholder="Enter your email"
                    />
                    {errorsEmployer.emailE && touchedEmployer.emailE ? (
                      <span className="error">{errorsEmployer.emailE}</span>
                    ) : null}
                  </div>

                  <div className="ip">
                    <label>
                      Password<span>*</span>
                    </label>
                    <div className="inputs-group auth-pass-inputgroup">
                      <input
                        type={showPass2 ? "text" : "password"}
                        className="input-form password-input"
                        name="passwordE"
                        value={valuesEmployer.passwordE}
                        autocomplete="current-password"
                        onChange={handleChangeEmployer}
                        onBlur={handleBlurEmployer}
                        placeholder="Password"
                      />

                      <Link
                        className={`password-addon ${
                          showPass2 ? "icon-eye" : "icon-eye-off"
                        }`}
                        onClick={() => setShowPass2(!showPass2)}
                      />
                    </div>
                    {errorsEmployer.passwordE && touchedEmployer.passwordE ? (
                      <span className="error">{errorsEmployer.passwordE}</span>
                    ) : null}
                  </div>

                  <div className="group-ant-choice">
                    <div className="sub-ip">
                      <input
                        type="checkbox"
                        name="remeberMeE"
                        checked={valuesEmployer.remeberMeE}
                        onChange={handleChangeEmployer}
                        onBlur={handleBlurEmployer}
                        value={1}
                      />
                      Remember me
                      {errorsEmployer.remeberMeE &&
                      touchedEmployer.remeberMeE ? (
                        <span className="error">
                          {errorsEmployer.remeberMeE}
                        </span>
                      ) : null}
                    </div>
                    <Link to="#" className="forgot">
                      Forgot password?
                    </Link>
                  </div>
                  <button type="submit" disabled={loading}>
                    {loading ? "Logging in..." : "Login"}
                  </button>

                  <div className="sign-up p-3">
                    Not registered yet?{" "}
                    <Link to="/create-account">Sign Up</Link>
                  </div>
                  <p className="line-ip">
                    <span>or sign up with</span>
                  </p>

                  {/* Social media login options */}
                  {/* <Link to="#" className="btn-social">
                      Continue with Facebook
                    </Link> */}
                  <Link to="#" className="btn-social">
                    <img src={img} alt="Google" /> Continue with Google
                  </Link>
                  {/* <Link to="#" className="btn-social">
                      <img src={img2} alt="Twitter" /> Continue with Twitter
                    </Link> */}
                </form>
              </TabPanel>
            </div>
          </Tabs>
        </div>
      </div>
    </section>
  );
}

export default SignIn;
