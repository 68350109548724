import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import Work from "@mui/icons-material/Work";

const Menuitems = [
  {
    title: "Dashboard",
    icon: DashboardOutlinedIcon,
    href: "dashboard",
  },
  // ,
  // {
  //   title: "Testing",
  //   icon: Work,
  //   items: [
  //     {
  //       title: "Testing",
  //       href: "testing",
  //     },
  //   ],

  // }
  {
    title: "Jobs",
    icon: Work,
    items: [
      {
        title: "Post Vacancy",
        href: "post-vacancy",
      },
      {
        title: "Manage Job Post",
        href: "manage-job-post",
      },
    ],
  },
  {
    title: "Profile",
    icon: Work,
    items: [
      {
        title: "Profile Setting",
        href: "profile-page",
      },
    ],
  },
];

export default Menuitems;
