import React from "react";
import { Navigate } from "react-router-dom";
import AuthMiddleware from "../../middleware/AuthMiddleware"; // Import middleware
import CandidateDashboard from "../../pages/Home/HomePage";
import CandidateProfile from "../../pages/Profile/ProfilePage";
import CandidateCardPage from "../../components/card-style/cardComp";
import Login from "../../pages/Auth/Login";
import ManageCandidatePreview from "../../Employer/components/ManageJobPost/PreviewCandidate.jsx/ManageCandidatePreview";
import MainDashboard from "../../pages/Employer/MainDashboard";
import FullLayout from "../../Employer/layouts/FullLayout/FullLayout.js";
import NotFoundPage from "../../pages/PageNotFound.js";
import CandidateLayout from "../../pages/Layout/CandidateLayout.js";



import Testing from "../../pages/Employer/Testing.jsx";
import ManageJobCandidateList from "../../Employer/components/ManageJobPost/ManageCandidate/ManagejobCandidateList.jsx";
import PostJobVacancy from "../../Employer/views/post-vacancy/PostJobVacancy.jsx";
import PostJobPreview from "../../Employer/views/post-vacancy/PostJobPreview.jsx";
import ManageJobPost from "../../Employer/components/ManageJobPost/ManageJobPost.jsx";
import ManageJobPostEdit from "../../Employer/components/ManageJobPost/Edit/ManageJobPostEdit.jsx";
import ManageJobPostEditPreviewSubmit from "../../Employer/components/ManageJobPost/Edit/ManageJobPostEditPreviewSubmit.jsx";
import ProfilePage from "../../Employer/views/profile/ProfilePage.jsx";

const routesConfigCandidate = [
  {
    path: "/",
    element: <CandidateLayout />,
    children: [
      { path: "/", element: <CandidateDashboard />, isProtected: false },
      { path: "/login", element: <Login />, isProtected: false },
      {
        path: "/profile",
        element: (
          <AuthMiddleware allowedRoles={[1]}>
            <CandidateProfile />
          </AuthMiddleware>
        ),
        isProtected: true,
      },
      {
        path: "/card",
        element: (
          <AuthMiddleware allowedRoles={[1]}>
            <CandidateCardPage />
          </AuthMiddleware>
        ),
      },
      {
        path: "/preview",
        element: (
          <AuthMiddleware allowedRoles={[1]}>
            <ManageCandidatePreview />
          </AuthMiddleware>
        ),
      },
    ],
  },
  {
    path: "/employer",
    element: (
      <AuthMiddleware allowedRoles={[2]}>
        <FullLayout />
      </AuthMiddleware>
    ),
    children: [
      { path: "dashboard", element: <MainDashboard /> },
      { path: "testing", element: <Testing /> },
      {
        path: "post-vacancy",
        element: (
          <AuthMiddleware allowedRoles={[2]}>
            <PostJobVacancy />{" "}
          </AuthMiddleware>
        ),
      },
      {
        path: "post-review",
        element: (
          <AuthMiddleware allowedRoles={[2]}>
            <PostJobPreview />
          </AuthMiddleware>
        ),
      },
      {
        path: "manage-job-candidate-list",
        element: (
          <AuthMiddleware allowedRoles={[2]}>
            <ManageJobCandidateList />
          </AuthMiddleware>
        ),
        isProtected: true,
      },
      {
        path: "manage-job-post-edit-preview-submit",
        element: (
          <AuthMiddleware allowedRoles={[2]}>
            <ManageJobPostEditPreviewSubmit />
          </AuthMiddleware>
        ),
        isProtected: true,
      },
      {
        path: "manage-job-post-edit",
        element: (
          <AuthMiddleware allowedRoles={[2]}>
            <ManageJobPostEdit />
          </AuthMiddleware>
        ),
        isProtected: true,
      },
      {
        path: "manage-job-post",
        element: (
          <AuthMiddleware allowedRoles={[2]}>
            <ManageJobPost />
          </AuthMiddleware>
        ),
      },
      {
        path: "profile-page",
        element: (
          <AuthMiddleware allowedRoles={[2]}>
            <ProfilePage />
          </AuthMiddleware>
        ),
      },

      //<ApplyJobVacancy /> },
    ],
  },
  { path: "*", element: <NotFoundPage /> },
];

export default routesConfigCandidate;
