import { useQuery, useMutation } from "@tanstack/react-query";
import apiService from "../../../api/apiService";

// Fetch all employers * working mode
export const useEmployers = () => {
  return useQuery({
    queryKey: ["employerProfile"],
    queryFn: apiService.getEmployerProfileDetails,
  });
};
export const useEmployersUpdate = () => {
  return useMutation(apiService.getEmployerProfileDetailsUpdate);
};
// export const useEmployersUpdate = (prop) => {
//   return useQuery({
//     queryKey: ["employerProfieUpdate"],
//     queryFn: apiService.getEmployerProfileDetailsUpdate(prop),
//   });
// };

// // Fetch employer by ID
// export const useEmployer = (id) => {
//   return useQuery({
//     queryKey: ["employer", id],
//     queryFn: () => getEmployerById(id),
//     enabled: !!id, // Prevent running if id is null
//   });
// };

// // Mutation to create employer
// export const useCreateEmployer = () => {
//   return useMutation(createEmployer);
// };
