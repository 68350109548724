import React from "react";
import { getApp, getAppUrl } from "./utils/helpers";
import AppCandidate from "./layouts/AppCandidate";

const App = () => {
  
  return <AppCandidate />;
};

export default App;
