import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_DB_URL,
  headers: {
    "Content-Type": "application/json",
    // "Content-Type": "application/*",
    // "Content-Type": "multipart/form-data",

    Accept: "application/json",
  },
  withCredentials: true,
});
// axiosInstance.interceptors.request.use((config) => {
//   const token = localStorage.getItem("token");
//   if (token) {
//     config.headers.Authorization = `Bearer ${token}`;
//   }
//   return config;
// });

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
export default axiosInstance;
