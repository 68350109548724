import React from "react";
import { useRoutes } from "react-router-dom";
import AuthMiddleware from "../../middleware/AuthMiddleware"; // Middleware
import routesConfigCandidate from "./routesConfigCandidate"; // Import routes

const wrapWithMiddleware = (routes) =>
  routes.map((route) => ({
    path: route.path,
    element: route.isProtected ? (
      <AuthMiddleware>{route.element}</AuthMiddleware>
    ) : (
      route.element
    ),
    children: route.children ? wrapWithMiddleware(route.children) : undefined,
  }));

const AppRoutePath = () => {
  return useRoutes(wrapWithMiddleware(routesConfigCandidate));
};

export default AppRoutePath;
