export default function NotFoundPage() {
  return (
    <div className="flex h-screen w-full flex-col items-center justify-center bg-gray-100 px-4">
      <h1 className="text-9xl font-bold text-gray-800">404</h1>
      <h2 className="mt-4 text-2xl font-semibold text-gray-700">
        Page Not Found
      </h2>
      <p className="mt-2 text-gray-500">
        The page you’re looking for doesn’t exist or has been moved.
      </p>
      <a
        href="/"
        className="mt-6 rounded-lg bg-blue-600 px-6 py-3 text-white shadow-md transition hover:bg-blue-700"
      >
        Go Back Home
      </a>
    </div>
  );
}
