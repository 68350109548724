import { Box, Typography, Autocomplete, TextField, Chip } from "@mui/material";
import React, { useState, useRef } from "react";
import { SelectPicker } from "rsuite";
// import InputStyles from '../rsuite/InputStyles';
import {InputStyles} from '../rsuite/InputStyles';

export default function CompSelectSearch({ label, value, onChange, list }) {
  // const styles = { width: 300, display: "block", marginBottom: 10 };

  return (
    // <Box sx={{ width: '100%', marginBottom: 2 }}>
    <SelectPicker
      size="xl"
      data={list}
      value={value}
      onChange={onChange}
      searchable={true}
      placeholder="Select search"
      style={InputStyles().input}
    />
    // </Box>
  );
}
