import {
  LOGIN_REQUEST_EMP,
  LOGIN_SUCCESS_EMP,
  LOGIN_FAILURE_EMP,
  LOGOUT_EMP,
} from "../../actions/authActionEmployer";
const initialState = {
  employer: JSON.parse(sessionStorage.getItem("employer")) || null,
  loading: false,
  error: null,
};

const authReducerEmployer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST_EMP:
      return { ...state, loading: true, error: null };
    case LOGIN_SUCCESS_EMP:
      return { ...state, employer: action.payload, loading: false };
    case LOGIN_FAILURE_EMP:
      return { ...state, error: action.payload, loading: false };
    case LOGOUT_EMP:
      return { ...state, employer: null };
    default:
      return state;
  }
};

export default authReducerEmployer;
