import React, {
  Suspense,
  useState,
  useEffect,
  useReducer,
  useRef,
  useCallback,
  useMemo,
  useContext,
} from "react";
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
// import Gallery from "../popup/Gallery";
// import Video from "../popup/Video";
import Session from "../../api/session";
// Profile Component
import ProfileModel from "../../components/candidates/CandidateProfile";

import CloseIcon from "@mui/icons-material/Close";
import Stack from "@mui/material/Stack";
// import SubTemplate from "../jobs/SubTemplate";
import SearchIcon from "@rsuite/icons/Search";
import {
  Autocomplete,
  Modal,
  Grid,
  Pagination,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Typography,
  TextField,
  Button,
  Icon,
} from "@mui/material";

// steps formreact
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { styled } from "@mui/material/styles";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import CallIcon from "@mui/icons-material/Call";
import MailIcon from "@mui/icons-material/Mail";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import cardStyles from "../../assets/css/card-style/card.css";
// import { inputStyles } from "../styles/style";
import CardComp from "../../components/card-style/cardComp";
import RoleComp from "../../components/Role/RoleComp";
import ProfilePage from "../../pages/Profile/ProfilePage";
import axios from "../../api/axios";
import img from "../../assets/images/review/google.png";
import img2 from "../../assets/images/review/tweet.png";
import domain from "../../api/constent";
import { CelebrateAlert, sweetAlert } from "../../utils/sweetAlert";
import ArowBackIcon from "@rsuite/icons/ArowBack";
import {
  Grid as RSGrid,
  Panel as RSPanel,
  Row as RSRow,
  CheckPicker as RSCheckPicker,
  SelectPicker as RSSelectPicker,
  Col as RSCol,
  Dropdown as RSDropdown,
  InputPicker as RSInputPicker,
  Loader as RSLoader,
  Pagination as RSPaginator,
  Placeholder as RSPlaceholder,
  AutoComplete as RSAutoComplete,
  VStack,
  CheckTreePicker as RSCheckTreePicker,
  TreePicker as RSTreePicker,
  List as RSList,
  TagGroup as RSTagGroup,
  Tag as RSTag,
  Button as RSButton,
  Divider as RSDivider,
  Placeholder,
  Schema as RSSchema,
  Form as RSForm,
  Uploader as RSUploader,
  FlexboxGrid as RSFlexboxGrid,
  Message as RSMessage,
  IconButton as RSIconButton,
} from "rsuite";

import CircleProgess from "../../components/progressBar";
import STYLES from "../../assets/css/styles.css";

import ReviewSubmit from "../../components/reviewSubmit/ReviewSubmit";
import apiService from "../../api/apiService";
import { FcOrganization, FcConferenceCall, FcHome } from "react-icons/fc";
import Spinner from "../../Employer/views/Spinner/Spinner";

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import PhotoAlbum from "react-photo-album";

import QuestionList from "../../components/jobs/appliedJob/QuestionList";

import LoaderMount from "../../components/preloader";
import { getPriceIcons } from "../../utils/helpers";
import { useResizeObserverSafe } from "../../hooks/useResizeObserverSafe";
import SearchAutoComplete from "../../components/custom/SearchAutoComplete";
// import apiService from '../../api/apiService';
import { debounce } from "../../utils/debounce";
import LottieControl from "../../utils/LottieControl";
import RadioButton from "../../components/rsuitComponents/RadioButton";
import { JSONTree } from "react-json-tree";
// import { ListContext } from "../../components/signin";
import { CloseOutline } from "@rsuite/icons";
const steps = [
  "Choose a Document",
  "Answer Employee Queries",
  "Update Profile",
  "Review and Submit",
];

// file upload
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxWidth: "600px",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  width: {
    xs: "90%",
    sm: "80%",
    md: "60%",
    lg: "50%",
    xl: "40%",
  },
  maxHeight: "70vh",
  overflowY: "auto",
};
const FixedLoader = () => (
  <RSLoader
    content="Loading..."
    style={{
      display: "flex",
      justifyContent: "center",
      position: "absolute",
      bottom: "0",
      background: "#fff",
      width: "100%",
      padding: "4px 0",
    }}
  />
);

const JSONView = ({ formValue, formError }) => (
  <div style={{ marginBottom: 10 }}>
    <RSPanel className="json-tree-wrapper" header={<p>formValue</p>}>
      <JSONTree data={formValue} />
    </RSPanel>

    <RSPanel className="json-tree-wrapper" header={<p>formError</p>}>
      <JSONTree data={formError} />
    </RSPanel>
  </div>
);
const RSField = React.forwardRef((props, ref) => {
  const { name, message, label, accepter, error, ...rest } = props;
  return (
    <RSForm.Group
      controlId={`${name}-10`}
      ref={ref}
      className={error ? "has-error" : ""}
    >
      <RSForm.ControlLabel>{label} </RSForm.ControlLabel>
      <RSForm.Control
        name={name}
        accepter={accepter}
        errorMessage={error}
        {...rest}
      />
      <RSForm.HelpText>{message}</RSForm.HelpText>
    </RSForm.Group>
  );
});
function HomePage() {
  const formRef = useRef();

  // ListContextRefrenece
  // const getListContext = useContext(ListContext);
  // jobPostingList
  const [jobPostingList, setJobPostingList] = useState([]);
  const [tempSelectApply, setTempSelectApply] = useState({});

  //features
  const [clickPass, setClickPass] = useState(0);
  const [listFind1, setListFind1] = useState([]);
  const [listFind2, setListFind2] = useState([]);

  // pagination
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  //total Jobs
  const [totalPages, setTotalPages] = useState(1);
  // asc,desc
  const [sortValue, setSortValue] = useState("asc");
  const [WherePlaceSearchFilter, setWherePlaceSearchFilter] = useState("");

  const [employmentTypeFilter, setEmploymentTypeFilter] = useState([]);
  const [salaryTypeFilter, setSalaryTypeFilter] = useState("");
  const [salaryRangeMinFilter, setSalaryRangeMinFilter] = useState(0);
  const [salaryRangeMaxFilter, setSalaryRangeMaxFilter] = useState(0);
  const [listingTimeList, setListingTimeList] = useState("");

  // filters
  const [wherePlaceSearchingList, setWherePlaceSearchingList] = useState([]); // Store selected items
  const [employeeTypes, setEmploymentList] = useState([]);
  const [salaryTypeList, setSalaryTypeList] = useState([]);
  const [salaryRangeList, setSalaryRangeList] = useState([]);
  const [listingAnyTimeList, setListingAnyTimeList] = useState([]);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Validation modified
  const [formError, setFormError] = useState({});

  // apply now
  // const { data } = props;
  const [open, setOpen] = useState(false);
  const [modalContent, setModalContent] = useState({});
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const initialFormData = {
    compId: 0,
    checkResume: 0,
    uploadResume: false,
    selectOptionResume: false,
    notSelectResume: false,
    checkCoverLetter: 0,
    uploadCoverLetter: false,
    selectOptionCoverLetter: false,
    notSelectCoverLetter: false,
    selectedResume: null,
    selectedCoverLetter: null,
  };
  const initialFormData2 = {
    compId: 0,
    checkTypeResume: 0,
    uploadedResume: null,
    selectedResume: "",
    prevResumeList: [],
    checkTypeCoverL: 0,
    uploadedCoverL: null,
    selectedCoverL: "",
    prevCoverList: [],
  };
  const [formData, setFormData] = useState(initialFormData2);

  const [selectOptionResume, setSelectOptionResume] = useState([]);
  const [selectOptionCoverLetter, setSelectOptionCoverLetter] = useState([]);
  const [message, setMessage] = useState("");
  const [getQuestions, setQuestions] = useState([]);
  const [getAnswers, setAnswers] = useState([]); // Sto
  // Banner Searching section
  const [enterKeywordOptionsList, setEnterKeywordOptionsList] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState(""); //keyword searching
  const [selectKeyword, setSelectKeyword] = useState([]); //checked in classifications
  const [selectedItems, setSelectedItems] = useState([]); // Store selected items

  const fetchEnterKeywordList = async () => {
    try {
      const response = await axios.get(domain.Job_Keyword_Search_List);
      setEnterKeywordOptionsList(response.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  // const fetchJobPostingData = (e) => {
  //   setSearchKeyword(e);
  //   fetchInfo(currentPage);
  // };

  const model = RSSchema.Model({
    checkTypeResume: RSSchema.Types.NumberType()
      .isRequired("Please select a any option.")
      .addRule((value) => value !== 0, "Invalid option selected."),
    uploadedResume:
      formData.checkTypeResume === 1
        ? RSSchema.Types.MixedType().isRequired(
            "Uploading a resume is required."
          )
        : RSSchema.Types.MixedType(),
    selectedResume:
      formData.checkTypeResume === 2
        ? RSSchema.Types.ObjectType().isRequired("Select a Resume option.")
        : RSSchema.Types.ObjectType(),
    checkTypeCoverL: RSSchema.Types.NumberType()
      .isRequired("Please select a Cover Letter option.")
      .addRule((value) => value !== 0, "Invalid option selected."),
    uploadedCoverL:
      formData.checkTypeCoverL === 1
        ? RSSchema.Types.MixedType().isRequired(
            "Uploading a Cover Letter is required."
          ) //.isRequired("This field is required Cover letter.")
        : RSSchema.Types.MixedType(),
    selectedCoverL:
      formData.checkTypeCoverL === 2
        ? RSSchema.Types.ObjectType().isRequired(
            "Select a Cover letter option."
          )
        : RSSchema.Types.ObjectType(), //.isRequired("Select a Cover letter option.")
  });
  const fetchAnyClassifications = async () => {
    // setLoading(true);
    try {
      const response = await axios.get(domain.Job_Select_Search_List);
      setSelectKeyword(response.data.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
    //  finally {
    //   // setLoading(false);
    // }
  };

  const fetchWherePlaceSearchingM = async () => {
    // setLoading(true);
    try {
      const response = await axios.get(domain.Job_Select_Where_Place_List);
      // console.log(response);

      setWherePlaceSearchingList(response.data.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
    // finally {
    //   // setLoading(false);
    // }
  };

  const fetchFilteringData = async () => {
    // setLoading(true);
    try {
      // Make all API calls concurrently
      const [
        employmentData,
        salaryTypeData,
        salaryRangeData,
        listingAnyTimeList,
      ] = await Promise.all([
        apiService.OpenEmploymentType(),
        apiService.OpenSalaryTypeList(),
        apiService.OpenSalaryRangeList(),
        apiService.OpenListingAnyTimeList(),
      ]);
      // console.log(`Log ${employmentData}`);

      // Update state with fetched data
      setEmploymentList(employmentData);
      setSalaryTypeList(salaryTypeData);
      setSalaryRangeList(salaryRangeData);
      setListingAnyTimeList(listingAnyTimeList);
    } catch (err) {
      console.error("Error fetching data:", err);
      setError(err.message);
    } finally {
      // setLoading(false);
    }
  };
  const handleSearch = useCallback(
    debounce((query) => {
      if (query != "") {
        //  && query.trim()
        console.log("Search keyword:", query);
        setSearchKeyword(query);
        fetchInfo(currentPage);
      } else {
        console.log("Input cleared or null", searchKeyword);
        setSearchKeyword("");
        fetchInfo(1);
      }
    }, 300), // Debounce with 300ms delay
    []
  );
  useEffect(() => {
    getPreviousUploadedResume();
    getPreviousUploadedCoverLetter();
    fetchEnterKeywordList();
    fetchAnyClassifications();
    fetchWherePlaceSearchingM();
    fetchFilteringData();
    fetchInfo(currentPage);
    // console.log("Checking value ", searchKeyword);
  }, [
    currentPage,
    listFind1,
    listFind2,
    sortValue,
    // searchKeyword,
    WherePlaceSearchFilter,
    employmentTypeFilter,
    salaryTypeFilter,
    salaryRangeMinFilter,
    salaryRangeMaxFilter,
    listingTimeList,
  ]); // Run once on mount

  //Job Posting List
  const fetchInfo = async (page) => {
    setLoading(true);
    console.log(searchKeyword);

    const sessionData = Session.getDataSessionStore();
    const downPoint = {
      page: page,
      per_page: itemsPerPage,
      order_direction: sortValue,
      candidate_info: sessionData && sessionData.id ? sessionData.id : 0,
      keyword: searchKeyword,
      depart_id: listFind1,
      role_id: listFind2,
      place_search: WherePlaceSearchFilter,
      employment_type: JSON.stringify(employmentTypeFilter),
      salary_type: salaryTypeFilter,
      salary_range_min: parseInt(salaryRangeMinFilter, 10) ?? 0,
      salary_range_max: parseInt(salaryRangeMaxFilter, 10) ?? 0,
      listing_time: listingTimeList,
    };

    const response = await apiService.getJobPostingList(downPoint);

    const { data } = response;
    // console.log(data);

    setCurrentPage(data.current_page);
    setLastPage(data.last_page);
    setItemsPerPage(data.per_page);
    setTotalPages(data.total);
    setJobPostingList(data.data);

    setLoading(false);

    if (!response) return;
  };
  // const processedKeywordOptions = useMemo(
  //   () => enterKeywordOptionsList,
  //   [enterKeywordOptionsList]
  // );

  // const debouncedSearch = useCallback(
  //   debounce((searchTerm) => {
  //     setSearchKeyword(searchTerm);
  //     fetchInfo(currentPage); // Reset to first page when searching
  //   }, 300),
  //   []
  // ); // Create a handler for search input changes
  // const handleSearch = (value) => {
  //   console.log(value);

  //   if (value === "") {
  //     console.log("Search cleared");
  //     setSearchKeyword(""); // Assign null to indicate no keyword
  //     fetchInfo(1); // Fetch initial data
  //   } else {
  //     console.log("Search value:", value);
  //     setSearchKeyword(value); // Update the search keyword
  //     fetchInfo(currentPage); // Fetch data for the current page
  //   }
  // };
  // const handleSearchChange = deb'ounce((value) => {
  //   setSearchKeyword(value);
  //   fetchInfo(currentPage); // Replacewith your data-fetching logic
  // }, 300);

  // const debouncedSetSearchKeyword = debounce(setSearchKeyword, 300);
  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchInfo(page);
  };
  const getPreviousUploadedResume = () => {
    setSelectOptionResume(["Resume 1", "Resume 2", "Resume 3"]);
  };
  const getPreviousUploadedCoverLetter = () => {
    setSelectOptionCoverLetter([
      "Cover Letter 1",
      "Cover Letter 2",
      "Cover Letter 3",
    ]);
  };

  // const handleRadioChange = (event) => {
  //   const value = parseInt(event.target.value);
  //   if (value >= 1 && value <= 3) {
  //     setFormData((prev) => ({
  //       ...prev,
  //       checkResume: value,
  //       uploadResume: value === 1,
  //       selectOptionResume: value === 2,
  //       notSelectResume: value === 3,
  //     }));
  //   } else if (value >= 4 && value <= 6) {
  //     setFormData((prev) => ({
  //       ...prev,
  //       checkCoverLetter: value,
  //       uploadCoverLetter: value === 4,
  //       selectOptionCoverLetter: value === 5,
  //       notSelectCoverLetter: value === 6,
  //     }));
  //   }
  // };

  const handleFileChangeResume = (e) => {
    const file = e.target.files[0];
    setFormData((prev) => ({ ...prev, selectedResume: file }));
    console.log("Selected Resume:", file);
  };

  const handleSelectResumeChange = (selectedValue) => {
    setFormData((prev) => ({ ...prev, selectedResume: selectedValue }));
  };

  // Cover letter
  // const handleFileChangeCoverLetter = (e) => {
  //   const file = e.target.files[0];
  //   setFormData((prev) => ({ ...prev, selectedCoverLetter: file }));
  //   console.log("Selected Cover Letter:", file);
  // };

  // const handleSelectCoverLetterChange = (selectedValue) => {
  //   setFormData((prev) => ({ ...prev, selectedCoverLetter: selectedValue }));
  // };

  function handleRedirect() {
    if (Session.getChecking()) {
      handleOpen();
    } else {
      window.location.href = "/login";
    }
  }

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setActiveStep(0);
  };

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleValidation = () => {
    const errors = {};
    let isValid = true;

    getQuestions.forEach((question) => {
      if (question.mandatory === 1) {
        const answer = getAnswers[question.id];
        if (!answer || (Array.isArray(answer) && answer.length === 0)) {
          errors[question.id] = true;
          isValid = false;
        }
      }
    });

    setFormError(errors);
    return isValid;
  };

  const callingValidate = (StepsMount) => {
    console.log(formRef);

    console.log(formData, "Form Value", StepsMount);
    console.log("Conti proces");
  };
  const handleNext = () => {
    let newSkipped = skipped;
    if (activeStep === steps.length - 1) {
      handleUpload(); // Call the submit function on the last step
    }
    if (!model.check(formData, { errors: formError })) {
      console.log("Validation failed:", formError);
      return;
    }
    if (!formRef.current.check() && activeStep == 0) {
      console.log(formRef);
      console.error("Form Error");
      return;
    }
    if (!handleValidation() && activeStep == 1) {
      console.log("Form has errors:", formError);
      return;
    } else {
      console.log("Form submitted successfully:", getAnswers);
    }
    callingValidate(activeStep);
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    callingValidate(activeStep);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  // step2
  const handleUpload = async () => {
    const user = new FormData();
    user.append("candidate_id", Session.getDataSessionStore()?.id ?? 0);
    user.append("job_post_id", tempSelectApply.id ?? 0);
    user.append(
      "resume",
      JSON.stringify(formData.uploadedResume ?? formData.selectedResume)
    );
    user.append(
      "cover_letter",
      JSON.stringify(formData.uploadedCoverL ?? formData.selectedCoverL)
    );
    user.append("question_ans", JSON.stringify(getAnswers));

    console.log(`Check Load: ${JSON.stringify(user)}`);

    try {
      const res = await axios.post(domain.Apply_Job, user);

      if (res.status === 201 || res.status === 202) {
        handleClose();
        sweetAlert("success", res.data.message, 1);
        setFormData(initialFormData2);
        setAnswers([]);
      } else {
        sweetAlert("error", res.data.message, 2);
      }
    } catch (error) {
      console.error("Upload failed:", error);
      sweetAlert(
        "warning",
        error.response?.data?.message || "Something went wrong",
        2
      );
    }
  };

  const resumeUpload = async (file) => {
    const formDataNext = new FormData();
    const userId = Session.getDataSessionStore().id;

    formDataNext.append("user_id", userId);
    formDataNext.append("resume", file.blobFile); // formData.uploadedResume.forEach((file) =>
    console.log(formDataNext);

    try {
      const response = await axios.post(
        `${domain.Candidate_Resume_Upload}`,
        formDataNext,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response?.data) {
        const uploadedPaths = response.data["paths"]; // Assuming paths is an array
        console.log("Uploaded paths:", uploadedPaths);

        if (uploadedPaths.length > 0) {
          // Get the latest uploaded resume's path
          const latestResumePath = uploadedPaths[uploadedPaths.length - 1].path;
          const latestResumeTitle =
            uploadedPaths[uploadedPaths.length - 1].original_name;

          console.log("Latest Resume Path:", latestResumePath);
          console.log("Latest Resume Title:", latestResumeTitle);

          // Update formData with the latest resume path and title
          setFormData((prev) => ({
            ...prev,
            uploadedResume: {
              title: latestResumeTitle,
              path: latestResumePath,
            },
          }));
        } else {
          console.log("No uploaded resume found in the response.");
        }
      } else {
        console.error("Error: 'paths' not found in the response data.");
      }
    } catch (error) {
      console.error("Error uploading resumes", error);
    }
  };

  const resumeDelete = async (file) => {
    const userId = Session.getDataSessionStore().id;

    // Parameters for deletion
    const requestData = {
      user_id: userId,
      path_file: file,
    };

    console.log("Request Data:", requestData);

    try {
      const response = await axios.delete(`${domain.Candidate_Resume_Delete}`, {
        data: requestData, // Pass parameters in the `data` property
        headers: {
          "Content-Type": "application/json", // Use JSON content type for simplicity
        },
      });

      if (response) {
        console.log("Deleted successfully:", response.data);

        sweetAlert("success", response.message, 1);
      } else {
        console.error("Error: No response data found.");
        sweetAlert("warning", response.message, 2);
      }
    } catch (error) {
      console.error("Error deleting resume:", error.response || error.message);
    }
    resumeGetList();
    setFormData((prev) => ({
      ...prev,
      selectedResume: null, // Clear selectedResume
    }));
  };

  const resumeGetList = async () => {
    try {
      const userId = Session.getDataSessionStore().id;

      const fetchedResumes = await axios.get(
        `${domain.Candidate_Resume_List + userId}`
      );

      console.log(fetchedResumes);
      // alert(response);
      const formattedResumes = fetchedResumes.data.data.map((resume) => ({
        title:
          resume.upload_date + " " + resume.original_name || "Unnamed Resume",
        path: resume.path,
        // uploadDate: resume.upload_date,
      }));
      setFormData((prev) => ({
        ...prev,
        prevResumeList: formattedResumes,
      }));

      // formData.prevResumeList(formattedResumes);

      console.log(formattedResumes);
      // prevResumeList
      // setUploadedResumes(response.data.data.resume_upload);
    } catch (error) {
      console.error("Error uploading resumes", error);
    }
  };

  const coverLetterUpload = async (file) => {
    const formDataNext = new FormData();
    const userId = Session.getDataSessionStore().id;

    formDataNext.append("user_id", userId);
    formDataNext.append("cover_letter", file.blobFile);
    // formData.uploadedResume.forEach((file) =>
    console.log(formDataNext);

    try {
      const response = await axios.post(
        `${domain.Candidate_CoverL_Upload}`,
        formDataNext,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("response", response);
      if (response?.data) {
        const uploadedPaths = response.data["paths"]; // Assuming paths is an array
        console.log("Uploaded paths:", uploadedPaths);

        if (uploadedPaths.length > 0) {
          // Get the latest uploaded resume's path
          const latestResumePath = uploadedPaths[uploadedPaths.length - 1].path;
          const latestResumeTitle =
            uploadedPaths[uploadedPaths.length - 1].original_name;

          console.log("Latest Resume Path:", latestResumePath);
          console.log("Latest Resume Title:", latestResumeTitle);

          // Update formData with the latest resume path and title
          setFormData((prev) => ({
            ...prev,
            uploadedCoverL: {
              title: latestResumeTitle,
              path: latestResumePath,
            },
          }));
        } else {
          console.log("No uploaded resume found in the response.");
        }
      } else {
        console.error("Error: 'paths' not found in the response data.");
      }
    } catch (error) {
      console.error("Error uploading resumes", error);
    }
  };
  const coverletterDelete = async (file) => {
    const userId = Session.getDataSessionStore().id;

    // Parameters for deletion
    const requestData = {
      user_id: userId,
      path_file: file,
    };

    console.log("Request Data:", requestData);

    try {
      const response = await axios.delete(`${domain.Candidate_CoverL_Delete}`, {
        data: requestData, // Pass parameters in the `data` property
        headers: {
          "Content-Type": "application/json", // Use JSON content type for simplicity
        },
      });

      if (response) {
        console.log("Deleted successfully:", response.data);

        sweetAlert("success", response.message, 1);
      } else {
        console.error("Error: No response data found.");
        sweetAlert("warning", response.message, 2);
      }
    } catch (error) {
      console.error("Error deleting resume:", error.response || error.message);
    }
    coverLetterGetList();
    setFormData((prev) => ({
      ...prev,
      selectedCoverL: null, // Clear selectedResume
    }));
  };

  const coverLetterGetList = async () => {
    try {
      const userId = Session.getDataSessionStore().id;

      const fetchedResumes = await axios.get(
        `${domain.Candidate_CoverL_List + userId}`
      );

      console.log(fetchedResumes);
      // alert(response);
      const formattedResumes = fetchedResumes.data.data.map((cl) => ({
        title: cl.upload_date + " " + cl.original_name || "Unnamed cl",
        path: cl.path,
      }));
      setFormData((prev) => ({
        ...prev,
        prevCoverList: formattedResumes,
      }));

      // formData.prevResumeList(formattedResumes);

      console.log(formattedResumes);
      // prevResumeList
      // setUploadedResumes(response.data.data.resume_upload);
    } catch (error) {
      console.error("Error uploading resumes", error);
    }
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <div class="pt-3">
            <div className="row ">
              <CardComp />
              <div class="row p-3">
                <Box>
                  <br />
                  <FormControl>
                    {formError.checkTypeResume && (
                      <div style={{ color: "red" }}>
                        {formError.checkTypeResume}
                      </div>
                    )}
                    <RadioButton
                      name="radioOption"
                      list={[{ value: 1, label: "Upload Resume" }]}
                      label="Upload Options :"
                      value={formData.checkTypeResume}
                      onChange={(value) => {
                        setFormData({ ...formData, checkTypeResume: value });
                        if (value == 2) {
                          resumeGetList();
                          console.log("SDF");
                        }
                      }}
                    />
                    {formData.checkTypeResume == 1 ? (
                      <RSForm.Group controlId="selectedResume">
                        {/* <h4>
                          <RSForm.ControlLabel>
                            Upload Resume
                          </RSForm.ControlLabel>
                        </h4> */}
                        <RSUploader
                          key={1}
                          fileListVisible={false}
                          appearance="primary"
                          Placeholder="Please Upload Resume"
                          onChange={(fileList) => {
                            // const file = fileList?.[0]?.blobFile || null;
                            // setFormData((prev) => ({
                            //   ...prev,
                            //   uploadedResume: file,
                            // }));
                            const file = fileList?.[0]?.blobFile || null;

                            // Validate the file using the schema
                            const checkResult = model.check({
                              uploadedResume: file,
                            });
                            if (checkResult.uploadedResume.hasError) {
                              setFormError(
                                checkResult.uploadedResume.errorMessage
                              );
                              return; // Do not update the state if validation fails
                            }

                            setFormError(""); // Clear any previous error
                          }}
                          onUpload={(e) => {
                            resumeUpload(e);
                            console.log("Uploaded ", e);
                          }}
                          accept=".pdf,.doc,.docx" // Limit file types
                        >
                          <Button size="lg">Upload Resume</Button>
                        </RSUploader>
                        {formData.uploadedResume && (
                          <div>
                            <p>
                              {formData.uploadedResume["path"] ? (
                                <>
                                  Uploaded Resume File:{" "}
                                  <Button
                                    key={22}
                                    appearance="link"
                                    onClick={() => {
                                      // Construct the full URL using the environment variable
                                      const fileURL = `${process.env.REACT_APP_DB_URL_Store}${formData.uploadedResume["path"]}`;

                                      // console.log("Opening file URL:", fileURL);

                                      // Open the URL directly in a new tab
                                      window.open(fileURL, "_blank");
                                    }}
                                  >
                                    {formData.uploadedResume["title"]}
                                  </Button>
                                </>
                              ) : null}

                              <RSIconButton
                                icon={<CloseIcon />}
                                size="xs"
                                circle
                                appearance="subtle"
                                style={{
                                  position: "absolute",
                                  top: "5px",
                                  right: "5px",
                                  color: "white",
                                  backgroundColor: "rgba(229, 0, 0, 0.8)",
                                }}
                                onClick={() =>
                                  setFormData((prev) => ({
                                    ...prev,
                                    uploadedResume: null,
                                  }))
                                }
                              />
                            </p>
                          </div>
                        )}
                        {formError.uploadedResume && (
                          <div style={{ color: "red" }}>
                            {formError.uploadedResume}
                          </div>
                        )}
                      </RSForm.Group>
                    ) : null}
                    <>
                      <RadioButton
                        name="radioOption"
                        list={[{ value: 2, label: "Select Resume" }]}
                        // label="Upload Options :"
                        value={formData.checkTypeResume}
                        onChange={(value) => {
                          setFormData({ ...formData, checkTypeResume: value });
                          if (value == 2) {
                            resumeGetList();
                            console.log("SDF");
                          }
                        }}
                      />
                      {formData.checkTypeResume == 2 ? (
                        <FormControl fullWidth>
                          <Autocomplete
                            value={formData.selectedResume?.title || ""} // Show title or an empty string if null
                            name="selectedResume"
                            onChange={(event, newValue) => {
                              // Find the selected resume object based on the title
                              const selectedResData =
                                formData.prevResumeList.find(
                                  (resume) => resume.title === newValue
                                );

                              if (selectedResData) {
                                setFormData((prev) => ({
                                  ...prev,
                                  selectedResume: selectedResData, // Store the full object
                                }));
                              } else {
                                setFormData((prev) => ({
                                  ...prev,
                                  selectedResume: null, // Clear selection if no match
                                }));
                              }
                            }}
                            sx={{ width: 300 }}
                            options={formData.prevResumeList.map(
                              (option) => option.title
                            )} // Options are titles
                            getOptionLabel={(option) => option || ""} // Ensure no [object Object] errors
                            isOptionEqualToValue={(option, value) =>
                              option === value
                            } // Proper comparison
                            renderInput={(params) => (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <TextField
                                  {...params}
                                  label="Select Resume"
                                  sx={{ flex: 1 }}
                                />
                                {formData.selectedResume && (
                                  <button
                                    key={
                                      formData.selectedResume.id ||
                                      formData.selectedResume.path
                                    }
                                    onClick={() => {
                                      resumeDelete(
                                        formData.selectedResume["path"]
                                      );
                                    }}
                                    style={{
                                      marginLeft: "8px",
                                      background: "transparent",
                                      border: "none",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: "20px",
                                        color: "red",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      ✖
                                    </span>
                                  </button>
                                )}
                              </div>
                            )}
                          />

                          {formError.selectedResume && (
                            <div style={{ color: "red" }}>
                              {formError.selectedResume}
                            </div>
                          )}
                        </FormControl>
                      ) : null}
                    </>
                  </FormControl>
                </Box>
              </div>
              {/* Cover Letter */}
              <div class="row p-3">
                <Box>
                  <br />
                  <FormControl>
                    {formError.checkTypeCoverL && (
                      <div style={{ color: "red" }}>
                        {formError.checkTypeCoverL}
                      </div>
                    )}
                    <RadioButton
                      name="radioOption"
                      list={[{ value: 1, label: "Upload Cover Letter" }]}
                      label="Upload Options :"
                      value={formData.checkTypeCoverL}
                      onChange={(value) => {
                        setFormData({ ...formData, checkTypeCoverL: value });
                      }}
                    />
                    {formData.checkTypeCoverL == 1 ? (
                      <RSForm.Group controlId="uploadedCoverL">
                        {/* <h4>
                        <RSForm.ControlLabel>
                          Upload Conver Letter
                        </RSForm.ControlLabel>
                      </h4> */}
                        <RSUploader
                          key={2}
                          fileListVisible={false}
                          appearance="primary"
                          Placeholder="Please Upload Cover Letter"
                          onChange={(fileList) => {
                            //   const file = fileList?.[0]?.blobFile || null;
                            //   setFormData((prev) => ({
                            //     ...prev,
                            //     uploadedCoverL: file,
                            //   }));
                            // }}

                            const file = fileList?.[0]?.blobFile || null;

                            // Validate the file using the schema
                            const checkResult = model.check({
                              uploadedCoverL: file,
                            });
                            if (checkResult.uploadedCoverL.hasError) {
                              setFormError(
                                checkResult.uploadedCoverL.errorMessage
                              );
                              return; // Do not update the state if validation fails
                            }

                            setFormError(""); // Clear any previous error
                          }}
                          onUpload={(e) => {
                            coverLetterUpload(e);
                            console.log("SDF2");
                          }}
                          accept=".pdf,.doc,.docx" // Limit file types
                        >
                          <Button size="lg">Upload Cover Letter</Button>
                        </RSUploader>
                        {formData.uploadedCoverL && (
                          <div>
                            <p>
                              {formData.uploadedCoverL["path"] ? (
                                <>
                                  Uploaded Cover Letter File:{" "}
                                  <Button
                                    appearance="link"
                                    onClick={() => {
                                      // Construct the full URL using the environment variable
                                      const fileURL = `${process.env.REACT_APP_DB_URL_Store}${formData.uploadedCoverL["path"]}`;

                                      // console.log("Opening file URL:", fileURL);

                                      // Open the URL directly in a new tab
                                      window.open(fileURL, "_blank");
                                    }}
                                  >
                                    {formData.uploadedCoverL["title"]}
                                  </Button>
                                </>
                              ) : null}
                              <RSIconButton
                                icon={<CloseIcon />}
                                size="xs"
                                circle
                                appearance="subtle"
                                style={{
                                  position: "absolute",
                                  top: "5px",
                                  right: "5px",
                                  color: "white",
                                  backgroundColor: "rgba(229, 0, 0, 0.8)",
                                }}
                                onClick={() =>
                                  setFormData((prev) => ({
                                    ...prev,
                                    uploadedCoverL: null,
                                  }))
                                }
                              />
                            </p>
                          </div>
                        )}

                        {formError.uploadedCoverL && (
                          <div style={{ color: "red" }}>
                            {formError.uploadedCoverL}
                          </div>
                        )}
                      </RSForm.Group>
                    ) : null}
                    <RadioButton
                      name="radioOption"
                      list={[{ value: 2, label: "Select Cover Letter" }]}
                      // label="Upload Options :"
                      value={formData.checkTypeCoverL}
                      onChange={(value) => {
                        setFormData({ ...formData, checkTypeCoverL: value });
                        if (value == 2) {
                          coverLetterGetList();
                        }
                      }}
                    />{" "}
                    {formData.checkTypeCoverL == 2 ? (
                      <FormControl fullWidth>
                        <Autocomplete
                          value={formData.selectedCoverL?.title || ""} // Show title or an empty string if null
                          name="selectedCoverL"
                          options={formData.prevCoverList.map(
                            (option) => option.title
                          )} // Options are titles
                          onChange={(event, newValue) => {
                            const selectedCoverData =
                              formData.prevCoverList.find(
                                (coverLetter) => coverLetter.title === newValue
                              );

                            if (selectedCoverData) {
                              setFormData((prev) => ({
                                ...prev,
                                selectedCoverL: selectedCoverData, // Store the full object
                              }));
                            } else {
                              setFormData((prev) => ({
                                ...prev,
                                selectedCoverL: null, // Clear selection if no match
                              }));
                            }
                          }}
                          sx={{ width: 300 }}
                          renderInput={(params) => (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <TextField
                                {...params}
                                label="Select Cover Letter"
                                sx={{ flex: 1 }}
                              />
                              {formData.selectedCoverL && (
                                <button
                                  onClick={() => {
                                    coverletterDelete(
                                      formData.selectedCoverL["path"]
                                    ); // Delete cover letter
                                  }}
                                  style={{
                                    marginLeft: "8px",
                                    background: "transparent",
                                    border: "none",
                                    cursor: "pointer",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: "20px",
                                      color: "red",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    ✖
                                  </span>
                                </button>
                              )}
                            </div>
                          )}
                        />

                        {formError.selectedCoverL && (
                          <div style={{ color: "red" }}>
                            {formError.selectedCoverL}{" "}
                            {/* Show error message if applicable */}
                          </div>
                        )}
                      </FormControl>
                    ) : (
                      <></>
                    )}
                    <RadioButton
                      name="radioOption"
                      list={[
                        { value: 3, label: "Don't include a Cover Letter" },
                      ]}
                      // label="Upload Options :"
                      value={formData.checkTypeCoverL}
                      onChange={(value) => {
                        setFormData({ ...formData, checkTypeCoverL: value });
                      }}
                    />
                  </FormControl>
                </Box>
              </div>
            </div>
          </div>
        );
      case 1:
        return (
          <div class=" pt-3">
            {getQuestions?.length > 0 ? (
              <>
                <strong>Please answer the following queries:</strong>
                <QuestionList
                  getQuest={getQuestions}
                  onUpdateValue={setAnswers}
                  onValidation={setFormError}
                  errors={formError}
                  answerMount={getAnswers}
                />
                {/* [
                    {
                      1: "PHP",
                      2: ["React", "Node.js"],
                      30: "JavaScript",
                      32: ["2 month"],
                      35: "23Lak",
                      38: "sdf",
                    },
                  ] */}
              </>
            ) : (
              <></>
            )}
          </div>
        );
      case 2:
        return (
          <div class="pt-2">
            <div>
              <ProfilePage />
            </div>
          </div>
        );

      case 3:
        return (
          <div class="pt-2">
            <div className="">
              <ReviewSubmit />
            </div>
          </div>
        );

      default:
        return <div>Unknown step</div>;
    }
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const [index, setIndex] = useState(-1);

  const normalizePath = (path) => path.replace(/\\/g, "/");

  const sortingList = (e) => {
    console.log(e);
    setSortValue(e);
    fetchInfo(1);
  };
  const handleSelectionChange = (selected) => {
    const numbers = new Set();
    const strings = new Set();
    const updatedSelectedItems = [...selected];

    // Loop through the selected items
    selected.forEach((item) => {
      if (typeof item === "number") {
        numbers.add(item); // Add unique numbers to the Set
      } else if (typeof item === "string") {
        // Add child and parent relationships
        selectKeyword.forEach((category) => {
          const parentValue = category.value;
          const childValue = category.children.find(
            (child) => child.value === item
          )?.value;

          if (childValue) {
            strings.add(item); // Add the child value to the strings Set
            numbers.add(parentValue); // Add the parent value to the numbers Set
            if (!updatedSelectedItems.includes(parentValue)) {
              updatedSelectedItems.push(parentValue);
            }
          }
        });
      }
    });

    // Update the states with unique values
    setListFind1(Array.from(numbers));
    setListFind2(Array.from(strings));
    fetchInfo(1);

    console.log("Selected Values - Numbers:", Array.from(numbers));
    console.log("Selected Values - Strings:", Array.from(strings));
  };

  // Do thing process
  const loadMore = () => {
    setLoading(true);
    setTimeout(() => {
      fetchWherePlaceSearchingM();
      setLoading(false);
    }, 1000);
  };

  const onItemsRendered = (props) => {
    if (props.visibleStopIndex >= wherePlaceSearchingList.length - 1) {
      loadMore();
    }
  };

  const renderMenu = (menu) => {
    return (
      <>
        {menu}
        {/* {loading && <FixedLoader />} */}
      </>
    );
  };
  return (
    <>
      <section className="tf-slider sl3 ">
        <div className="tf-container">
          <div className="row text-center">
            <div className="col-lg-2 "></div>
            <div className="col-lg-8 col-md-8">
              <div className="content wow fadeInUp">
                <div className="form-sl">
                  {/* <form action="/job-list-sidebar"> */}

                  <div className="row-group-search ">
                    <VStack>
                      <div className="row col-md-12">
                        <div className="col-md-4">
                          <RSForm.Group controlId="search-keyword">
                            <RSForm.ControlLabel>What</RSForm.ControlLabel>

                            <SearchAutoComplete
                              className="standard-input"
                              data={enterKeywordOptionsList}
                              // style={{ width: 224 }}
                              onSearch={handleSearch}
                              onClickHandler={(e) => {
                                console.log("click", e.target.value);
                              }}
                            />
                            {/* <RSAutoComplete
                              placeholder="Enter Keywords"
                              className="standard-input"
                              data={enterKeywordOptionsList}
                              // onSearch={(e) => {
                              //   console.log(e);
                              // }} //{handleSearch}
                              onChange={(e) => {
                                console.log(e);
                              }}
                              // defaultValue={searchKeyword}
                            /> */}
                            {/* <SearchAutoComplete
                              data={enterKeywordOptionsList}
                              onChange={(e) => {
                                console.log(e.target.value);
                                console.log(e);

                                setSearchKeyword(e);
                                fetchInfo(currentPage);
                              }}
                              onKeyUp={(e) => {
                                fetchInfo(currentPage);
                              }}
                              style={{ width: "100%" }}
                              placeholder="Enter Keywords"
                            /> */}
                          </RSForm.Group>
                        </div>
                        <div className="col-md-4">
                          <RSForm.Group controlId="search-any-classification">
                            <RSForm.ControlLabel>&nbsp;</RSForm.ControlLabel>
                            <RSCheckTreePicker
                              style={{ width: 280 }}
                              placeholder="Any Classification"
                              defaultExpandAll
                              data={selectKeyword}
                              cleanable
                              value={selectedItems} // Bind the selected items
                              onChange={(e) => {
                                setSelectedItems(e);
                                handleSelectionChange(e); // Process the selected values
                              }}
                            />
                          </RSForm.Group>
                        </div>
                        <div className="col-md-4">
                          <RSForm.Group controlId="search-city-region">
                            <RSForm.ControlLabel>Where</RSForm.ControlLabel>
                            <br />
                            <RSSelectPicker
                              data={wherePlaceSearchingList}
                              style={{ width: 224 }}
                              virtualized
                              // groupBy="label"
                              // labelKey="label"
                              // valueKey="value"
                              onChange={(e) => {
                                console.log(e);
                                setWherePlaceSearchFilter(e);
                              }}
                              renderMenu={renderMenu}
                              listProps={{
                                onItemsRendered,
                              }}
                              placeholder="Enter Suburb,city, or region"

                              // defaultExpandAll
                              // virtualized
                              // data={wherePlaceSearchingList}
                              // groupBy="label"
                              // // labelKey="label"
                              // valueKey="value"

                              // renderMenu={renderMenu}
                              // onChange={(e) => {
                              //   console.log(e);
                              //   setWherePlaceSearchFilter(e);

                              // }}
                              // placeholder="Enter Suburb,city, or region"
                              // style={{ width: 200 }}
                            />
                            {/* <RSTreePicker defaultExpandAll data={WherePlaceSearching} style={{ width: 246 }} placeholder="Enter Suburb,city, or region" /> */}
                          </RSForm.Group>
                        </div>
                        {/* <RSAutoComplete data={data} placeholder="Enter Keywords" style={newStyles} /> */}

                        {/* <AsyncSelect
                      value={searchingKeyword} // Set the selected option
                      onChange={handleChange} // Handle option change
                      inputValue={inputValue}
                      onInputChange={(newValue) => {
                        setInputValue(newValue);
                        return newValue;
                      }} // Update input value
                      loadOptions={debouncedLoadOptions} // Load options asynchronously
                      placeholder="Enter Keywords"
                      isClearable
                      cacheOptions
                      styles={customStyles} // Apply custom styles
                    /> */}
                        {/* <SearchSelect
                      options={Array.isArray(keyword) ? keyword : []} // Ensure it's always an array
                      placeholder="Enter Keywords"

                      frameType={1}
                      showIcon={true}      // Set to true or false to toggle icon
                      isClearable={true}    // Set to true to allow clearing selection
                    /> */}
                        {/* <span className="icon-search search-job"></span>
                    <input
                      type="text"
                      className="input-filter-search"
                      placeholder="Job title, key words or company"
                    /> */}
                      </div>
                    </VStack>

                    {/* <span className="icon-map-pin"></span> */}
                    {/* <SelectLocation /> */}

                    {/* working is properly */}
                    {/* <div className="form-group-2 p-2">
                    <SearchSelect
                      options={enterKeywordOptionsList}
                      frameType={2}
                      placeholder="Any Classification"
                      showIcon={false}
                      isClearable={false}
                    />
                  </div>
                  <div className="form-group-3 p-2">
                    <SearchSelect
                      options={enterKeywordOptionsList}
                      placeholder="Enter Suburb,city, or region"
                      frameType={1}
                      showIcon={true}
                      isClearable={true}
                    />

                  </div> */}
                    <div className="form-group-4">
                      <button onClick={fetchInfo} className="btn btn-find">
                        Search
                      </button>
                      {/* sadfsadf <p> {ListContext.name}s</p> */}
                    </div>
                  </div>

                  {/* </form> */}
                </div>

                <ul className="list-category text-white">
                  <li>
                    <RSCheckPicker
                      placeholder="All Work Types"
                      data={employeeTypes}
                      onChange={(e) => {
                        setEmploymentTypeFilter(e);
                        // fetchInfo(currentPage);
                        console.log(e);
                      }}
                      style={{ width: 140 }}
                    />
                  </li>
                  <li>
                    <RSSelectPicker
                      placeholder="Salary Type"
                      data={salaryTypeList}
                      onChange={(e) => {
                        setSalaryTypeFilter(e);
                      }}
                      styles={{ width: 140 }}
                    />
                  </li>
                  <li>
                    <RSSelectPicker
                      placeholder="Salary min"
                      data={salaryRangeList}
                      onChange={(e) => {
                        setSalaryRangeMinFilter(e);
                        console.log(e);
                      }}
                      style={{ width: 140 }}
                    />
                  </li>
                  <li>
                    <RSSelectPicker
                      placeholder="Salary max"
                      data={salaryRangeList}
                      onChange={(e) => {
                        setSalaryRangeMaxFilter(e);
                        console.log(e);
                      }}
                      style={{ width: 140 }}
                    />
                  </li>
                  <li>
                    <RSSelectPicker
                      placeholder="listed any time"
                      data={listingAnyTimeList}
                      onChange={(e) => {
                        console.log(e);

                        setListingTimeList(e);
                      }}
                      style={{ width: 140 }}
                    />
                  </li>
                </ul>
              </div>
            </div>
            <div style={STYLES} className="col-lg-2">
              <div className="wd-review-job thumb2 widget-counter tf-sl3">
                <div className="thumb">
                  <div className="box1">
                    <img
                      src={require("../../assets/images/review/thumb5.png")}
                      alt="images"
                    />
                    <div className="markk ani3">
                      <img
                        src={require("../../assets/images/review/shape3.png")}
                        alt="images"
                      />
                    </div>
                  </div>
                  <div className="box2">
                    <img
                      src={require("../../assets/images/review/thumb6.png")}
                      alt="images"
                    />
                    <div className="markk ani3">
                      <img
                        src={require("../../assets/images/review/shape4.png")}
                        alt="images"
                      />
                    </div>
                  </div>
                </div>
                <div className="tes-box ani5">
                  <div className="client-box">
                    <div className="avt">
                      <img
                        src={require("../../assets/images/review/client.jpg")}
                        alt="images"
                      />
                      <div className="badge"></div>
                    </div>
                    <div className="content">
                      <h6 className="number wrap-counter">1M+ recruiters</h6>
                      <div className="des">Happpy Candidates</div>
                    </div>
                  </div>
                </div>
                <div className="icon1 ani3">
                  <img
                    src={require("../../assets/images/review/icon2.png")}
                    alt="images"
                  />
                </div>
                <div className="icon2 ani4">
                  <img
                    src={require("../../assets/images/review/icon3.png")}
                    alt="images"
                  />
                </div>
                <div className="icon3 ani6">
                  <img
                    src={require("../../assets/images/review/icon11.png")}
                    alt="images"
                  />
                </div>

                <div className="chart-box counter">
                  <h6>Candidates</h6>
                  <CircleProgess />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="tab-section pt-4">
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={{ ...style, width: "80%", maxWidth: "800px" }}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Applying for{" "}
            </Typography>
            <h4>{modalContent.title}</h4>
            <p>{modalContent.cate}</p>
            <Typography id="modal-modal-title" variant="h6" component="h4">
              view job details{" "}
            </Typography>
            {/* <RSButton
              onClick={() => {
                console.log("Data ");
              }}
            >
              Close
            </RSButton> */}
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                // if (isStepOptional(index)) {
                //   labelProps.optional = (
                //     <Typography variant="caption">Optional</Typography>
                //   );
                // }
                if (isStepSkipped(index)) {
                  stepProps.completed = false;
                }
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>

            {activeStep === steps.length ? (
              <></>
            ) : (
              // <React.Fragment>
              //   <Typography sx={{ mt: 2, mb: 1 }}>
              //     All steps completed - you&apos;re finished
              //   </Typography>
              //   <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              //     <Box sx={{ flex: '1 1 auto' }} />
              //     <Button variant="contained" onClick={handleReset}>Reset</Button>
              //   </Box>
              // </React.Fragment>
              <React.Fragment>
                <RSForm
                  ref={formRef}
                  onChange={setFormData}
                  onCheck={setFormError}
                  formValue={formData}
                  model={model}
                >
                  {renderStepContent(activeStep)}{" "}
                  {/* Render the content for the current step */}
                  <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                    {activeStep === 0 ? (
                      <></>
                    ) : (
                      <RSButton
                        appearance="default"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                      >
                        Back
                      </RSButton>
                    )}
                    <Box sx={{ flex: "1 1 auto" }} />
                    {/* {isStepOptional(activeStep) && (
                      <RSButton
                        color="default"
                        onClick={handleSkip}
                        sx={{ mr: 1 }}
                      >
                        Skip
                      </RSButton>
                    )} */}
                    <RSButton appearance="primary" onClick={handleNext}>
                      {activeStep === steps.length - 1 ? "Submit" : "Continue"}
                    </RSButton>
                  </Box>
                </RSForm>
                {/* <RSFlexboxGrid.Item colspan={12}>
                  <JSONView formValue={formData} formError={formError} />
                </RSFlexboxGrid.Item> */}
              </React.Fragment>
            )}
          </Box>
        </Modal>
        {/* dDD */}

        <section className="tab-section">
          <div className="tf-container">
            <Tabs className="row tf-tab">
              <RSRow className="show-grid p-1">
                <RSCol xs={8}>
                  {" "}
                  <Typography>{totalPages ?? 0} Jobs</Typography>
                </RSCol>
                <RSCol style={{ PaddingLeft: "4px" }} xs={5}>
                  &nbsp;&nbsp; Sort By{" "}
                  <RSDropdown
                    title="menu"
                    activeKey={sortValue}
                    onSelect={sortingList}
                  >
                    <RSDropdown.Item as={Link} eventKey="asc">
                      ASC
                    </RSDropdown.Item>
                    <RSDropdown.Item as={Link} eventKey="desc">
                      DESC
                    </RSDropdown.Item>
                  </RSDropdown>
                </RSCol>
                <RSCol xs={10}></RSCol>
              </RSRow>

              {loading ? (
                <div
                  className="text-center"
                  style={{
                    display: "grid",
                    placeItems: "center",
                    height: "100vh",
                  }}
                >
                  <LoaderMount.CuLoader />
                </div>
              ) : (
                <>
                  <div className="col-xl-5 col-lg-5 col-md-12">
                    <div className="group-job-sidebar">
                      <TabList className="list-job menu-tab">
                        {jobPostingList.map((idx) => (
                          <Tab
                            key={idx.id}
                            className="features-job mg-bt-5 ct-tab"
                          >
                            {/* <p>{idx.id}</p> */}
                            <div className="job-archive-header">
                              <div className="inner-box">
                                <div className="logo-company">
                                  <img
                                    src={`${process.env.REACT_APP_ROOT_URL}${idx.img} `}
                                    alt="Jobtex"
                                  />
                                </div>
                                <div className="box-content">
                                  <h4>
                                    <Link>{idx.cate}</Link>
                                  </h4>
                                  <h3>
                                    <Link>{idx.title}</Link>&nbsp;
                                    {idx.status !== 0 && (
                                      <span className="icon-bolt"></span>
                                    )}
                                    <br />
                                    <small>{idx.role}</small>
                                  </h3>
                                  <small title="subtitle2">
                                    <strong>{idx.comp_name}</strong>
                                  </small>
                                  <ul>
                                    <li>
                                      <span className=""></span>&nbsp;{" "}
                                      {idx.work_mode}
                                    </li>
                                    &nbsp;
                                    {idx.job_location !== "" ? (
                                      <>
                                        <li>
                                          <span className="icon-map-pin"></span>
                                          &nbsp;
                                          {idx.job_location ?? ""}
                                        </li>
                                        &nbsp;
                                        <li>
                                          {/* <span className="icon-map-pin"></span> */}
                                          {/* &nbsp; */}
                                          {idx.map}
                                        </li>
                                      </>
                                    ) : (
                                      <>
                                        {/* <li>
                                    <span className="icon-map-pin"></span>
                                    &nbsp;
                                    {idx.map}
                                  </li> */}
                                      </>
                                    )}
                                    &nbsp;
                                    <li>
                                      <span className="icon-calendar"></span>
                                      &nbsp; {idx.time}
                                    </li>
                                  </ul>
                                  <span className="icon-heart"></span>
                                </div>
                              </div>
                            </div>
                            <div className="job-archive-footer">
                              <div className="job-footer-left">
                                <ul className="job-tag">
                                  {idx.employment_type.map((type, index) => (
                                    <li key={index}>
                                      <Link to="#">{type}</Link>
                                    </li>
                                  ))}
                                </ul>
                                <div className="star">
                                  <span className="icon-star-full"></span>
                                  <span className="icon-star-full"></span>
                                  <span className="icon-star-full"></span>
                                  <span className="icon-star-full"></span>
                                  <span className="icon-star-full"></span>
                                </div>
                              </div>
                              <div className="job-footer-right">
                                <div className="price">
                                  {getPriceIcons(idx.sal_symb_id)}&nbsp;
                                  {/* <span className="icon-dolar1"></span> */}
                                  <p>
                                    {idx.price}{" "}
                                    <span className="year">/{idx.sal_pay}</span>
                                  </p>
                                </div>
                                <p className="days">{idx.apply}</p>
                              </div>
                            </div>
                          </Tab>
                        ))}

                        <RSDivider />
                        <RSPaginator
                          prev
                          next
                          first
                          last
                          ellipsis
                          size="lg"
                          boundaryLinks
                          pages={lastPage}
                          // total={totalPages}
                          activePage={currentPage}
                          onSelect={(e) => {
                            handlePageChange(e);
                          }}
                        />
                      </TabList>
                    </div>
                  </div>

                  <div className="col-xl-7 col-lg-7 col-md-12" id="zIndex">
                    {jobPostingList.length != 0 ? (
                      <>
                        {jobPostingList.map((idx) => (
                          <TabPanel
                            key={idx.id}
                            className="content-tab sticky-home2 po-sticky add-mgt"
                          >
                            <article className={`job-article ${idx.stt}`}>
                              <div className="top-content">
                                <div className="features-job style-2 stc-apply">
                                  <div className="job-archive-header">
                                    <div className="inner-box">
                                      <div className="logo-company">
                                        <img
                                          src={`${process.env.REACT_APP_ROOT_URL}${idx.img} `}
                                          alt="job-title-logo"
                                        />
                                      </div>
                                      <div className="box-content">
                                        <Typography title="subtitle2">
                                          <strong>{idx.comp_name}</strong>
                                        </Typography>
                                        <h4>
                                          <Link>{idx.cate}</Link>
                                        </h4>
                                        <h3>
                                          <Link>{idx.title}</Link>
                                          &nbsp;
                                          {idx.status != 0 ? (
                                            <>
                                              <span className="icon-bolt"></span>
                                            </>
                                          ) : (
                                            <> </>
                                          )}
                                          <br />
                                          <small>{idx.role}</small>
                                        </h3>
                                        <ul>
                                          <li>
                                            <span className="">
                                              {" "}
                                              <FcOrganization />
                                            </span>
                                            &nbsp;
                                            {idx.work_mode}
                                          </li>
                                          &nbsp;
                                          {idx.job_location != "" ? (
                                            <>
                                              <li>
                                                <span className="icon-map-pin"></span>
                                                &nbsp;
                                                {idx.job_location ?? ""}
                                              </li>
                                              &nbsp;
                                              <li>
                                                {/* <span className="icon-map-pin"></span> */}
                                                {/* &nbsp; */}
                                                {idx.map}
                                              </li>
                                            </>
                                          ) : (
                                            <>
                                              {/* <li>
                                      <span className="icon-map-pin"></span>
                                      &nbsp;
                                      {idx.map}
                                    </li> */}
                                            </>
                                          )}
                                          &nbsp;
                                          <li>
                                            <span className="icon-calendar"></span>
                                            &nbsp;
                                            {idx.time}
                                          </li>
                                        </ul>
                                        <div className="button-readmore">
                                          {/* { title: idx.title, cate: idx.cate } */}
                                          {/* <span className="icon-heart"></span> */}
                                          {idx.is_already_appiled == true ? (
                                            <h5>
                                              {idx.is_already_appiled_message}
                                            </h5>
                                          ) : (
                                            <Link
                                              className="btn-apply btn-popup"
                                              onClick={() => {
                                                handleRedirect();
                                                setTempSelectApply(idx);
                                                console.log(
                                                  idx.question_select
                                                );
                                                setQuestions(
                                                  idx.question_select
                                                );

                                                setModalContent({
                                                  title: idx.title,
                                                  cate: idx.cate,
                                                });
                                              }}
                                            >
                                              <span className="icon-send"></span>
                                              Apply Now
                                            </Link>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="job-archive-footer">
                                    <div className="job-footer-left">
                                      <ul className="job-tag">
                                        {idx.employment_type.map(
                                          (type, index) => (
                                            <li key={index}>
                                              <Link to="#">{type}</Link>
                                            </li>
                                          )
                                        )}
                                      </ul>
                                      <div className="star">
                                        <span className="icon-star-full"></span>
                                        <span className="icon-star-full"></span>
                                        <span className="icon-star-full"></span>
                                        <span className="icon-star-full"></span>
                                        <span className="icon-star-full"></span>
                                      </div>
                                    </div>
                                    <div className="job-footer-right">
                                      <div className="price">
                                        {getPriceIcons(idx.sal_symb_id)} &nbsp;
                                        {/* <span className="icon-dollar"></span> */}
                                        <p>
                                          {idx.price}{" "}
                                          <span className="year">
                                            /{idx.sal_pay}
                                          </span>
                                        </p>
                                      </div>
                                      <p className="days">{idx.apply}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="inner-content home2"
                                style={{ paddingLeft: "20px" }}
                              >
                                <h5>Full Job Description</h5>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      idx.summarnote_job_description ??
                                      "Job Description",
                                  }}
                                />
                                <RSList style={{ paddingTop: "15px" }}>
                                  <h6>Job information:</h6>
                                  <RSList.Item>
                                    <b>Industry Type:</b>&nbsp;
                                    {idx.company_industry ?? "Industry Type"}
                                  </RSList.Item>
                                  <RSList.Item>
                                    <b>Department:</b>&nbsp;
                                    {idx.cate ?? "Department Category"}
                                  </RSList.Item>
                                  <RSList.Item>
                                    <b>Role :</b>&nbsp;
                                    {idx.role ?? "Role Category"}
                                  </RSList.Item>
                                  <RSList.Item>
                                    <b>Employment Type:</b>&nbsp;
                                    {idx.employment_type?.join(", ") ??
                                      "jobType"}
                                  </RSList.Item>
                                  <RSList.Item>
                                    <h6>
                                      <b>Eduction & Qualifications:</b>
                                    </h6>
                                    <RSTagGroup className="pt-2">
                                      {idx.education_list.length > 0 &&
                                      idx.education_list != [] ? (
                                        idx.education_list.map(
                                          (label, index) => (
                                            <RSTag
                                              key={index}
                                              size="md"
                                              color="white"
                                              style={{
                                                backgroundColor: "#14a077",
                                                color: "white",
                                              }}
                                            >
                                              {" "}
                                              {label ??
                                                "Education Qualification"}
                                            </RSTag>
                                          )
                                        )
                                      ) : (
                                        <li>
                                          <Link to="#">
                                            No Education & Qualification
                                            available
                                          </Link>
                                        </li>
                                      )}
                                    </RSTagGroup>
                                  </RSList.Item>
                                  <RSList.Item>
                                    <h6>
                                      <b>Key Skills</b>
                                    </h6>
                                    <RSTagGroup className="pt-2">
                                      {idx.key_skill.length > 0 &&
                                      idx.key_skill != [] ? (
                                        idx.key_skill.map((label, index) => (
                                          <RSTag
                                            key={index}
                                            size="md"
                                            style={{
                                              backgroundColor: "#14a077",
                                              color: "white",
                                            }}
                                          >
                                            {" "}
                                            {label ?? "Education Qualification"}
                                          </RSTag>
                                        ))
                                      ) : (
                                        <li>
                                          <Link to="#">
                                            No Skills available
                                          </Link>
                                        </li>
                                      )}
                                    </RSTagGroup>
                                  </RSList.Item>
                                </RSList>

                                <>
                                  <h4>About Company</h4>
                                  <p>{idx.comp_name ?? "companyName"}</p>

                                  <div className="video-thumb">
                                    <div className="content-tab2">
                                      {idx.comp_video_content ? (
                                        <>
                                          <iframe
                                            width="800"
                                            height="320"
                                            // src={`https://www.youtube.com/embed/${
                                            //   idx.comp_video_content
                                            //     ?.split("v=")[1]
                                            //     ?.split("&")[0]
                                            // }`}
                                            title="YouTube video player"
                                            frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                            referrerPolicy="strict-origin-when-cross-origin"
                                            allowFullScreen
                                          ></iframe>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                    <PhotoAlbum
                                      layout="rows"
                                      photos={idx.comp_image_upload.map(
                                        (image) => ({
                                          src: `${
                                            process.env.REACT_APP_ROOT_URL
                                          }/storage/${normalizePath(
                                            image.path
                                          )}`, // Normalize the path
                                          width: 800,
                                          height: 600,
                                        })
                                      )}
                                      targetRowHeight={150}
                                      onClick={({ index: current }) =>
                                        setIndex(current)
                                      }
                                    />

                                    <Lightbox
                                      index={index}
                                      slides={idx.comp_image_upload
                                        .map((image) => ({
                                          src: `${
                                            process.env.REACT_APP_ROOT_URL
                                          }/storage/${normalizePath(
                                            image.path
                                          )}`, // Normalize the path
                                          width: 800,
                                          height: 600,
                                        }))
                                        .map(({ src }) => ({ src }))}
                                      open={index >= 0}
                                      close={() => setIndex(-1)}
                                    />
                                  </div>

                                  <h4>Company Information</h4>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        idx?.summarnote_comp_about ??
                                        "aboutCompany",
                                    }}
                                  />
                                </>

                                <div className="post-navigation d-flex aln-center pt-2">
                                  <div className="wd-social d-flex aln-center">
                                    <span>Social Profiles:</span>
                                    <ul className="list-social d-flex aln-center">
                                      <li>
                                        <Link to={idx.social_link_fb}>
                                          <i className="icon-facebook"></i>
                                        </Link>
                                      </li>
                                      <li>
                                        <Link to={idx.social_link_in}>
                                          <i className="icon-linkedin2"></i>
                                        </Link>
                                      </li>
                                      <li>
                                        <Link to="#">
                                          <i className="icon-twitter">
                                            {idx.social_link_tw}
                                          </i>
                                        </Link>
                                      </li>
                                      <li>
                                        <Link to="#">
                                          <i className="icon-instagram1">
                                            {idx.social_link_ins}
                                          </i>
                                        </Link>
                                      </li>
                                      <li>
                                        <Link to="#">
                                          <i className="icon-youtube">
                                            {idx.social_link_you}
                                          </i>
                                        </Link>
                                      </li>
                                    </ul>
                                  </div>
                                  <Link to="#" className="frag-btn">
                                    {" "}
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="14"
                                      height="15"
                                      viewBox="0 0 14 15"
                                      fill="none"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M0 3C0 2.20435 0.316071 1.44129 0.87868 0.87868C1.44129 0.316071 2.20435 0 3 0H13C13.1857 0 13.3678 0.0517147 13.5257 0.149349C13.6837 0.246984 13.8114 0.386681 13.8944 0.552786C13.9775 0.718892 14.0126 0.904844 13.996 1.08981C13.9793 1.27477 13.9114 1.45143 13.8 1.6L11.25 5L13.8 8.4C13.9114 8.54857 13.9793 8.72523 13.996 8.91019C14.0126 9.09516 13.9775 9.28111 13.8944 9.44721C13.8114 9.61332 13.6837 9.75302 13.5257 9.85065C13.3678 9.94829 13.1857 10 13 10H3C2.73478 10 2.48043 10.1054 2.29289 10.2929C2.10536 10.4804 2 10.7348 2 11V14C2 14.2652 1.89464 14.5196 1.70711 14.7071C1.51957 14.8946 1.26522 15 1 15C0.734784 15 0.48043 14.8946 0.292893 14.7071C0.105357 14.5196 0 14.2652 0 14V3Z"
                                        fill="#64666C"
                                      />
                                    </svg>
                                    Report job
                                  </Link>
                                </div>
                              </div>
                            </article>
                          </TabPanel>
                        ))}{" "}
                      </>
                    ) : (
                      <LottieControl />
                    )}
                  </div>
                </>
              )}
            </Tabs>
          </div>
        </section>
      </section>
    </>
  );
}

export default HomePage;
