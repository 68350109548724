import React, { useState } from "react";
import {
  experimentalStyled,
  useMediaQuery,
  Container,
  Box,
} from "@mui/material";
import { Outlet } from "react-router-dom";
import { TopbarHeight } from "../../Employer/assets/global/Theme-variable";
import Header from "../../layouts/subCandidate/Header";
import Footer from "../../layouts/subCandidate/Footer";
import Gotop from "../../components/gotop";
import NotFoundPage from "../../pages/PageNotFound.js";

const CandidateLayout = () => {
  return (
    <>
      <>
        <Header />
        <Outlet />

        <Footer />
        <Gotop />
      </>
    </>
  );
};

export default CandidateLayout;
