import { Business } from "@mui/icons-material";
import * as Yup from "yup";

const profilePageSchema = Yup.object({
  firstName: Yup.string().required("first name is required"),
  middleName: Yup.string().nullable(),
  lastName: Yup.string().required("last name is required"),
  backgroundPoster: Yup.mixed().nullable(),
  //     .nullable()
  //     // .required("Background Poster is required")
  //     .test("fileType", "Only images are allowed", (value) => {
  //       return (
  //         value && ["image/jpeg", "image/png", "image/jpg"].includes(value.type)
  //       );
  //     })
  //     .test("fileSize", "File size must be less than 2MB", (value) => {
  //       return value && value.size <= 2 * 1024 * 1024; // 2MB limit
  //     }),
  companyLogo: Yup.mixed().nullable(),
  companyName: Yup.string().required("company name is required"),
  companyPriAdd: Yup.string().nullable(),
  companyUrl: Yup.string().nullable(),

  //     .nullable()
  //     // .required("Company Logo is required")
  //     .test("fileType", "Only images are allowed", (value) => {
  //       return (
  //         value && ["image/jpeg", "image/png", "image/jpg"].includes(value.type)
  //       );
  //     })
  //     .test("fileSize", "File size must be less than 2MB", (value) => {
  //       return value && value.size <= 2 * 1024 * 1024; // 2MB limit
  //     }),
  companyVideoUrl: Yup.string().nullable(),
  aboutCompany: Yup.mixed().nullable(),
  jobDescription: Yup.string().nullable(),

  socialLinkFB: Yup.string().nullable(),
  socialLinkIn: Yup.string().nullable(),
  socialLinkTw: Yup.string().nullable(),
  socialLinkIns: Yup.string().nullable(),
  socialLinkYou: Yup.string().nullable(),

  // .required("Job description is required")
  // .min(10, "Description must be at least 10 characters"),
});

const deploySchema = { profilePageSchema };
export default deploySchema;
