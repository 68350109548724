import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import Footer from "../../../components/footer";
import "rsuite/dist/rsuite.min.css";
import { Link, useNavigate } from "react-router-dom";

import { Edit, Trash, Eye } from "@rsuite/icons";
import SortBuy from "../../../components/dropdown/SortBuy";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import MapSingle from "../../../components/map/MapSingle";
import Gotop from "../../../components/gotop";
import lo1 from "../../../assets/images/logo-company/cty4.png";
import { Rating } from "react-simple-star-rating";
import StarProgress from "../../../components/progressBar/StarProgress";
import Video from "../../../components/popup/Video";
import Gallery from "../../../components/popup/Gallery";
import { Collapse } from "react-collapse";
import logo from "../../../assets/images/logo.png";
import Header4 from "../../../components/header/Header4";
import JoditEditor from "jodit-react";
import CloseIcon from "@rsuite/icons/Close";

import AddOutlineIcon from "@rsuite/icons/AddOutline";
import LottieControl from "../../../utils/LottieControl";
import Session from "../../../api/session";
import {
  CheckPicker,
  TreePicker,
  InputPicker as RSInputPicker,
  InlineEdit as RSInlineEdit,
  VStack,
  Schema,
  Panel,
  FlexboxGrid,
  Form,
  Uploader,
  CheckPicker as RSCheckPicker,
  Drawer as RSDrawer,
  ButtonToolbar as RSButtonToolbar,
  Radio as RSRadio,
  Button as RSButton,
  Input as RSInput,
  CheckboxGroup as RSCheckboxGroup,
  Checkbox as RSCheckbox,
  Toggle as RSToggle,
  RadioGroup as RSRadioGroup,
  SelectPicker as RSSelectPicker,
  CheckTreePicker,
  Toggle,
  IconButton,
  Stack,
  Whisper,
  Tooltip,
  Placeholder,
  Highlight as RSHighlight,
  Loader,
  Grid,
  Input,
  Row,
  Text,
  Modal,
  Divider as RSDivider,
  Button,
  Pagination as RSPaginator,
  ButtonToolbar,
} from "rsuite";
import PhotoAlbum from "react-photo-album";

import { useQuery, useMutation } from "@tanstack/react-query";
import {
  useEmployers,
  useEmployersUpdate,
} from "../../hooks/profile/useEmployer";
import apiService from "../../../api/apiService";
import LoaderMount from "../../../components/preloader";
import { useFormik } from "formik";
import deploySchema from "../../../schemas/Employer/profilepage/ProfilePageSchema";
import { backdropClasses } from "@mui/material";
import { sweetAlert } from "../../../utils/sweetAlert";
// import { useNavigate } from "react-router-dom";

const marKers = [
  {
    id: 1,
    title: "Rockstar Games New York",
    name: "Senior UI/UX Designer",
    address: "Las Vegas, NV 89107, USA",
    longitude: -74.00122,
    latitude: 40.71023,
    img: lo1,
  },
];

ProfilePage.propTypes = {};

function ProfilePage(props) {
  const [rating, setRating] = useState(0);
  const progressRef = useRef();
  const [targetHeight, setTargetHeight] = useState(0);
  const [toggle, setToggle] = useState({
    key: "",
    status: false,
  });
  const [isShowMobile, setShowMobile] = useState(false);

  // aferting submit trigger
  const [employerProfileDetail, setEmployerProfileDetails] = useState(null);

  const handleRating = (rate) => {
    setRating(rate);
  };
  const handleToggle = (key) => {
    if (toggle.key === key) {
      setToggle({
        status: false,
      });
    } else {
      setToggle({
        status: true,
        key,
      });
    }
  };

  const navigate = useNavigate();
  const handleMobile = () => {
    const getMobile = document.querySelector(".menu-mobile-popup");
    setShowMobile(!isShowMobile);
    !isShowMobile
      ? getMobile.classList.add("modal-menu--open")
      : getMobile.classList.remove("modal-menu--open");
  };

  const handleDelete = async (idIndex) => {
    try {
      const response = await apiService.getEManageJobDelete({ id: idIndex });

      if (response?.case === 1) {
        sweetAlert("Success!", "The job has been deleted successfully.", 1);

        fetchInfo();
        // Callback to update the parent component if necessary
        // onJobDeleted?.(idx.id);
      } else {
        // Error Alert
        sweetAlert("Error!", response?.msg || "An error occurred.", 2);
      }
    } catch (error) {
      // Log the error and show the user a fallback message
      console.error("Error deleting job:", error);
      sweetAlert("Error!", "Failed to delete the job. Please try again.", 2);
    }
  };
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = (value) => {
    setSize(value);
    setOpen(true);
  };
  const [size, setSize] = React.useState();

  const editor = useRef(null);
  const [index, setIndex] = useState(-1);

  const {
    data: employerProfileData,
    error,
    isLoading,
    refetch,
  } = useEmployers();
  // Job Page setting calling
  const [jobManagePostingList, setManageJobPostingList] = useState([]);

  const [shortlisted, setShortlisted] = useState(2);
  const [perPagefil, setPerPageFil] = useState(10);
  const [perSortList, setPerSortList] = useState("asc");

  const [searchValue, setSearchValue] = useState("");
  const [selectedJobStatus, setSelectedJobStatus] = useState("1");
  const [selectedCategory, setSelectedCategory] = useState("1");

  const [loading, setLoading] = useState(false);

  // pagination
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);

  //total Jobs
  const [totalPages, setTotalPages] = useState(1);

  function getCallback() {
    if (employerProfileData) {
      setFieldValueProfileUpdate(
        "firstName",
        employerProfileData.data.first_name
      );
      setFieldValueProfileUpdate(
        "middleName",
        employerProfileData.data.middle_name
      );
      setFieldValueProfileUpdate(
        "lastName",
        employerProfileData.data.last_name
      );

      setFieldValueProfileUpdate(
        "companyVideoUrl",
        employerProfileData.data.video_url
      );
      setFieldValueProfileUpdate(
        "aboutComp",
        employerProfileData.data.comp_about
      );

      setFieldValueProfileUpdate(
        "socialLinkFB",
        employerProfileData.data.social_link_fb
      );
      setFieldValueProfileUpdate(
        "socialLinkIn",
        employerProfileData.data.social_link_in
      );
      setFieldValueProfileUpdate(
        "socialLinkTw",
        employerProfileData.data.social_link_tw
      );
      setFieldValueProfileUpdate(
        "socialLinkIns",
        employerProfileData.data.social_link_ins
      );
      setFieldValueProfileUpdate(
        "socialLinkYou",
        employerProfileData.data.social_link_you
      );

      setFieldValueProfileUpdate(
        "companyName",
        employerProfileData.data.comp_name
      );
      setFieldValueProfileUpdate(
        "companyPriAdd",
        employerProfileData.data.comp_primary_addr
      );
      setFieldValueProfileUpdate(
        "companyUrl",
        employerProfileData.data.comp_url
      );

      // setFieldValueProfileUpdate(
      //   "companyImageUpload",
      //   employerProfileData.data.comp_image_upload
      // );
    }
  }

  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchInfo(page);
  };
  const fetchInfo = async (pageIndex) => {
    const sessionData = Session.getDataEmployerSessionStore();
    console.log(sessionData);

    const downPoint = {
      id: sessionData && sessionData.id ? sessionData.id : 0,
      check_status: 1,
      encrypted:
        sessionData && sessionData.encryptId ? sessionData.encryptId : "0",
      page: pageIndex,
      per_page: perPagefil,
      order_direction: perSortList,
      keyword: searchValue,
    };

    const response = await apiService.getEManageJobList(downPoint);

    // console.log(response);

    setCurrentPage(response.data.current_page);
    setLastPage(response.data.last_page);
    setItemsPerPage(response.data.per_page);
    setTotalPages(response.data.total);
    setManageJobPostingList(response.data.data);

    setLoading(false);

    if (!response) return;
  };

  const formvariable = {
    firstName: "",
    middleName: "",
    lastName: "",
    backgroundPoster: null,
    companyLogo: null,
    companyName: "",
    companyPriAdd: "",
    companyUrl: "",
    aboutComp: "",
    companyVideoUrl: "",
    companyImageUpload: [],
    socialLinkFB: "",
    socialLinkIn: "",
    socialLinkTw: "",
    socialLinkIns: "",
    socialLinkYou: "",
  };
  const {
    handleChange: handleChangeProfileUpdate,
    handleSubmit: handleSubmitProfileUpdate,
    handleBlur: handleBlurProfileUpdate,
    setFieldValue: setFieldValueProfileUpdate,
    values: valuesProfileUpdate,
    errors: errorsProfileUpdate,
    touched: touchedProfileUpdate,
  } = useFormik({
    initialValues: formvariable,
    validationSchema: deploySchema.profilePageSchema,
    onSubmit: async (values, action) => {
      console.log(values.companyImageUpload);

      const formDatas = new FormData();
      formDatas.append("first_name", values.firstName);
      formDatas.append("middle_name", values.middleName);
      formDatas.append("last_name", values.lastName);
      formDatas.append("about_comp", values.aboutComp);
      formDatas.append("comp_name", values.companyName);
      formDatas.append("comp_primary_addr", values.companyPriAdd);
      formDatas.append("comp_url", values.companyUrl);
      formDatas.append("social_link_fb", values.socialLinkFB);
      formDatas.append("social_link_in", values.socialLinkIn);
      formDatas.append("social_link_tw", values.socialLinkTw);
      formDatas.append("social_link_ins", values.socialLinkIns);
      if (values.companyImageUpload && values.companyImageUpload.length > 0) {
        values.companyImageUpload.forEach((image, index) => {
          if (image instanceof File) {
            formDatas.append(`company_image_upload[]`, image);
          } else {
            console.warn(`Skipping invalid file at index ${index}`);
          }
        });
      }

      // Check if the file is properly set before appending
      if (values.backgroundPoster instanceof File) {
        formDatas.append("backgroundPoster", values.backgroundPoster);
      } else {
        console.warn("backgroundPoster is not a file");
      }

      if (values.companyLogo instanceof File) {
        formDatas.append("businessLogo", values.companyLogo);
      } else {
        console.warn("businessLogo is not a file");
      }

      if (values.companyVideoUrl) {
        formDatas.append("video_url", values.companyVideoUrl);
      }

      console.log("Final FormData contents:");
      for (let pair of formDatas.entries()) {
        console.log(pair[0], pair[1]); // Debugging
      }
      console.log(formDatas);

      try {
        const getDetails = await apiService.getEmployerProfileDetailsUpdate(
          formDatas
        );

        sweetAlert(
          "Success!",
          "Your Company Details has been Successfully Updated",
          1
        );

        console.log("API Response:", getDetails);
        action.resetForm();
        setOpen(false);
        refetch();
      } catch (error) {
        console.error("API Error:", error);
        // sweetAlert("Failed!", "Your Company Details Not Updated !", 2);
      }
    },
  });
  const removeImage = (index) => {
    const updatedImages = valuesProfileUpdate.companyImageUpload.filter(
      (_, i) => i !== index
    );
    setFieldValueProfileUpdate("companyImageUpload", updatedImages);
  };
  const photos =
    employerProfileDetail?.comp_image_upload?.map((image) => ({
      src: `${process.env.REACT_APP_ROOT_URL}/${image}`, // Convert relative path to full URL
      width: 800,
      height: 600,
    })) || [];
  // employerProfieUpdate
  useEffect(() => {
    if (employerProfileData) {
      setEmployerProfileDetails(employerProfileData.data);
    }
    fetchInfo(currentPage);
    getCallback();

    if (progressRef?.current) {
      const offsetHeight = progressRef?.current?.offsetTop;
      setTargetHeight(offsetHeight);
    }
  }, [progressRef, employerProfileData, currentPage, perPagefil, perSortList]);
  if (isLoading)
    return (
      <div
        className="text-center"
        style={{
          display: "grid",
          placeItems: "center",
          height: "100vh",
        }}
      >
        <LoaderMount.CuLoader />
      </div>
    );
  if (error) return <p>Error: {error.message}</p>;

  return (
    <>
      <section className="single-job-thumb">
        <img
          src={
            process.env.REACT_APP_ROOT_URL +
            employerProfileDetail?.background_poster
          }
          alt="images"
        />
      </section>

      <section className="form-sticky fixed-space">
        <div className="tf-container">
          <div className="row">
            <div className="col-lg-12">
              <div className="wd-job-author stc-em">
                <div className="inner-job-left">
                  <img
                    src={
                      process.env.REACT_APP_ROOT_URL +
                      employerProfileDetail?.business_logo
                    }
                    alt="Jobtex"
                    className="logo-company"
                  />
                  <div className="content">
                    <h3>
                      <Link to="#">
                        {employerProfileDetail?.first_name}{" "}
                        {employerProfileDetail?.middle_name}{" "}
                        {employerProfileDetail?.last_name}
                      </Link>
                      &nbsp;
                      <span className="icon-bolt"></span>
                    </h3>
                    {/* <div className="job-info">
                      <span className="icon-map-pin"></span>
                      <span>{employerProfileDetail?.home_location} </span>
                    </div> */}
                    <div className="group-btn">
                      {/* <button className="tf-btn">3 Inactive</button> */}
                      <button className="tf-btn">{totalPages} Active</button>
                    </div>
                  </div>
                </div>
                <div className="inner-job-right">
                  <span className="icon-share2"></span>
                  <div className="group-btn">
                    <button
                      size="md"
                      onClick={() => handleOpen("lg")}
                      className="tf-btn-submit btn-popup"
                    >
                      Update Details
                    </button>
                    {/* <button className="tf-btn">Message</button> */}
                  </div>
                </div>
                <Modal
                  style={{ paddingTop: "30px" }}
                  onClose={handleClose}
                  size={size}
                  open={open}
                >
                  <Modal.Header>
                    <Modal.Title>Profile Details </Modal.Title>
                  </Modal.Header>
                  <form onSubmit={handleSubmitProfileUpdate}>
                    <Modal.Body>
                      <div className="row">
                        <div className="col-md-4 form-group">
                          <label className="form-label">
                            First Name<span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            style={{ color: "black" }}
                            name="firstName"
                            value={valuesProfileUpdate.firstName}
                            onChange={handleChangeProfileUpdate}
                            onBlur={handleBlurProfileUpdate}
                            placeholder="First Name"
                            className={`form-control
                            `}
                          />
                          {errorsProfileUpdate.firstName &&
                            touchedProfileUpdate.firstName && (
                              <span className="error">
                                {errorsProfileUpdate.firstName}
                              </span>
                            )}
                        </div>
                        <div className="col-md-4 form-group">
                          <label className="form-label">Middle Name</label>
                          <input
                            type="text"
                            style={{ color: "black" }}
                            name="middleName"
                            value={valuesProfileUpdate.middleName}
                            onChange={handleChangeProfileUpdate}
                            onBlur={handleBlurProfileUpdate}
                            placeholder="Middle Name"
                            className={`form-control
                            `}
                          />
                          {errorsProfileUpdate.middleName &&
                            touchedProfileUpdate.middleName && (
                              <span className="error">
                                {errorsProfileUpdate.middleName}
                              </span>
                            )}
                        </div>
                        <div className="col-md-4 form-group">
                          <label className="form-label">
                            Last Name<span className="text-danger">*</span>
                          </label>
                          <input
                            style={{ color: "black" }}
                            type="text"
                            name="lastName"
                            value={valuesProfileUpdate.lastName}
                            onChange={handleChangeProfileUpdate}
                            onBlur={handleBlurProfileUpdate}
                            placeholder="Last Name"
                            className={`form-control
                            `}
                          />
                          {errorsProfileUpdate.lastName &&
                            touchedProfileUpdate.lastName && (
                              <span className="error">
                                {errorsProfileUpdate.lastName}
                              </span>
                            )}
                        </div>

                        <div className="pt-4 row ">
                          <div className="col-md-4 form-group">
                            <label className="form-label">
                              Company Name
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              disabled={true}
                              style={{ color: "black" }}
                              type="text"
                              name="companyName"
                              value={valuesProfileUpdate.companyName}
                              onChange={handleChangeProfileUpdate}
                              onBlur={handleBlurProfileUpdate}
                              placeholder="Company Name "
                              className={`form-control
                            `}
                            />
                            {errorsProfileUpdate.companyName &&
                              touchedProfileUpdate.companyName && (
                                <span className="error">
                                  {errorsProfileUpdate.companyName}
                                </span>
                              )}
                          </div>
                          <div className="col-md-4 form-group">
                            <label className="form-label">
                              Company Url
                              {/* <span className="text-danger">*</span> */}
                            </label>
                            <input
                              style={{ color: "black" }}
                              type="text"
                              name="companyUrl"
                              value={valuesProfileUpdate.companyUrl}
                              onChange={handleChangeProfileUpdate}
                              onBlur={handleBlurProfileUpdate}
                              placeholder="Company Url "
                              className={`form-control
                            `}
                            />
                            {errorsProfileUpdate.companyUrl &&
                              touchedProfileUpdate.companyUrl && (
                                <span className="error">
                                  {errorsProfileUpdate.companyUrl}
                                </span>
                              )}
                          </div>

                          <div className="col-md-4 form-group">
                            <label className="form-label">
                              Company Video Url &nbsp;(embed)
                              {/* <span className="text-danger">*</span> */}
                            </label>
                            <input
                              style={{ color: "black" }}
                              type="text"
                              name="companyVideoUrl"
                              value={valuesProfileUpdate.companyVideoUrl}
                              onChange={handleChangeProfileUpdate}
                              onBlur={handleBlurProfileUpdate}
                              placeholder="Company Video Url"
                              className={`form-control
                            `}
                            />
                            {errorsProfileUpdate.companyVideoUrl &&
                              touchedProfileUpdate.companyVideoUrl && (
                                <span className="error">
                                  {errorsProfileUpdate.companyVideoUrl}
                                </span>
                              )}
                          </div>
                        </div>
                        <div className="pt-4 row ">
                          <div className="col-md-4  form-group">
                            <label className="form-label">
                              Background Poster
                            </label>
                            <input
                              type="file"
                              name="backgroundPoster"
                              accept="image/*"
                              onChange={(event) => {
                                setFieldValueProfileUpdate(
                                  "backgroundPoster",
                                  event.currentTarget.files[0]
                                );
                              }}
                              onBlur={handleBlurProfileUpdate}
                              className={`form-control ${
                                errorsProfileUpdate.backgroundPoster &&
                                touchedProfileUpdate.backgroundPoster
                                  ? "is-invalid"
                                  : ""
                              }`}
                            />
                            {errorsProfileUpdate.backgroundPoster &&
                              touchedProfileUpdate.backgroundPoster && (
                                <span className="error">
                                  {errorsProfileUpdate.backgroundPoster}
                                </span>
                              )}
                          </div>
                          <div className="col-md-4 form-group">
                            <label className="form-label">Company Logo</label>
                            <input
                              type="file"
                              name="companyLogo"
                              accept="image/*"
                              onChange={(event) => {
                                setFieldValueProfileUpdate(
                                  "companyLogo",
                                  event.currentTarget.files[0]
                                );
                              }}
                              onBlur={handleBlurProfileUpdate}
                              className={`form-control ${
                                errorsProfileUpdate.companyLogo &&
                                touchedProfileUpdate.companyLogo
                                  ? "is-invalid"
                                  : ""
                              }`}
                            />
                            {errorsProfileUpdate.companyLogo &&
                              touchedProfileUpdate.companyLogo && (
                                <span className="error">
                                  {errorsProfileUpdate.companyLogo}
                                </span>
                              )}
                          </div>
                          <div className=" col-md-4 form-group">
                            <label className="form-label">
                              Company Primary Address
                              {/* <span className="text-danger">*</span> */}
                            </label>
                            <textarea
                              style={{ color: "black" }}
                              type="text"
                              cols={2}
                              rows={3}
                              name="companyPriAdd"
                              value={valuesProfileUpdate.companyPriAdd}
                              onChange={handleChangeProfileUpdate}
                              onBlur={handleBlurProfileUpdate}
                              placeholder="company primary address"
                              className={`form-control
                            `}
                            />
                            {errorsProfileUpdate.companyPriAdd &&
                              touchedProfileUpdate.companyPriAdd && (
                                <span className="error">
                                  {errorsProfileUpdate.companyPriAdd}
                                </span>
                              )}
                          </div>
                        </div>
                        <div className="pt-4 row ">
                          <label>Company Image Upload </label>

                          <input
                            type="file"
                            name="companyImageUpload"
                            multiple // ✅ Allows multiple selection
                            accept="image/*"
                            className={`form-control `}
                            onChange={(event) => {
                              const files = event.target.files;
                              setFieldValueProfileUpdate("companyImageUpload", [
                                ...valuesProfileUpdate.companyImageUpload,
                                ...Array.from(files),
                              ]);
                            }}
                          />

                          {/* Preview Uploaded Images */}
                          <div
                            className="col-md-12"
                            style={{
                              paddingTop: "4px",
                              display: "flex",
                              flexWrap: "wrap", // ✅ Wrap images if they exceed container width
                              gap: "10px", // ✅ Add spacing between images
                              justifyContent: "start", // ✅ Center images
                            }}
                          >
                            {valuesProfileUpdate.companyImageUpload.map(
                              (file, index) => (
                                <div
                                  key={index}
                                  style={{
                                    position: "relative",
                                    width: "120px",
                                    height: "120px",
                                    border: "1px solid #ddd",
                                    borderRadius: "5px",
                                    overflow: "hidden",
                                    flexShrink: 0,
                                  }}
                                >
                                  {/* Image Preview */}
                                  <img
                                    src={
                                      file instanceof File
                                        ? URL.createObjectURL(file)
                                        : `http://localhost:8000/storage/${file.path}`
                                    }
                                    alt={`preview-${index}`}
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      objectFit: "cover",
                                    }}
                                  />

                                  {/* Delete Button */}
                                  <Whisper
                                    placement="top"
                                    trigger="hover"
                                    speaker={<Tooltip>Remove Image</Tooltip>}
                                  >
                                    <IconButton
                                      icon={<CloseIcon />}
                                      size="xs"
                                      circle
                                      appearance="subtle"
                                      style={{
                                        position: "absolute",
                                        top: "5px",
                                        right: "5px",
                                        backgroundColor:
                                          "rgba(255, 255, 255, 0.8)",
                                      }}
                                      onClick={() => removeImage(index)}
                                    />
                                  </Whisper>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                        <div className="pt-4 row  col-md-12">
                          <div className=" form-group">
                            <label className="form-label">
                              About Company
                              {/* <span className="text-danger">*</span> */}
                            </label>
                            <JoditEditor
                              ref={editor}
                              config={{
                                readonly: false, // Editor is editable
                                height: 400, // Set height of the editor
                                placeholder: "Write job details here...", // Placeholder text
                                toolbar: {
                                  buttons: [
                                    "bold",
                                    "italic",
                                    "underline",
                                    "strikethrough",
                                    "font",
                                    "fontsize",
                                    "paragraph",
                                    "align",
                                    "orderedlist",
                                    "unorderedlist",
                                    "outdent",
                                    "indent",
                                    "link",
                                    "image",
                                    "video",
                                    "table",
                                    "clean",
                                    "undo",
                                    "redo",
                                    "copy",
                                    "paste",
                                  ],
                                },
                                speechRecognition: false,
                                toolbarAdaptive: false,
                                toolbarSticky: false,
                              }}
                              value={valuesProfileUpdate.aboutComp}
                              onBlur={(newContent) =>
                                setFieldValueProfileUpdate(
                                  "aboutComp",
                                  newContent
                                )
                              }
                            />
                          </div>
                        </div>
                        <div className="pt-4 row col-md-12">
                          <h4>Social Links</h4>
                          <div className="col-md-4 form-group">
                            <label className="form-label">Facebook</label>
                            <input
                              style={{ color: "black" }}
                              type="text"
                              name="socialLinkFB"
                              value={valuesProfileUpdate.socialLinkFB}
                              onChange={handleChangeProfileUpdate}
                              onBlur={handleBlurProfileUpdate}
                              placeholder="Facebook Url"
                              className={`form-control
                            `}
                            />
                          </div>{" "}
                          <div className="col-md-4 form-group">
                            <label className="form-label">LinkdIn</label>
                            <input
                              style={{ color: "black" }}
                              type="text"
                              name="socialLinkIn"
                              value={valuesProfileUpdate.socialLinkIn}
                              onChange={handleChangeProfileUpdate}
                              onBlur={handleBlurProfileUpdate}
                              placeholder="LinkdIn Url"
                              className={`form-control
                            `}
                            />
                          </div>{" "}
                          <div className="col-md-4 form-group">
                            <label className="form-label">Twitter</label>
                            <input
                              style={{ color: "black" }}
                              type="text"
                              name="socialLinkTw"
                              value={valuesProfileUpdate.socialLinkTw}
                              onChange={handleChangeProfileUpdate}
                              onBlur={handleBlurProfileUpdate}
                              placeholder="Twitter Url"
                              className={`form-control
                            `}
                            />
                          </div>{" "}
                          <div className="pt-2 col-md-4 form-group">
                            <label className="form-label">Instagram</label>
                            <input
                              style={{ color: "black" }}
                              type="text"
                              name="socialLinkIns"
                              value={valuesProfileUpdate.socialLinkIns}
                              onChange={handleChangeProfileUpdate}
                              onBlur={handleBlurProfileUpdate}
                              placeholder="Instagram Url"
                              className={`form-control
                            `}
                            />
                          </div>{" "}
                          <div className="pt-2 col-md-4 form-group">
                            <label className="form-label">Youtube Url </label>
                            <input
                              style={{ color: "black" }}
                              type="text"
                              name="socialLinkYou"
                              value={valuesProfileUpdate.socialLinkYou}
                              onChange={handleChangeProfileUpdate}
                              onBlur={handleBlurProfileUpdate}
                              placeholder="Youtube Url"
                              className={`form-control
                            `}
                            />
                          </div>
                        </div>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <button
                        onClick={handleClose}
                        className="btn btn-danger"
                        appearance="subtle"
                      >
                        Cancel
                      </button>{" "}
                      {/* <Button  type="submit" appearance="primary">Primary</Button> */}
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <button
                        type="submit"
                        className="btn btn-primary"
                        appearance="primary"
                      >
                        Update Details
                      </button>
                    </Modal.Footer>
                  </form>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="inner-employer-section">
        <div className="tf-container">
          <div className="row">
            <div className="col-lg-8">
              <Tabs className="job-article tf-tab single-job single-employer">
                <TabList className="menu-tab">
                  <Tab className="ct-tab">About</Tab>
                  <Tab className="ct-tab">Jobs ({totalPages})</Tab>
                  {/* <Tab className="ct-tab">reviews</Tab> */}
                </TabList>
                <div className="content-tab">
                  <TabPanel className="inner-content animation-tab">
                    <h5>About Company</h5>
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          employerProfileDetail?.comp_about ?? "aboutCompany",
                      }}
                    />
                    <h5>Company Video</h5>

                    <div className="video-thumb">
                      <div className="content-tab2">
                        <div className="inner">
                          <div className="thumb">
                            <iframe
                              width="853"
                              height="480"
                              src={employerProfileDetail?.video_url}
                              frameBorder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen
                              title="Embedded youtube"
                            />
                            {/* 
                            <img
                              src={require("../../../assets/images/review/thumbv3.jpg")}
                              alt="images"
                            />
                            <Video /> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <h5>Company Images</h5>
                    <PhotoAlbum
                      layout="rows"
                      photos={photos}
                      targetRowHeight={150}
                      onClick={({ index: current }) => setIndex(current)}
                    />
                    {/* <Gallery /> */}

                    <div className="pt-2 post-navigation d-flex aln-center">
                      <div className="wd-social d-flex aln-center">
                        <ul className="list-social d-flex aln-center">
                          <h5>Social Profiles:</h5>
                          <br />
                          <li>
                            <Link
                              target="_blank"
                              to={employerProfileDetail?.social_link_fb}
                            >
                              <i className="icon-facebook"></i>
                            </Link>
                          </li>
                          <li>
                            <Link to={employerProfileDetail?.social_link_in}>
                              <i className="icon-linkedin2"></i>
                            </Link>
                          </li>
                          <li>
                            <Link to={employerProfileDetail?.social_link_tw}>
                              <i className="icon-twitter"></i>
                            </Link>
                          </li>
                          <li>
                            <Link to={employerProfileDetail?.social_link_ins}>
                              <i className="icon-instagram1"></i>
                            </Link>
                          </li>
                          <li>
                            <Link to={employerProfileDetail?.social_link_you}>
                              <i className="icon-youtube"></i>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel className="inner-content animation-tab">
                    <Tabs className="col-lg-12 tf-tab ctn2">
                      <div className="wd-meta-select-job">
                        <div className="wd-findjob-filer">
                          <div className="group-select-display">
                            <TabList className="inner menu-tab">
                              <Tab className="btn-display">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="17"
                                  height="16"
                                  viewBox="0 0 17 16"
                                  fill="none"
                                >
                                  <path
                                    d="M0.5 12.001L0.5 16.0005C0.880952 16.001 1.09693 16.001 1.83333 16.001L15.1667 16.001C15.9031 16.001 16.5 16.0005 16.5 16.0005L16.5 12.001C16.5 12.001 15.9031 12.001 15.1667 12.001L1.83333 12.001C1.09693 12.001 0.880952 12.001 0.5 12.001Z"
                                    fill="#A0A0A0"
                                  />
                                  <path
                                    d="M0.5 6.00098L0.5 10.0005C0.880952 10.001 1.09693 10.001 1.83333 10.001L15.1667 10.001C15.9031 10.001 16.5 10.0005 16.5 10.0005L16.5 6.00098C16.5 6.00098 15.9031 6.00098 15.1667 6.00098L1.83333 6.00098C1.09693 6.00098 0.880952 6.00098 0.5 6.00098Z"
                                    fill="#A0A0A0"
                                  />
                                  <path
                                    d="M0.5 0.000976562L0.5 4.0005C0.880952 4.00098 1.09693 4.00098 1.83333 4.00098L15.1667 4.00098C15.9031 4.00098 16.5 4.0005 16.5 4.0005L16.5 0.000975863C16.5 0.000975863 15.9031 0.000975889 15.1667 0.000975921L1.83333 0.000976504C1.09693 0.000976536 0.880952 0.000976546 0.5 0.000976562Z"
                                    fill="#A0A0A0"
                                  />
                                </svg>
                              </Tab>
                              <Tab className="btn-display">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="17"
                                  height="16"
                                  viewBox="0 0 17 16"
                                  fill="none"
                                >
                                  <path
                                    d="M4.5 0H0.500478C0.5 0.380952 0.5 0.596931 0.5 1.33333V14.6667C0.5 15.4031 0.500478 16 0.500478 16H4.5C4.5 16 4.5 15.4031 4.5 14.6667V1.33333C4.5 0.596931 4.5 0.380952 4.5 0Z"
                                    fill="white"
                                  />
                                  <path
                                    d="M10.5 0H6.50048C6.5 0.380952 6.5 0.596931 6.5 1.33333V14.6667C6.5 15.4031 6.50048 16 6.50048 16H10.5C10.5 16 10.5 15.4031 10.5 14.6667V1.33333C10.5 0.596931 10.5 0.380952 10.5 0Z"
                                    fill="white"
                                  />
                                  <path
                                    d="M16.5 0H12.5005C12.5 0.380952 12.5 0.596931 12.5 1.33333V14.6667C12.5 15.4031 12.5005 16 12.5005 16H16.5C16.5 16 16.5 15.4031 16.5 14.6667V1.33333C16.5 0.596931 16.5 0.380952 16.5 0Z"
                                    fill="white"
                                  />
                                </svg>
                              </Tab>
                            </TabList>
                            <p className="nofi-job">
                              <span>{totalPages}</span> Total Job Posting
                            </p>
                          </div>
                          <SortBuy
                            mountSetPerPage={setPerPageFil}
                            mountSetSortList={setPerSortList}
                            prop={[
                              { value: "12", label: "12 Per Page" },
                              { value: "1", label: "1 Per Page" },
                              { value: "10", label: "10 Per Page" },
                              { value: "8", label: "8 Per Page" },
                            ]}
                            props={[
                              { value: "", label: "Sort by (Default)" },
                              { value: "asc", label: "Asc" },
                              { value: "desc", label: "Desc" },
                            ]}
                          />
                        </div>
                      </div>
                      <div className="content-tab no-scroll">
                        {loading ? (
                          <div
                            className="text-center"
                            style={{
                              display: "grid",
                              placeItems: "center",
                              height: "100vh",
                            }}
                          >
                            <LoaderMount.CuLoader />
                          </div>
                        ) : (
                          <>
                            {jobManagePostingList.length != 0 ? (
                              <>
                                <TabPanel className="inner">
                                  {jobManagePostingList.map((idx) => (
                                    <div
                                      key={idx.id}
                                      className="features-job candidate-layout wd-thum-career"
                                      // onClick={() => {
                                      // navigate();

                                      // window.location.href =
                                      //   "employer#/manage-job-candidate-list";
                                      // }}
                                    >
                                      {/* <p>
                                        {" "}
                                        id {idx.id} status {idx.status}
                                      </p> */}
                                      <div className="job-archive-header">
                                        <div className="career-header-left">
                                          {/* <img
                          src={`${process.env.REACT_APP_ROOT_URL}${idx.img} `}
                          alt="jobtex"
                          className="thumb"
                        /> */}
                                          <div className="career-left-inner">
                                            {/* <h4>
                            <Link>{idx.cate}</Link>
                          </h4> */}
                                            <h3>
                                              <Link>{idx.title}</Link>&nbsp;
                                              {/* {idx.status !== 0 && (
                                      <span className="icon-bolt"></span>
                                    )} */}
                                              <br />
                                              <small>{idx.role}</small>
                                              <h6>
                                                Total Applied Candidate :{" "}
                                                {idx.total_applied}
                                              </h6>
                                              <h6>
                                                Shortlisted:{" "}
                                                {setShortlisted ?? 0}
                                              </h6>
                                              <small>
                                                {/* {idx.status == 1 ? ( */}
                                                <small>
                                                  Status:{" "}
                                                  <b style={{ color: "green" }}>
                                                    Active Job
                                                  </b>
                                                </small>
                                                {/* ) : null}  */}
                                              </small>
                                            </h3>
                                            {/* 
                          <h3>
                            <Link to="/Candidatesingle_v1">{idx.name}</Link>
                            &nbsp;
                            <span className="icon-bolt"></span>
                          </h3> */}
                                            {/* <ul className="career-info">
                            <li>
                              {" "}
                              <b>{idx.total_applied}</b>
                            </li>
                            <li>
                              <span className="icon-map-pin"></span>
                              {idx.map}
                            </li> 
                          </ul> */}
                                          </div>
                                        </div>

                                        <div className="career-header-right">
                                          {idx.id} {idx.encryptId}
                                          <ButtonToolbar>
                                            {/* <IconButton
                            style={{ color: "green" }}
                            icon={<AddOutlineIcon />}
                          >
                            Repost
                          </IconButton> */}
                                            <IconButton
                                              onClick={() => {
                                                // sessionStorage.removeItem(
                                                //   "formValue"
                                                // );
                                                navigate(
                                                  "/manage-job-post-edit",
                                                  {
                                                    state: {
                                                      jobPostingDetails: idx,
                                                      privateID: idx.id,
                                                    },
                                                  }
                                                );

                                                // <ManageJobPostEdit prop={"d"} />;
                                                // const formValue = {
                                                //   jobTitle: idx.title || "",
                                                //   jobType: idx.employment_type,
                                                //   keySkills: idx.key_skill,
                                                //   departmentCategory:
                                                //     idx.departmentCategory || "",
                                                //   role: idx.role || "",
                                                //   workMode: idx.work_mode || "", // Map from work_mode
                                                //   jobLocation: idx.job_location || "", // Map from job_location
                                                //   baseLocationRemote:
                                                //     idx.baseLocationRemote || "",
                                                //   hybridPolicy: idx.hybridPolicy || "",
                                                //   minYear: idx.min_exp || "", // Map from min_exp
                                                //   maxYear: idx.max_exp || "", // Map from max_exp
                                                //   salaryType: idx.sal_pay || "", // Map from sal_pay
                                                //   salaryProType: idx.salaryProType || "",
                                                //   minSalary: idx.sal_min || 0, // Map from sal_min
                                                //   maxSalary: idx.sal_max || 0, // Map from sal_max
                                                //   checkPercentage: !!idx.check_percentage, // Convert to boolean
                                                //   percentage:
                                                //     parseFloat(idx.percentage) || 0,
                                                //   variableSalaryType: idx.sal_symb || "₹", // Map from sal_symb
                                                //   variableMinSalary:
                                                //     parseFloat(
                                                //       idx.temp_min_salary_value
                                                //     ) || 0,
                                                //   variableMaxSalary:
                                                //     parseFloat(
                                                //       idx.temp_max_salary_value
                                                //     ) || 0,
                                                //   companyIndustries:
                                                //     idx.company_industry || "", // Map from company_industry
                                                //   hideSalary: !!idx.sal_hide, // Convert to boolean
                                                //   educations: Array.isArray(
                                                //     idx.education_list
                                                //   )
                                                //     ? idx.education_list
                                                //     : [],
                                                //   candidateIndustries: Array.isArray(
                                                //     idx.candidate_industries
                                                //   )
                                                //     ? idx.candidate_industries
                                                //     : [],
                                                //   isDiversityHiringChecked:
                                                //     !!idx.is_diversity_hiring_checked, // Convert to boolean
                                                //   jobDescription:
                                                //     idx.summarnote_job_description || "", // Map from summarnote_job_description
                                                //   vacancy: !!idx.vacancy, // Convert to boolean
                                                //   additionalVacancy:
                                                //     idx.additional_vacancy || "",
                                                //   requestCandidateVideoProfile:
                                                //     !!idx.request_candidate_video_profile, // Convert to boolean
                                                //   requestIncludeWalkInDetails:
                                                //     !!idx.request_include_walk_in_details, // Convert to boolean
                                                //   requestIncludeWalkInText:
                                                //     idx.request_include_walk_text || "",
                                                //   addQuestionsList: Array.isArray(
                                                //     idx.question_select
                                                //   )
                                                //     ? idx.question_select
                                                //     : [],
                                                //   companyName: idx.comp_name || "",
                                                //   companyWebsite: idx.comp_website || "",
                                                //   aboutCompany:
                                                //     idx.summarnote_comp_about || "", // Map from summarnote_comp_about
                                                //   isSendMailChecked:
                                                //     !!idx.isSendMailChecked, // Convert to boolean if needed
                                                //   selectedUserEmails: Array.isArray(
                                                //     idx.selectedUserEmails
                                                //   )
                                                //     ? idx.selectedUserEmails
                                                //     : [],
                                                //   notificationValue: Array.isArray(
                                                //     idx.notificationValue
                                                //   )
                                                //     ? idx.notificationValue
                                                //     : [],
                                                //   companyEmail: idx.comp_email || "",
                                                //   companyVideoContent:
                                                //     idx.companyVideoContent || "",
                                                //   companyImageUpload: Array.isArray(
                                                //     idx.companyImageUpload
                                                //   )
                                                //     ? idx.companyImageUpload
                                                //     : [],
                                                //   companySize: Array.isArray(
                                                //     idx.companySize
                                                //   )
                                                //     ? idx.companySize
                                                //     : [],
                                                //   headquarters: idx.headquarters || "",
                                                //   foundedYear: idx.foundedYear || "",
                                                //   socialLinkFB: idx.socialLinkFB || "",
                                                //   socialLinkIn: idx.socialLinkIn || "",
                                                //   socialLinkTw: idx.socialLinkTw || "",
                                                //   socialLinkIns: idx.socialLinkIns || "",
                                                //   socialLinkYou: idx.socialLinkYou || "",
                                                // };

                                                // console.log(formValue);
                                                // sessionStorage.setItem(
                                                //   "formValue",
                                                //   JSON.stringify(formValue)
                                                // );
                                                // // ManageJobPostEdit
                                                // // ManageJobPostEdit(idx);
                                                // // handleDelete(idx.id);
                                                // console.log("SDDD");
                                              }}
                                              style={{ color: "blue" }}
                                              icon={<Edit />}
                                            >
                                              Edit
                                            </IconButton>
                                            <IconButton
                                              // onClick={async () => {
                                              //   const options = {
                                              //     okButtonText: "Yes",
                                              //     cancelButtonText: "Cancel",
                                              //   };
                                              //   const result = await confirm(
                                              //     "Are you sure?",
                                              //     options
                                              //   );

                                              //   if (result) {
                                              //     console.log("You clicked Yes");
                                              //   }
                                              // }}
                                              onClick={() => {
                                                handleDelete(idx.id);
                                              }}
                                              style={{
                                                color: "red",
                                                "align-items": "center",
                                              }}
                                              icon={<Trash />}
                                            >
                                              Delete
                                            </IconButton>
                                          </ButtonToolbar>
                                          <br />
                                          {/* <span className="icon-heart"></span> */}
                                          <div
                                            className="p-2"
                                            onClick={() => {
                                              navigate(
                                                "/manage-job-candidate-list",
                                                {
                                                  state: {
                                                    idx,
                                                  },
                                                }
                                              );
                                            }}
                                          >
                                            <Link className="tf-btn mb-3">
                                              Details
                                            </Link>
                                          </div>
                                          <p>
                                            posted by Me |{" "}
                                            {idx.post_uploaded_date2}{" "}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </TabPanel>
                                <TabPanel className="inner">
                                  <div className="group-col-2">
                                    {jobManagePostingList.map((idx) => (
                                      <div
                                        key={idx.id}
                                        className="features-job wd-thum-career style-2 cl2 mb1"
                                      >
                                        <p>
                                          posted by Me |{" "}
                                          {idx.post_uploaded_date2}{" "}
                                        </p>
                                        <div className="job-archive-header">
                                          <div className="career-header-left">
                                            {/* <img
                            src={`${process.env.REACT_APP_ROOT_URL}${idx.img} `}
                            alt="jobtex"
                            className="thumb"
                          /> */}
                                            <div className="career-left-inner">
                                              {/* <h4>
                              <Link to="#">{idx.unit}</Link>
                            </h4> */}
                                              <h3>
                                                <Link>{idx.title}</Link>&nbsp;
                                                {idx.status !== 0 && (
                                                  <span className="icon-bolt"></span>
                                                )}
                                                <br />
                                                <small>{idx.role}</small>
                                                <h6>
                                                  Total Applied Candidate :{" "}
                                                  {idx.total_applied}
                                                </h6>
                                              </h3>
                                              {/* <ul className="career-info">
                              <li>{idx.cate}</li>
                              <li>
                                <span className="icon-map-pin"></span>
                                {idx.map}
                              </li>
                              <li>
                                <span className="icon-dolar1"></span>
                                {idx.price}$/hour
                              </li>
                            </ul> */}
                                            </div>
                                          </div>
                                          {/* <div className="career-header-right">
                          <span className="icon-heart"></span>
                        </div> */}
                                        </div>
                                        {/* job-archive-footer */}
                                        <div className="row ">
                                          <div className="pb-3">
                                            <ButtonToolbar>
                                              <IconButton
                                                style={{ color: "green" }}
                                                icon={<AddOutlineIcon />}
                                              >
                                                Repost
                                              </IconButton>
                                              <IconButton
                                                style={{ color: "blue" }}
                                                icon={<Edit />}
                                              >
                                                Edit
                                              </IconButton>
                                              <IconButton
                                                style={{ color: "red" }}
                                                icon={<Trash />}
                                              >
                                                Delete
                                              </IconButton>
                                            </ButtonToolbar>
                                          </div>
                                          <br />
                                          <Link to="/" className="tf-btn mb-3">
                                            Details
                                          </Link>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </TabPanel>
                              </>
                            ) : (
                              <LottieControl />
                            )}
                          </>
                        )}
                      </div>
                    </Tabs>

                    <ul className="pagination-job p-top-st1">
                      <RSPaginator
                        prev
                        next
                        first
                        last
                        ellipsis
                        size="lg"
                        boundaryLinks
                        pages={lastPage - 1}
                        activePage={currentPage}
                        onSelect={(e) => {
                          handlePageChange(e);
                        }}
                      />
                    </ul>
                    <RSDivider />
                  </TabPanel>
                  <TabPanel className="inner-content animation-tab">
                    <h5>Full Reviews</h5>
                    <p>
                      Are you a User Experience Designer with a track record of
                      delivering intuitive digital experiences that drive
                      results? Are you a strategic storyteller and systems
                      thinker who can concept and craft smart.
                    </p>
                  </TabPanel>
                </div>
              </Tabs>
            </div>
            <div className="col-lg-4">
              <div className="cv-form-details po-sticky job-sg single-stick">
                <ul className="list-infor">
                  <li>
                    <div className="category">Website</div>
                    <div className="detail">
                      <Link to={employerProfileDetail?.comp_url}>
                        {employerProfileDetail?.comp_url}
                      </Link>
                    </div>
                  </li>
                  <li>
                    <div className="category">Company Name</div>
                    <div className="detail">
                      {employerProfileDetail?.comp_name}
                    </div>
                  </li>
                  <li>
                    <div className="category">Company Primary Email</div>
                    <div className="detail">{employerProfileDetail?.email}</div>
                  </li>
                  <li>
                    <div className="category">Created Date</div>
                    <div className="detail">{employerProfileDetail?.dor}</div>
                  </li>
                  <li>
                    <div className="category">Phone No.</div>
                    <div className="detail">
                      {employerProfileDetail?.country_code}{" "}
                      {employerProfileDetail?.phone_no}
                    </div>
                  </li>

                  <li>
                    <div className="category">Company Primary Address</div>
                    <div className="detail">
                      {" "}
                      {employerProfileDetail?.comp_primary_addr}
                    </div>
                  </li>
                </ul>

                <div className="wd-social d-flex aln-center">
                  <span>Socials:</span>
                  <ul className="list-social d-flex aln-center">
                    <li>
                      {/* employerProfileDetail?.social_link_fb */}
                      <a href="SDF" target="_blank" rel="noopener noreferrer">
                        <i className="icon-facebook"></i>
                      </a>
                    </li>

                    <li>
                      <Link to={employerProfileDetail?.social_link_in}>
                        <i className="icon-linkedin2"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to={employerProfileDetail?.social_link_tw}>
                        <i className="icon-twitter"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to={employerProfileDetail?.social_link_ins}>
                        <i className="icon-instagram1"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to={employerProfileDetail?.social_link_you}>
                        <i className="icon-youtube"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
                {/* <div className="form-job-single">
                  <h6>Contact Us</h6>
                  <form action="post">
                    <input type="text" placeholder="Subject" />
                    <input type="text" placeholder="Name" />
                    <input type="email" placeholder="Email" />
                    <textarea placeholder="Message..."></textarea>
                    <button>Send Message</button>
                  </form>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <Gotop />
    </>
  );
}

export default ProfilePage;
