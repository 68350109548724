import * as Yup from "yup";

const SignInSchema = Yup.object().shape({
  role: Yup.number()
    .required("Role is required")
    .oneOf([1, 2], "Invalid role value. Role must be 1 or 2"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters long"),
  remeberMe: Yup.boolean(),
});

const SignInSchemaEmployer = Yup.object().shape({
  roleE: Yup.number()
    .required("Role is required")
    .oneOf([1, 2], "Invalid role value. Role must be 1 or 2"),
  emailE: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  passwordE: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters long"),
  remeberMeE: Yup.boolean(),
});
const mount = { SignInSchema, SignInSchemaEmployer };
export default mount;
