import React from "react";
import PropTypes from "prop-types";
import Gotop from "../../../../components/gotop";
import { Link, useHistory, useNavigate, useLocation } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import MapSingle from "../../../../components/map/MapSingle";
import lo1 from "../../../../assets/images/logo-company/cty4.png";
import StarProgress from "../../../../components/progressBar/StarProgress";
import { useRef, useState, useEffect } from "react";
import Video from "../../../../components/popup/Video";
import Gallery from "../../../../components/popup/Gallery";
import { sweetAlert } from "../../../../utils/sweetAlert";
import { Collapse } from "react-collapse";
import axios from "../../../../api/axios";
import domain from "../../../../api/constent";
import logo from "../../../../assets/images/logo.png";
import Header4 from "../../../../components/header/Header4";
import {
  Box,
  TextField,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Button,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  Select,
  MenuItem,
  InputLabel,
  Typography,
  Autocomplete,
  Chip,
  Grid,
} from "@mui/material";
import LoaderMount from "../../../../components/preloader";

// import Gallery from "../../components/popup/Gallery";
import {
  List as RSList,
  TagGroup as RSTagGroup,
  Tag as RSTag,
  Button as RSButton,
  Divider,
  Placeholder,
} from "rsuite";
// PostJobPreview.propTypes = {};
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import PhotoAlbum from "react-photo-album";
import Session from "../../../../api/session";
// import axios from "axios";

const getValidUrl = (url, fallback) => {
  if (!url) {
    return "#";
  }
  return url.startsWith("http://") || url.startsWith("https://")
    ? url
    : `https://${url}`;
};
function findEducationLabel(options, value) {
  for (const option of options) {
    if (option.value === value) {
      return option.label;
    }
    if (option.children) {
      const childLabel = findEducationLabel(option.children, value);
      if (childLabel) {
        return childLabel;
      }
    }
  }
  return null;
}

function ManageJobPostEditPreviewSubmit() {
  const location = useLocation();
  const navigate = useNavigate();
  const formData = location.state?.formData;
  const AllList = location.state;
  const jobTypeList = formData?.jobType
    .map((type) => AllList?.employmentTypes.find((emp) => emp.value === type))
    .filter(Boolean)
    .map((emp) => emp.label);

  const getCompLogo =
    process.env.REACT_APP_ROOT_URL +
      "/images/logo/" +
      Session.getDataEmployerSessionStore().businessLogo || "";
  // console.log(getCompLogo);

  const jobKeySkillList = formData?.keySkills
    .map((type) =>
      AllList?.keySkillsList.find((skillMode) => skillMode.value === type)
    )
    .filter(Boolean)
    .map((skillMode) => skillMode.label);
  const jobDepartmentCategory = AllList?.departmentCategoryList.find(
    (category) => category.value === formData?.departmentCategory
  ).label;

  const CompSize = AllList?.companySizeList.find(
    (category) => category.value === formData?.companySize
  ).label;

  const jobRoleCategory = AllList?.roleList.find(
    (category) => category.value === formData?.role
  ).label;
  // console.log(formData?.role, AllList?.roleCategoryList);

  const jobCompanyIndustryType = AllList?.companyIndustriesList.find(
    (category) => category.value === formData?.companyIndustries
  ).label;

  const flattenEducationOptions = (options) => {
    const flatList = [];
    const traverse = (nodes) => {
      nodes.forEach((node) => {
        flatList.push(node);
        if (node.children) traverse(node.children);
      });
    };
    traverse(options);
    return flatList;
  };

  const flatEducationOptions = flattenEducationOptions(
    AllList?.educationOptionsList
  );

  const educationQualification = formData.educations
    .map((type) => flatEducationOptions.find((option) => option.value === type))
    .filter(Boolean)
    .map((option) => option.label);
  const jobMinY = AllList?.workExpList.find(
    (category) => category.value === formData?.minYear
  ).label;
  const jobMaxY = AllList?.workExpList.find(
    (category) => category.value === formData?.maxYear
  ).label;
  const jobMinSalaryList = AllList?.salaryRangeList.find(
    (category) => category.value === formData?.minSalary
  ).label;
  const jobMaxSalaryList = AllList?.salaryRangeList.find(
    (category) => category.value === formData?.maxSalary
  ).label;
  const jobSalaryType = AllList?.salaryTypeList.find(
    (category) => category.value === formData?.salaryType
  ).label;

  const jobSalaryProType = AllList?.salaryProcessTypeList.find(
    (category) => category.value === formData?.salaryProType
  ).label;

  const CompIndustry = AllList?.companyIndustriesList.find(
    (category) => category.value === formData?.companyIndustries
  ).label;
  formData.addQuestionsList = AllList.questionariesList;

  // const tempQuestionsList = AllList?.valueFromSecondPage; //addQuestionsList;
  // formData.addQuestionsList = AllList?.valueFromSecondPage;
  const CompVideoUploaded = formData?.companyVideoContent ?? "";
  const queryParams = new URLSearchParams(CompVideoUploaded);
  const videoUrl = CompVideoUploaded;

  const videoId = videoUrl?.split("v=")[1]?.split("&")[0];
  const photos =
    formData?.companyImageUpload?.map((image) => ({
      src: image.src,
      width: 800,
      height: 600,
    })) || [];
  // formData.addQuestionsList = AllList.questionariesList;

  const [index, setIndex] = useState(-1);

  // console.log(photos);

  const backFn = () => {
    navigate("/post-vacancy");
  };

  useEffect(() => {
    console.log("List", AllList);

    console.log("getDetails ", formData);

    sessionStorage.setItem("formValueEdit", JSON.stringify(formData));
  });

  const handleJobPostSubmit = async () => {
    try {
      // console.log(` point : ${typeof formData.workMode.toString()}`);
      // console.log(`location point : ${typeof formData.jobLocation.toString()}`);
      console.log("dummy : ", AllList.questionariesList);

      console.log(
        "Questionarys : ",
        formData.jobTitle,
        formData.addQuestionsList
      );

      const StoreArea = {
        private_id: formData.privateLoadID,
        employer_id: Session.getDataEmployerSessionStore().id,
        job_title: formData.jobTitle,
        job_type: formData.jobType,
        key_skills: formData.keySkills,
        department_category: formData.departmentCategory,
        role: formData.role,
        work_mode: formData.workMode.toString(),
        job_location: formData.jobLocation, //formData.jobLocationList,
        base_location_remote: formData.baseLocationRemote,
        hybrid_policy: formData.hybridPolicy.toString(),
        min_year: formData.minSalary,
        max_year: formData.maxSalary,
        salary_type: formData.salaryType,
        salary_process_type: formData.salaryProType,
        min_salary: formData.minSalary,
        max_salary: formData.maxSalary,
        check_percentage: formData.checkPercentage,
        percentage: formData.percentage,
        variable_salary_type: formData.variableSalaryType,
        variable_min_salary: formData.variableMinSalary,
        variable_max_salary: formData.variableMaxSalary,
        company_industries: formData.companyIndustries,
        hide_salary: formData.hideSalary,
        education_qualification: formData.educations,
        candidate_industries: formData.candidateIndustries,
        is_diversity_hiring_checked: formData.isDiversityHiringChecked,
        job_description: formData.jobDescription,
        vacancy: formData.vacancy,
        additional_vacancy: formData.additionalVacancy,
        request_candidate_video_profile: formData.requestCandidateVideoProfile,
        request_include_walk_in_details: formData.requestIncludeWalkInDetails,
        request_include_walk_in_text: formData.requestIncludeWalkInText,
        add_questions_list: JSON.stringify(formData.addQuestionsList),
        company_name: formData.companyName,
        company_website: formData.companyWebsite,
        about_company: formData.aboutCompany,
        is_send_mail_checked: formData.isSendMailChecked,
        selected_user_emails: formData.selectedUserEmails ?? [],
        notification_value: formData.notificationFrequency,
        company_email: formData.companyEmail,
        company_video_content: formData.companyVideoContent,
        company_image_upload: formData.companyImageUpload.map(
          (image) => image.file
        ),
        company_size: formData.companySize ?? [], //issue required
        social_link_fb: formData.socialLinkFB,
        social_link_in: formData.socialLinkIn,
        social_link_tw: formData.socialLinkTw,
        social_link_ins: formData.socialLinkIns,
        social_link_you: formData.socialLinkYou,
      };
      console.log(StoreArea);

      const response = await axios.post(
        `${domain.Employer_Job_Posting_Update}`,
        StoreArea,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      // // Handle the response
      if (response.data.success) {
        sweetAlert(
          "Success!",
          "Your job Post has been successfully Updated Post.",
          1
        );
      } else {
        sweetAlert("Failed!", "Your job post has not been posted.", 2);

        alert("Job posting failed.");
      }
    } catch (error) {
      console.error(
        "Error submitting job:",
        error.response?.data || error.message
      );
      sweetAlert("Failed!", error.message, 2);
    }
  };

  return (
    <>
      <section className="form-sticky fixed-space">
        <div className="tf-container">
          <div className="row">
            <div className="col-lg-12">
              <div className="wd-job-author2">
                <div className="content-left">
                  <div className="thumb">
                    <img src={getCompLogo} alt="logo" />
                  </div>
                  <div className="content">
                    <Link to="#" className="category">
                      {formData?.companyName ?? "Company Name"}
                    </Link>
                    <h6>
                      <Link to="#">
                        {formData?.jobTitle ?? "job Title"}

                        <span className="icon-bolt"></span>
                      </Link>
                    </h6>
                    <ul className="job-info">
                      <li>
                        <span className="icon-map-pin"></span>
                        {formData?.headquarters ?? "Headquarters "}
                      </li>
                      <li>
                        <span className="icon-calendar"></span>
                        <span>2 days ago</span>
                      </li>
                    </ul>

                    <ul className="tags">
                      {jobTypeList.length > 0 ? (
                        jobTypeList.map((label, index) => (
                          <li key={index}>
                            <Link to="#">{label}</Link>
                          </li>
                        ))
                      ) : (
                        <li>
                          <Link to="#">No Employment available</Link>
                        </li>
                      )}
                    </ul>
                    <ul className="tags pt-2">
                      {jobKeySkillList.length > 0 && jobKeySkillList != [] ? (
                        jobKeySkillList.map((label, index) => (
                          <li key={index}>
                            <Link to="#">{label}</Link>
                          </li>
                        ))
                      ) : (
                        <li>
                          <Link to="#">No Skills available</Link>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
                <div className="content-right">
                  <div className="top">
                    <Link to="#" className="share">
                      <i className="icon-share2" />
                    </Link>
                    <Link to="#" className="wishlist">
                      <i className="icon-heart" />
                    </Link>
                    {/* btn-popup */}
                    <Button className="btn ">
                      <i className="icon-send" />
                      Apply Now
                    </Button>
                  </div>
                  <div className="bottom">
                    <div className="gr-rating">
                      <p>32 days left to apply</p>
                      <ul className="list-star">
                        <li className="icon-star-full" />
                        <li className="icon-star-full" />
                        <li className="icon-star-full" />
                        <li className="icon-star-full" />
                        <li className="icon-star-full" />
                      </ul>
                    </div>
                    <div className="price">
                      <p>
                        {jobMinY ?? "Job minYear"}&nbsp;min&nbsp;-&nbsp;
                        {jobMaxY ?? "Job maxYear"}&nbsp;max
                        <span className="experience">&nbsp;Experience</span>
                      </p>
                    </div>
                    <div className="price">
                      <p>
                        {jobSalaryType ?? "Job salaryType"}&nbsp;
                        {jobMinSalaryList ?? "Job minSalary"}{" "}
                        &nbsp;&nbsp;-&nbsp;&nbsp;
                        {jobMaxSalaryList ?? "Job maxSalary"}
                        <span className="year">
                          {" "}
                          {"/" + jobSalaryProType ?? "/Job salaryProType"}
                        </span>
                      </p>
                    </div>
                    {formData?.checkPercentage ? (
                      <>
                        <div className="price">
                          <small sx={{ fontSize: "12px" }}>
                            {/* formData?.variableSalaryType */}
                            Fixed Percentage %{" "}
                            {formData?.percentage ?? "Job fixedPercentage"}{" "}
                            &nbsp;
                            {formData?.variableMinSalary ??
                              "Job minSalary"}{" "}
                            &nbsp;-&nbsp;
                            {formData?.variableMaxSalary ?? "Job maxSalary"}
                          </small>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="inner-jobs-section">
        <div className="tf-container">
          <div className="row">
            <div className="col-lg-8">
              {formData?.jobDescription !== "" && (
                <>
                  <h4>Job description</h4>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: formData?.jobDescription ?? "Job salaryType",
                    }}
                  />
                </>
              )}
              <RSList style={{ paddingTop: "15px" }}>
                {/* <RSList.Item><b>Role:  </b>&nbsp;{jobRole ?? "Role"}</RSList.Item> */}
                <RSList.Item>
                  <b>Industry Type:</b>&nbsp;
                  {jobCompanyIndustryType ?? "Industry Type"}
                  {/* {jobIndustryType.length > 0 ? (
                                        jobIndustryType.map((label, index) => (
                                            <li key={index}>
                                                <Link to="#">{label}</Link>
                                            </li>
                                        ))
                                    ) : (
                                        <li>
                                            <Link to="#">No Skills available</Link>
                                        </li>
                                    )} */}
                  {/* { ?? "Industry Type"} */}
                </RSList.Item>
                <RSList.Item>
                  <b>Department:</b> &nbsp;
                  {jobDepartmentCategory ?? "Department Category"}{" "}
                </RSList.Item>
                <RSList.Item>
                  <b>Role :</b>&nbsp;{jobRoleCategory ?? "Role Category"}
                </RSList.Item>
                <RSList.Item>
                  <b>Employment Type:</b>&nbsp;{jobTypeList ?? "jobType"}
                </RSList.Item>
              </RSList>

              <RSList style={{ paddingTop: "15px" }}>
                <RSList.Item>
                  <h6>
                    <b>Eduction</b>
                  </h6>
                  <RSTagGroup className="px-3 pt-3">
                    {/* <RSTag size="md" color="blue"> {educationQualification ?? "Education Qualification"}</RSTag> */}

                    {educationQualification.length > 0 &&
                    educationQualification != [] ? (
                      educationQualification.map((label, index) => (
                        <RSTag key={index} size="md" color="blue">
                          {" "}
                          {label ?? "Education Qualification"}
                        </RSTag>
                      ))
                    ) : (
                      <li>
                        <Link to="#">
                          No Education & Qualification available
                        </Link>
                      </li>
                    )}
                  </RSTagGroup>
                </RSList.Item>
                <RSList.Item>
                  <h6>
                    <b>Key Skills</b>
                  </h6>
                  <RSTagGroup>
                    {jobKeySkillList.length > 0 && jobKeySkillList != [] ? (
                      jobKeySkillList.map((label, index) => (
                        <RSTag key={index} size="md" color="blue">
                          {" "}
                          {label ?? "Education Qualification"}
                        </RSTag>
                      ))
                    ) : (
                      <li>
                        <Link to="#">No Skills available</Link>
                      </li>
                    )}
                  </RSTagGroup>
                </RSList.Item>

                {/* <RSList.Item>
                                    <h6><b>Key Industries</b></h6>
                                    <RSTagGroup>
                                        {formData?.candidateIndustries?.map((skill, index) => (
                                            <RSTag key={index} size="md" color="blue">{skill}</RSTag>

                                        )) || (
                                                <Link to="#">No skills available</Link>
                                            )}
                                    </RSTagGroup>
                                </RSList.Item> */}
              </RSList>

              {formData?.companyName !== "" && (
                <>
                  <h4>About Company</h4>
                  <p>{formData?.companyName ?? "companyName"}</p>

                  <div className="video-thumb">
                    <div className="content-tab2">
                      {videoId ? (
                        <>
                          <iframe
                            width="800"
                            height="320"
                            src={`https://www.youtube.com/embed/${videoId}`}
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerPolicy="strict-origin-when-cross-origin"
                            allowFullScreen
                          ></iframe>

                          {/* </div> */}
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                    <PhotoAlbum
                      layout="rows"
                      photos={photos}
                      targetRowHeight={150}
                      onClick={({ index: current }) => setIndex(current)}
                    />

                    <Lightbox
                      index={index}
                      slides={photos.map(({ src }) => ({ src }))}
                      open={index >= 0}
                      close={() => setIndex(-1)}
                    />
                  </div>

                  <h4>Company Information</h4>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: formData?.aboutCompany ?? "aboutCompany",
                    }}
                  />
                </>
              )}
            </div>
            <div className="col-lg-4">
              <div className="cv-form-details po-sticky job-sg single-stick">
                {/* <MapSingle marKers={marKers} /> */}
                <ul className="list-infor">
                  <li>
                    <div className="category">Company</div>
                    <h4>
                      <b> {formData?.companyName ?? "Company Name"}</b>
                    </h4>
                  </li>

                  <li>
                    <div className="category">Email</div>
                    <div className="detail">
                      {formData?.companyEmail ?? "abc@info.com"}
                    </div>
                  </li>
                  <li>
                    <div className="category">Industry</div>
                    <div className="detail">
                      {CompIndustry ?? "Industry Type"}
                    </div>
                  </li>
                  <li>
                    <div className="category">Company size</div>
                    <div className="detail">{CompSize ?? "Company Size"}</div>
                  </li>
                  <li>
                    <div className="category">Website</div>
                    <div className="detail">
                      <a
                        href={
                          formData?.companyWebsite ?? "https://jobworld.com.au/"
                        }
                      >
                        {formData?.companyWebsite ?? "https://jobworld.com.au/"}{" "}
                        {/* / Themesflat.vn */}
                      </a>
                    </div>
                  </li>
                  {/* <li>
                                        <div className="category">Headquarters</div>
                                        <div className="detail">{formData?.headquarters ?? "Headquarters "}</div>
                                    </li>
                                    <li>
                                        <div className="category">Founded</div>
                                        <div className="detail">{formData?.foundedYear ?? "Founded Year "}</div>
                                    </li> */}
                </ul>

                <div className="wd-social d-flex aln-center">
                  <span>Socials:</span>
                  <ul className="list-social d-flex aln-center">
                    {formData?.socialLinkFB && (
                      <li>
                        <Link
                          to={getValidUrl(
                            formData.socialLinkFB,
                            "socialLinkFB"
                          )}
                        >
                          <i className="icon-facebook"></i>
                        </Link>
                      </li>
                    )}
                    {formData?.socialLinkIn && (
                      <li>
                        <Link
                          to={getValidUrl(
                            formData.socialLinkIn,
                            "socialLinkIn"
                          )}
                        >
                          <i className="icon-linkedin2"></i>
                        </Link>
                      </li>
                    )}
                    {formData?.socialLinkTw && (
                      <li>
                        <Link
                          to={getValidUrl(
                            formData.socialLinkTw,
                            "socialLinkTw"
                          )}
                        >
                          <i className="icon-twitter"></i>
                        </Link>
                      </li>
                    )}
                    {/* Uncomment if needed */}
                    {/* 
                                            {formData?.socialLinkPin && (
                                                <li>
                                                    <Link to={getValidUrl(formData.socialLinkPin, "socialLinkPin")}>
                                                        <i className="icon-pinterest"></i>
                                                    </Link>
                                                </li>
                                            )} 
                                            */}
                    {formData?.socialLinkIns && (
                      <li>
                        <Link
                          to={getValidUrl(
                            formData.socialLinkIns,
                            "socialLinkIns"
                          )}
                        >
                          <i className="icon-instagram1"></i>
                        </Link>
                      </li>
                    )}
                    {formData?.socialLinkYou && (
                      <li>
                        <Link
                          to={getValidUrl(
                            formData.socialLinkYou,
                            "socialLinkYou"
                          )}
                        >
                          <i className="icon-youtube"></i>
                        </Link>
                      </li>
                    )}
                  </ul>
                </div>
                <div className="form-job-single">
                  <h6>Contact Us</h6>
                  <form action="post">
                    <input type="text" placeholder="Subject" />
                    <input type="text" placeholder="Name" />
                    <input type="email" placeholder="Email" />
                    <textarea placeholder="Message..."></textarea>
                    <button>Send Message</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Divider />

      <section className="inner-jobs-section">
        <div className="tf-container">
          <Box
            sx={{
              display: "flex",
              backgroundColor: "",
              justifyContent: "space-between",
              mt: 2,
            }}
          >
            <RSButton
              size="lg"
              variant="contained"
              color="secondary"
              onClick={backFn}
            >
              Back to Edit
            </RSButton>
            <RSButton
              size="lg"
              variant="contained"
              color="primary"
              onClick={handleJobPostSubmit}
            >
              Post Job
            </RSButton>
          </Box>
        </div>
      </section>

      <Gotop />
    </>
  );
}

export default ManageJobPostEditPreviewSubmit;
