import domain from "./constent";
import Session from "./session";

import axios from "./axios";
import DosAxios from "../api/axios";
// import Session from "../api/session";
import { sweetAlert } from "../utils/sweetAlert";

// Profile Details
const fetchPersonalDetails = async () => {
  try {
    const response = await axios.post(domain.Personal_Details);
    if (response.data && response.data.data) {
      return response.data.data;
    }
    throw new Error("No data found");
  } catch (error) {
    console.error("Error fetching personal details:", error);
    throw error;
  }
};
const updatePersonalDetails = async (getJson) => {
  try {
    const userId = Session.getDataSessionStore().id;
    const resp = await axios.post(
      `${domain.Update_Personal_Details}/${userId}`,
      getJson
    );
    if (resp.data && resp.data) {
      return resp.data;
    }
    throw new Error("No data found");
  } catch (error) {
    console.error("Error fetching personal details:", error);
    throw error;
  }
};
const candidateAdditionalDetails = async (formData) => {
  try {
    const response = await axios.post(
      domain.Candidate_Additional_Details,
      formData
    );

    // sweetAlert(
    //   "Success!",
    //   "You Candidate Additional Details has been Updated successfully.",
    //   1
    // ); // Success
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    // sweetAlert("error!", error, 2); // Success

    throw error;
  }
};
// Profile Role
const getDetailsRoleList = async () => {
  const userId = Session.getDataSessionStore().id;
  try {
    const response = await axios.get(`${domain.Career_History_List}${userId}`);
    return response.data.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
const getDetailsRoleCreate = async (formData) => {
  // try {
  const storeValue = {
    job_title: formData.job_title,
    company_name: formData.company_name,
    start_month: formData.start_month,
    start_year: formData.start_year,
    end_month: formData.Education_List,
    end_year: formData.end_year,
    is_current: formData.is_current,
    description: formData.description,
    total_experience: parseFloat(formData.total_experience),
  };

  //   const response = await axios.post(domain.Career_History_Create, formData);
  //   // getDetailsRoleList();
  //

  //   sweetAlert("Success!", "You Role has been created successfully.", 1); // Success
  //   return response.data;
  // } catch (error) {
  //   console.error("Error fetching data:", error);
  //   sweetAlert("error!", error, 2); // Success

  //   throw error;
  // }
};

const getDetailsRoleUpdate = async (getFindID, formData) => {
  try {
    const response = await axios.post(
      `${domain.Career_History_Edit}${getFindID}`,
      formData
    );
    sweetAlert("Success!", "You Role has been Update successfully.", 1); // Success
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    sweetAlert("error!", error, 2); // Success

    throw error;
  }
};

const getDetailsEducationList = async () => {
  try {
    const userId = Session.getDataSessionStore().id;
    const response = await axios.get(`${domain.Education_List}${userId}`);
    return response.data.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
const getDetailsLicensesList = async () => {
  try {
    const userId = Session.getDataSessionStore().id;
    const response = await axios.get(`${domain.Licenses_List}${userId}`);
    return response.data.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
const getDetailsSkillList = async () => {
  try {
    const userId = Session.getDataSessionStore().id;
    const response = await axios.get(
      `${domain.Candidate_Skills_List}${userId}`
    );

    return response.data.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

const getSkillList = async () => {
  // try {
  //   const response = await axios.get(`${domain.all_Skill_list}`);
  //   return response.data.data;
  // } catch (error) {
  //   console.error("Error fetching data:", error);
  //   throw error;
  // }
};

const getLanguagesList = async () => {
  try {
    const response = await axios.get(`${domain.all_Languages_List}`);
    return response.data.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
// Employer Side
// Suggestion List ALL
// Question Suggestion List
const getQuestionSugggstionList = async () => {
  try {
    // const userId = Session.getDataSessionStore().id;
    // ${userId}
    const response = await DosAxios.get(
      `${domain.Employer_Suggestion_QuestionsList}`
    );
    // sweetAlert("Error!", "No token found. Please log in again.", 2);
    return response.data.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
// Questions list on Running
const getQuestionsList = async () => {
  try {
    // const userId = Session.getDataSessionStore().id;
    // ${userId}
    const response = await DosAxios.get(`${domain.Employer_QuestionsList}`);
    // console.log(response.data.data);
    // sweetAlert("Error!", "No token found. Please log in again.", 2);
    return response.data.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
const getAddQuestions = async (DataCollect) => {
  try {
    // const userId = Session.getDataSessionStore().id;
    const response = await DosAxios.post(
      `${domain.Employer_QuestionsStore}`,
      DataCollect
    );
    // sweetAlert("Error!", "No token found. Please log in again.", 2);
    return response.data.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
async function callLogout() {
  try {
    // Include the Authorization token in the headers
    const token = localStorage.getItem("token");

    if (!token) {
      sweetAlert("Error!", "No token found. Please log in again.", 2);
      throw new Error("No token found");
    }
    await Session.getCSRF();
    const res = await DosAxios.post(`${domain.Logout_URL}`);

    // Success response
    sweetAlert("Success!", res.data.message, 1);
    sessionStorage.clear();
    localStorage.clear();
    // Clear cookies manually after logout

    document.cookie =
      "XSRF-TOKEN=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";

    // Delete laravel_session cookie
    document.cookie =
      "laravel_session=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
    window.location.replace("/");

    return res;
  } catch (err) {
    console.error("Error occurred:", err);

    // Check if error is due to token expiry
    if (err.response && err.response.status === 401) {
      sweetAlert("Error!", "Session expired. Please log in again.", 2);
    } else {
      sweetAlert("Error!", "An unexpected error occurred.", 2);
    }
    throw err;
  }
}

const GetEmploymentType = async () => {
  try {
    const response = await DosAxios.get(`${domain.Get_Employment_Type_List}`);
    return response.data.data || [];
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

const GetKeySkillsList = async () => {
  try {
    const response = await DosAxios.get(`${domain.Get_Skills_List}`);

    return response.data.data || [];
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

const GetDepartmentList = async () => {
  try {
    const response = await DosAxios.get(`${domain.Get_Department_List}`);
    return response.data.data || [];
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
const GetRoleList = async (DepartmentID) => {
  try {
    // console.log(`Depart ID: ${DepartmentID}`);
    const response = await DosAxios.get(
      `${domain.Get_Role_List}${DepartmentID}`
    );
    return response.data.data || [];
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
const GetWorkModeList = async () => {
  try {
    const response = await DosAxios.get(`${domain.Get_Working_List}`);
    return response.data.data || [];
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
const GetJobLocationList = async () => {
  try {
    const response = await DosAxios.get(
      `${domain.Job_Select_Where_Place_List}`
      // ,
      // {
      //   responseType: "json", // Can also use 'stream' for large payloads
      //   timeout: 10000,
      // }
    );
    return response.data.data || [];
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
const GetWorkExpList = async () => {
  try {
    const response = await DosAxios.get(`${domain.Get_Work_Exp_List}`);
    return response.data.data || [];
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
const GetSalaryTypeList = async () => {
  try {
    const response = await DosAxios.get(`${domain.Get_Salary_Type_List}`);
    return response.data.data || [];
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
const GetSalaryProcessTypeList = async () => {
  try {
    const response = await DosAxios.get(
      `${domain.Get_Salary_Process_Type_List}`
    );
    return response.data.data || [];
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
const GetSalaryRangeList = async () => {
  try {
    const response = await DosAxios.get(`${domain.Get_Salary_Range_List}`);
    return response.data.data || [];
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

const GetCompanyIndustryList = async () => {
  try {
    const response = await DosAxios.get(`${domain.Get_Company_Industry_List}`);
    return response.data.data || [];
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

const GetEducationQualificationList = async () => {
  try {
    const response = await DosAxios.get(
      `${domain.Get_Education_Qualification_List}`
    );
    return response.data.data || [];
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
const GetCandidateIndustryList = async () => {
  try {
    const response = await DosAxios.get(
      `${domain.Get_Candidate_Industry_List}`
    );
    return response.data.data || [];
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
const GetNotificationList = async () => {
  try {
    const response = await DosAxios.get(`${domain.Get_Notification_Type_List}`);
    return response.data.data || [];
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
const GetCompanySizeList = async () => {
  try {
    const response = await DosAxios.get(`${domain.Get_Company_Size_List}`);
    return response.data.data || [];
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
const GetEmailSubUserList = async () => {
  try {
    const response = await DosAxios.get(`${domain.Get_Company_Sub_User_List}`);
    return response.data.data || [];
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

// Open filters using

const OpenEmploymentType = async () => {
  try {
    const response = await axios.get(`${domain.Open_Employment_Type_List}`);
    return response.data.data || [];
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

const OpenSalaryTypeList = async () => {
  try {
    const response = await axios.get(`${domain.Open_Salary_Type_List}`);
    return response.data.data || [];
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
const OpenSalaryProcessTypeList = async () => {
  try {
    const response = await axios.get(`${domain.Open_Salary_Process_Type_List}`);
    return response.data.data || [];
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
const OpenSalaryRangeList = async () => {
  try {
    const response = await axios.get(`${domain.Open_Salary_Range_List}`);
    return response.data.data || [];
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
const OpenListingAnyTimeList = async () => {
  try {
    const response = await axios.get(`${domain.Open_Listing_MyTime_List}`);
    return response.data.data || [];
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

const getJobPostingList = async (paramsData) => {
  try {
    const response = await axios.get(domain.Job_Posting_List_URL, {
      params: paramsData,
    });

    return response;
  } catch (err) {
    // const msg = err?.response?.error.message ?? err?.message ?? "Unknown Error";

    console.error(err);

    return false;
  }
};
// Employer ManageJob

const getEManageJobList = async (paramsData) => {
  try {
    const response = await axios.get(domain.Employer_ManageJobAndResponse, {
      params: paramsData,
    });

    return response;
  } catch (err) {
    // const msg = err?.response?.error.message ?? err?.message ?? "Unknown Error";

    console.log(err);
    // throw err;
    // return false;
  }
};
const getEAppliedJobCandidateList = async (paramsData) => {
  try {
    const response = await axios.get(domain.Employer_ManageJobCandidateList, {
      params: paramsData,
    });

    return response;
  } catch (err) {
    // const msg = err?.response?.error.message ?? err?.message ?? "Unknown Error";

    console.error(err);

    // return false;
  }
};

const getEManageJobDelete = async (formData) => {
  try {
    const response = await axios.post(
      `${domain.Employer_ManageJobDeleteList}`,
      formData
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    console.log("error!", error, 2); // Success

    throw error;
    // return false;
  }
};
// PDF download API directly
const getEManageJobFileDownload = async (formData) => {
  try {
    const response = await DosAxios.get(
      `${domain.Employer_ManageJobCandidateFileDownload}`,
      formData,
      {
        "Content-Type": "application/pdf",
        // responseType: "blob", // Important: ensures the response is treated as a file
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    // console.log("error!", error, 2); // Success

    throw error;
    // return false;
  }
};

const getEManageJobCandidateUpdateStatus = async (paramsData) => {
  try {
    const response = await axios.get(
      `${domain.Employer_JobCandateStatusUpdateList}`,
      { params: paramsData }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
const getCandidateOTPSend = async (formData) => {
  try {
    const response = await axios.post(`${domain.CandidateOTPSend}`, formData);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    console.log("error!", error, 2); // Success

    throw error;
  }
};

const getCandidateVerificationOTP = async (formData) => {
  try {
    const response = await axios.post(
      `${domain.CandidateVerificationOTP}`,
      formData
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    console.log("error!", error, 2); // Success

    throw error;
  }
};
const getEmployerSendOTP = async (formData) => {
  try {
    const response = await axios.post(`${domain.EmployerOTPSend}`, formData);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    console.log("error!", error, 2); // Success

    throw error;
    // return false;
  }
};

const getEmployerVerificationOTP = async (formData) => {
  try {
    const response = await axios.post(
      `${domain.EmployerVeficaitonOTP}`,
      formData
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    console.log("error!", error, 2); // Success

    throw error;
    // return false;
  }
};
const getEmployerProfileDetails = async () => {
  const response = await axios.get(
    `${domain.EmployerProfileDetails}${localStorage.getItem("node")}`
  );
  return response.data;
};

const getEmployerProfileDetailsUpdate = async (formData) => {
  console.log("Api Here", formData);

  const response = await DosAxios.post(
    `${domain.EmployerUpdateProfileDetails}${localStorage.getItem("node")}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response;
};
const apiService = {
  callLogout,
  fetchPersonalDetails,
  updatePersonalDetails,
  getDetailsRoleList,
  getDetailsRoleCreate,
  getDetailsRoleUpdate,
  getDetailsEducationList,
  getDetailsLicensesList,
  getDetailsSkillList,
  getSkillList,
  getLanguagesList,
  getQuestionsList,
  getQuestionSugggstionList,
  getAddQuestions,
  GetEmploymentType,
  GetKeySkillsList,
  GetDepartmentList,
  GetRoleList,
  GetWorkModeList,
  GetJobLocationList,
  GetWorkExpList,
  GetSalaryTypeList,
  GetSalaryRangeList,
  candidateAdditionalDetails,
  GetSalaryProcessTypeList,
  GetCompanyIndustryList,
  GetEducationQualificationList,
  GetCandidateIndustryList,
  GetNotificationList,
  GetCompanySizeList,
  GetEmailSubUserList,
  OpenEmploymentType,
  OpenSalaryTypeList,
  OpenSalaryProcessTypeList,
  OpenSalaryRangeList,
  OpenListingAnyTimeList,
  getJobPostingList,
  getEManageJobList,
  getEAppliedJobCandidateList,
  getEManageJobCandidateUpdateStatus,
  getEManageJobDelete,
  getEManageJobFileDownload,
  getCandidateOTPSend,
  getCandidateVerificationOTP,
  getEmployerSendOTP,
  getEmployerVerificationOTP,
  getEmployerProfileDetails,
  getEmployerProfileDetailsUpdate,
};

export default apiService;
