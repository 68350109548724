import React, { useEffect, useRef, useState } from "react";

import Header1 from "../../components/header/Header1";
import Footer from "../../components/footer";
import Gotop from "../../components/gotop";
import axios from "axios";

import { ShimmerDiv, ShimmerCategoryItems } from "shimmer-effects-react";

import DomAxios from "../../api/axios";
import domain from "../../api/constent";
import CardComp from "../../components/card-style/cardComp";
import {
  Text as RSText,
  Card as RSCard,
  Grid as RSGrid,
  Panel as RSPanel,
  Row as RSRow,
  CheckPicker as RSCheckPicker,
  SelectPicker as RSSelectPicker,
  Col as RSCol,
  Dropdown as RSDropdown,
  InputPicker as RSInputPicker,
  Loader as RSLoader,
  Pagination as RSPaginator,
  Placeholder as RSPlaceholder,
  AutoComplete as RSAutoComplete,
  VStack,
  CheckTreePicker as RSCheckTreePicker,
  TreePicker as RSTreePicker,
  List as RSList,
  TagGroup as RSTagGroup,
  Tag as RSTag,
  Button as RSButton,
  Divider as RSDivider,
  Placeholder,
  Form as RSForm,
} from "rsuite";
import { Link, useNavigate } from "react-router-dom";
import RoleComp from "../../components/Role/RoleComp";
import EducationComp from "../../components/Education/EducationComp";
import LicensesAComp from "../../components/LicencesAndCertificate/LicensesAComp";
import SkillComp from "../../components/Skill/SkillComp";
import LanguageComp from "../../components/language/LanguageComp";
import { sweetAlert } from "../../utils/sweetAlert";

import Session from "../../api/session";

import apiService from "../../api/apiService";
export default function ProfilePage({ clname = "profile" }) {
  const [loading, setLoading] = useState(true);

  const [jobList, setDataInFeatured] = useState([]);
  const [error, setError] = useState(null);
  const [getPassValue, setPassValue] = useState([]);

  const [wherePlaceSearchingList, setWherePlaceSearchingList] = useState([]);
  const [employeeTypes, setEmploymentList] = useState([]);
  const [salaryTypeList, setSalaryTypeList] = useState([]);
  const [salaryRangeList, setSalaryRangeList] = useState([]);

  const [candidateDetails, setCandidateDetails] = useState({
    candidateID: Session.getDataSessionStore().id,
    candidateEmail: Session.getDataSessionStore().email,
    homeLocation: "",
    prefLocation: "",
    jobtType: "",
    salaryType: "",
    expectationSalaryType: "",
  });

  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => setLoading(false), 3000);

    const WOW = require("wowjs");
    window.wow = new WOW.WOW({
      live: false,
    });
    window.wow.init();
    fetchInfo();
    loadMore();
    fetchFilteringData();
  }, []);
  const fetchFilteringData = async () => {
    // setLoading(true);
    try {
      // Make all API calls concurrently
      const [
        employmentData,
        salaryTypeData,
        salaryRangeData,
        // listingAnyTimeList,
      ] = await Promise.all([
        apiService.OpenEmploymentType(),
        apiService.OpenSalaryTypeList(),
        apiService.OpenSalaryRangeList(),
        apiService.OpenListingAnyTimeList(),
      ]);
      // console.log(`Log ${employmentData}`);

      // Update state with fetched data
      setEmploymentList(employmentData);
      setSalaryTypeList(salaryTypeData);
      setSalaryRangeList(salaryRangeData);
      //   setListingAnyTimeList(listingAnyTimeList);
    } catch (err) {
      console.error("Error fetching data:", err);
      setError(err.message);
    } finally {
      // setLoading(false);
    }
  };
  const fetchWherePlaceSearchingM = async () => {
    try {
      setLoading(true);

      const response = await DomAxios.get(domain.Cadidate_job_location_List);
      //   console.log(response);
      setLoading(false);
      setWherePlaceSearchingList(response.data.data);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching categories:", error);
    }
  };

  const fetchInfo = async () => {
    try {
      setLoading(true);

      const resp = await axios.get(domain.Job_Posting_List_URL);
      //   console.log(resp.data);
      setDataInFeatured(resp.data);
      setLoading(false);
    } catch (err) {
      setError("Error fetching data");
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  // Do thing process
  const loadMore = () => {
    setLoading(true);
    setTimeout(() => {
      fetchWherePlaceSearchingM();
      setLoading(false);
    }, 1000);
  };

  const onItemsRendered = (props) => {
    if (props.visibleStopIndex >= wherePlaceSearchingList.length - 1) {
      loadMore();
    }
  };

  const renderMenu = (menu) => {
    return (
      <>
        {menu}
        {/* {loading && <FixedLoader />} */}
      </>
    );
  };

  const handleUpdate = async () => {
    console.log("DATA", candidateDetails);
    const getJson = {
      id: candidateDetails.candidateID,
      email: candidateDetails.candidateEmail,
      home_location: candidateDetails.homeLocation.toString(),
      pref_location_ids: candidateDetails.prefLocation.toString(),
      job_type: candidateDetails.jobtType,
      salary_type: candidateDetails.salaryType,
      exp_salary: candidateDetails.expectationSalaryType,
    };
    // setLoading(true);
    try {
      const resp = await apiService.candidateAdditionalDetails(getJson);
      if (resp) {
        // handleClose();
        console.log(resp);

        sweetAlert("Success!", "Your personal details have been updated.", 1);
      }
    } catch (error) {
      console.log(error);

      sweetAlert("Error!", "Failed to update personal details. Try again.", 2);
    } finally {
      //   setLoading(false);
    }
  };

  return (
    <>
      <div className="card">
        <CardComp checkUpdateShow={true} />

        <div className="container pl-4">
          <RoleComp />
          <br />
          <EducationComp />
          <br />
          <LicensesAComp />
          <br />
          {/* <SkillComp />
          <br /> */}
          <LanguageComp />
          <br />
        </div>
      </div>
    </>
  );
}
