import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const AuthMiddleware = ({ children, allowedRoles = [] }) => {
  const { user } = useSelector((state) => state.authUser);
  const { employer } = useSelector((state) => state.authEmployer);

  console.log(user, "ddd:", employer);

  const userType = user?.type || null;
  const userToken = user?.token || null;
  const empType = employer?.type || null;
  const empToken = employer?.token || null;

  // ✅ 1. Check if at least ONE valid token exists
  if (!userToken && !empToken) {
    return <Navigate to="/login" replace />;
  }

  // ✅ 2. Check if the role is allowed
  const isUserAllowed = userType && allowedRoles.includes(userType);
  const isEmployerAllowed = empType && allowedRoles.includes(empType);

  if (allowedRoles.length && !isUserAllowed && !isEmployerAllowed) {
    return <Navigate to="/unauthorized" replace />;
  }

  return children;
};

export default AuthMiddleware;
